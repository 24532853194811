import { useEffect, useState } from "react"

const useDeviceTheme = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(true)

  useEffect(() => {
    const newSavedValue = localStorage.getItem("current-theme")
    setIsDarkTheme(newSavedValue === "dark" ? true : false)

    const handleStorageChange = () => {
      const newSavedValue = localStorage.getItem("current-theme")
      setIsDarkTheme(newSavedValue === "dark" ? true : false)
    }
    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  })

  return isDarkTheme
}

export default useDeviceTheme
