import { Grid } from "antd"
import React, { useCallback } from "react"
import Particles from "react-particles"
import styled from "styled-components"
import { loadFull } from "tsparticles"
import type { Container, Engine } from "tsparticles-engine"
import { animationProperties } from "../../../utils"
import { BannerContent } from "../BannerContent"
import { AosWrapper } from "../../molecules/AosWrapper"

const Wrapper = styled.div`
  background: ${(props) => props.theme.coverGradientHome};
  background-color: ${(props) => props.theme.blue5Home};
  position: relative;
  .mountain-background {
    width: 100%;
    position: absolute;
    bottom: 0;
    @media (max-width: 641px) {
      height: auto;
    }
  }
  .mountain-background-img {
    bottom: 0;
    min-height: 100vh;
    width: 100%;
    @media (max-width: 641px) {
      height: auto;
    }
  }
`
const StyledGradientTitle = styled.span`
  ${(props) => props.theme.typography.heroHeadline};
  background: ${(props) => props.theme.indicatorGradient};
  background-clip: text;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 876px) {
    font-size: 1.875rem;
  }
  @media (max-width: 641px) {
    font-size: 1.5rem;
    font-weight: 600;
  }
`
const Banner: React.FC = () => {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine)
  }, [])
  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {
      await container
    },
    []
  )
  const { useBreakpoint } = Grid
  const screenSize = useBreakpoint()

  return (
    <Wrapper id={"banner"}>
      <AosWrapper
        duration={animationProperties.duration + 1}
        delay={animationProperties.delay + 1}
        animatePreScroll={true}
        animateIn={"animate__fadeInUpBig"}
        className={"mountain-background"}
      >
        <img
          src={
            (screenSize.xs || screenSize.md || screenSize.sm) && !screenSize.xl
              ? "/assets/imgs/mobileMountain.png"
              : "/assets/imgs/mountain.png"
          }
          className={"mountain-background-img"}
        />
      </AosWrapper>
      <BannerContent
        header={"SERVING TECHNOLOGY BETTER"}
        title={
          <>
            <>{"We Connect People, Processes ,"}</> <>{"And\n"}</>
            <StyledGradientTitle>{"Technology"} </StyledGradientTitle>
            {"Together"}
          </>
        }
        content={
          "Collaborating to incorporate latest trends & technologies in\n products & services to serve better"
        }
      />
      <Particles
        id={"tsparticles"}
        url={"/assets/particles.json"}
        init={particlesInit}
        loaded={particlesLoaded}
      />
    </Wrapper>
  )
}

export { Banner }
