import React from "react"
import styled from "styled-components"
import {
  Headline,
  StyledGradientTitleInverse,
  animationProperties,
} from "../../../utils"
import { AosWrapper } from "../../molecules/AosWrapper"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

const Wrapper = styled.div`
  padding: 100px 20px 0 20px;
  background: ${(props) => props.theme.efficiencySection};
  @media (max-width: 641px) {
    padding: 50px 20px;
  }
`
const HeadlineText = styled(Headline)`
  position: relative;
  margin-bottom: 0;
  color: ${(props) => props.theme.base};
  @media (max-width: 768px) {
    white-space: normal !important;
  }
  @media (max-width: 641px) {
    font-size: 1.25rem !important;
    white-space: normal !important;
  }
  .close-vector {
    position: absolute;
    margin: 10px 0px 0px 10px;
    @media (max-width: 641px) {
      margin: 0px;
      height: 40px;
      width: 56.4px;
      right: auto;
    }
    @media (max-width: 423px) {
      margin: 0px;
      right: 0;
    }
    @media (max-width: 376px) {
      margin: 0px;
      right: auto;
    }
    @media (max-width: 319px) {
      margin: 0px;
      right: 0;
    }
  }

  .open-vector {
    display: none;
    @media (max-width: 425px) {
      display: inline-block;
      margin: 0px;
      height: 40px;
      width: 56.4px;
    }
  }
`

const ImageWrapper = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .mobile-img {
    display: none;
  }

  .mobile-img,
  .desktop-img {
    object-fit: contain;
    margin-top: -120px;
    width: 1450px;
    @media (max-width: 1500px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      margin-top: -60px;
    }
    @media (max-width: 641px) {
      width: calc(100% + 80px);
      margin-top: 6px;
    }
  }
`

const CompanyEfficiency: React.FC = () => {
  const isDarkTheme = useDeviceTheme()
  return (
    <Wrapper>
      <AosWrapper>
        <HeadlineText>
          <img className={"open-vector"} src={"/assets/imgs/open_vector.svg"} />

          {"We aim to empower individuals and businesses\n"}
          <StyledGradientTitleInverse>
            {" "}
            {"productivity "}
          </StyledGradientTitleInverse>
          {"and"}
          <StyledGradientTitleInverse>
            {" "}
            {"efficiency "}
          </StyledGradientTitleInverse>
          {"through our software\n solutions."}
          <img
            className={"close-vector"}
            src={"/assets/imgs/close_vector.svg"}
          />
        </HeadlineText>
        <AosWrapper
          duration={animationProperties.duration + 0.2}
          delay={animationProperties.delay + 0.2}
          animateIn={"animate__fadeInUpBig animate__zoomIn"}
        >
          <ImageWrapper>
            <img
              className={"desktop-img"}
              src={
                isDarkTheme
                  ? "/assets/imgs/our-team.png"
                  : "/assets/imgs/our-team.svg"
              }
              width={"100%"}
              height={"auto"}
            />
          </ImageWrapper>
        </AosWrapper>
      </AosWrapper>
    </Wrapper>
  )
}

export { CompanyEfficiency }
