import React from "react"
import styled from "styled-components"
import { OrganizationCultureGrid } from "../OrganizationCultureGrid"
import { IOrganizationCard } from "../../molecules/OrganizationCard"
import { AosWrapper } from "../../molecules/AosWrapper"

const Wrapper = styled.div`
  padding: 100px 20px 100px 20px;
  background-color: ${(props) => props.theme.blackBackground};
  @media (max-width: 641px) {
    padding: 60px 20px;
  }
`

const Headline = styled.div`
  margin-bottom: 50px;
  ${(props) => props.theme.typography.sectionHeadline};
  color: ${(props) => props.theme.sameBase};
  white-space: break-spaces;
  text-align: center;
  @media (max-width: 641px) {
    font-size: 1.375rem;
    margin-bottom: 40px;
  }
  .styled-gradient-title {
    ${(props) => props.theme.typography.sectionHeadline};
    background: ${(props) => props.theme.highlightGradient};
    @media (max-width: 641px) {
      font-size: 1.375rem;
    }
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
`

const CultureList: Array<IOrganizationCard> = [
  {
    title: "Remote Team Management",
    icon: <img src={"/assets/icons/remote-team.svg"} />,
    lightIcon: <img src={"/assets/icons/remote-team-light.svg"} />,
    content: `RTW is an established tech company with 50+\nemployees working remotely in parallel\n making remote working effective and more\n productive.`,
    footer: "Ensuring Balanced, Supportive &\n Productive Remote Work Culture",
  },
  {
    title: "Career Growth Opportunities",
    icon: <img src={"/assets/icons/growth.svg"} />,
    lightIcon: <img src={"/assets/icons/growth-light.svg"} />,
    content:
      "We care for our RTW family and their growth\n so we have got them covered with full-proof\n path to bloom. We build positive work culture\n to increase job satisfaction.",
    footer: "BACKING YOU AT EVERY STAGE",
  },
  {
    title: "Opportunities for Initiatives",
    icon: <img src={"/assets/icons/idea.svg"} />,
    lightIcon: <img src={"/assets/icons/idea-light.svg"} />,
    content:
      "RTW offers freedom to take initiatives\n creating a culture of innovation and creativity.\n We also encourage self development through\n articles writing.",
    footer: "Taking tech forward, together",
  },
  {
    title: "In tune with Latest Tech",
    icon: <img src={"/assets/icons/jet-rocket-science.svg"} />,
    lightIcon: <img src={"/assets/icons/jet-rocket-science-light.svg"} />,
    content:
      "RTW attempts to become up-to-date with the\n most recent advancement & trends in\n technology. We equip employee with the\n skills needed to use latest technologies.",
    footer: "ACTIVELY PARTICIPATING  TO STAY\n COMPETITIVE IN INDUSTRY",
  },
  {
    title: "Reward & Recognition",
    icon: <img src={"/assets/icons/reward.svg"} />,
    lightIcon: <img src={"/assets/icons/reward-light.svg"} />,
    content:
      "We understand and appreciate efforts made\n by our employees everyday and  make sure\n that our employees are motivated, satisfied\n and feel valued.",
    footer: "Hard work does not go unnoticed\n here in RTW",
  },
  {
    title: "Entertainment",
    icon: <img src={"/assets/icons/bounce.svg"} />,
    lightIcon: <img src={"/assets/icons/bounce-light.svg"} />,
    content:
      "We organize regular meet-ups and team-\n building games focusing on the stronger and\n healthier bond between colleagues .\nWhat else could be better than this.",
    footer: "ENCOURAGING HEALTHIER & HAPPIER\n TEAM BONDING",
  },
]

const OrganizationCultureSection = () => {
  return (
    <Wrapper id={"culture"}>
      <AosWrapper>
        <Headline>
          {"Sneak-peak to our Organization"}
          <span className="styled-gradient-title">{" Culture"}</span>
        </Headline>
        <OrganizationCultureGrid items={CultureList} />
      </AosWrapper>
    </Wrapper>
  )
}

export { OrganizationCultureSection }
