import { Drawer, Grid, Menu, MenuProps } from "antd"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"
import { SwitchContainer } from "@project/shared"

const Wrapper = styled.div`
  .scroll-to-top {
    position: fixed;
    right: 0;
    bottom: 10px;
    cursor: pointer;
    height: 100px;
    width: 100px;
    z-index: 10;
  }
`

const NavBar = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 18px 100px;

  .ant-menu-light.ant-menu-horizontal > .ant-menu-item::after {
    border: none;
  }
  @media (max-width: 1375px) {
    padding: 37px 50px;
  }
  @media (max-width: 1375px) {
    padding: 37px 30px;
    .logo {
      width: 175px;
    }
  }
`
const MobMenu = styled(Menu)`
  width: 100%;
  background-color: transparent;
  justify-content: flex-end;
  .ant-menu-title-content {
    ${(props) => props.theme.typography.navMobItemFont}
    font-weight: 400;
    color: ${(props) => props.theme.sameBase};
  }
  .ant-menu-item {
    display: flex;
    justify-content: flex-end;
    margin: 35px 0;
    padding: 0;
  }
`
const StyledMenu = styled(Menu)<{ onHomeLight: boolean }>`
  width: 100%;
  background-color: transparent;
  justify-content: flex-end;
  color: ${(props) =>
    props.onHomeLight ? props.theme.base : props.theme.menuTextColor};
  border: none;

  .ant-menu-item {
    padding: 0;
  }
  .ant-menu-title-content {
    ${(props) => props.theme.typography.navItemFont}
    padding-left: 30px;
    padding-right: 30px;
    border-right: 1px solid
      ${(props) =>
        props.onHomeLight ? props.theme.menuItemBorderColor : "#DDDDDD"};
  }
  .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon {
    vertical-align: middle;
    margin-left: 30px;
    padding: 0;
  }
  .ant-menu-item-selected {
    .ant-menu-title-content {
      color: ${(props) =>
        props.onHomeLight ? props.theme.base : props.theme.sameBase};
    }
  }
  li:first-child {
    .ant-menu-title-content {
      padding-left: 0;
    }
  }

  li:nth-last-child(-n + 2) {
    .ant-menu-title-content {
      padding: 0;
      border: none;
    }
  }
  .ant-menu-horizontal {
    border-bottom: none;
  }
`
const MobileNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  background: ${(props) => props.theme.coverGradientHeaderHome};
  @media (max-width: 576px) {
    .logo {
      width: 109px;
    }
  }
`

const DrawerWrapper = styled(Drawer)`
  display: flex;
  justify-content: flex-end;
  .ant-drawer-body {
    background-color: ${(props) => props.theme.mobNavBackgroundColor};
    padding: 30px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .ant-drawer-header {
    background-color: ${(props) => props.theme.mobNavBackgroundColor};
    padding: 31px 30px 0 30px;
    border-bottom: none;
  }
  .ant-menu {
    border: none !important;
  }
  .logo-on-drawer-on-scroll {
    margin: 50px 0 0;
  }
  .ant-menu-item {
    border: none !important;
    background-color: transparent !important;
    span {
      font-weight: 400;
      font-size: 18px;
    }
  }
`
const MobileNavWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  .before-scrolling {
    background-color: transparent !important;
  }
  .after-scrolling {
    background: ${(props) => props.theme.coverGradientHeader};
  }
`

const DrawerOnScroll = styled.div`
  .drawer-image {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    cursor: pointer;
  }
  .on-mobile {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    cursor: pointer;
    height: 60px;
    width: 60px;
  }
`

const HeaderComponent: React.FC = () => {
  const router = useRouter()
  const [activeKey, setActiveKey] = useState("home")
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false)
  const [openDrawerOnScroll, setOpenDrawerOnScroll] = useState<boolean>(false)
  const [onScrolling, setOnScrolling] = useState(false)
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const isDarkTheme = useDeviceTheme()
  const [checked, setChecked] = useState<boolean>(true)

  useEffect(() => {
    const isDarkTheme = localStorage.getItem("current-theme")
    setChecked(isDarkTheme === "dark" ? true : false)
  }, [])

  const menuItem: MenuProps["items"] = [
    {
      key: "home",
      label: "HOME",
      onClick: () => router.push("/"),
    },
    {
      key: "team",
      label: "OUR TEAM",
      onClick: () => router.push("/team"),
    },
    {
      key: "service",
      label: "SERVICE",
      onClick: () => router.push("/service"),
    },
    // {
    //   key: "rtw-blogs",
    //   label: "RTW BLOGS",
    //   onClick: () => router.push("/rtw-blogs"),
    // },
    {
      key: "career",
      label: "CAREER",
      onClick: () => router.push("/career"),
    },
    {
      key: "contact-us",
      label: "CONTACT US",
      onClick: () => router.push("/contact-us"),
    },
    {
      key: "japan",
      icon: (
        <a
          href={"https://readytowork.jp/"}
          target={"_blank"}
          style={{ marginTop: -5 }}
        >
          <img
            className={"japan"}
            src={"/assets/icons/japan-flag.svg"}
            width={40}
            height={40}
          />
        </a>
      ),
    },
  ]
  /**
   *
   * Ant design hooks for accessing the breakpoints.
   */
  const { useBreakpoint } = Grid
  const screenSize = useBreakpoint()
  useEffect(() => {
    setActiveKey(String(router.pathname.split("/")[1]))
  }, [router.pathname])

  useEffect(() => {
    if (
      (screenSize.xs || screenSize.sm || screenSize.md) &&
      !screenSize.lg &&
      !screenSize.xl &&
      !screenSize.xxl
    ) {
      setOpenDrawerOnScroll(false)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY
      setOnScrolling(scrollTop - 50 < 0 ? false : true)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const middleOfScreen = document.documentElement.scrollHeight / 3.5
      const scrollPosition = document.documentElement.scrollTop
      setShowScrollToTop(scrollPosition > middleOfScreen)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const getSwitchContainer = () => {
    return (
      <SwitchContainer
        checked={checked}
        onChange={(value) => {
          router.reload()
          localStorage.setItem("current-theme", value ? "dark" : "light")
          window.dispatchEvent(new Event("storage"))
          setChecked(value)
        }}
      />
    )
  }

  const commonDrawerWrapper = (onMobile: boolean) => {
    const selectKey = router.pathname.split("/")
    if (selectKey[1] === "menu1-submenu1") selectKey[1] = "menu1"
    const selectKeyDefault = selectKey[1] || "menu1"
    return (
      <DrawerWrapper
        placement={"right"}
        onClose={() =>
          onMobile ? setIsOpenDrawer(true) : setOpenDrawerOnScroll(true)
        }
        open={onMobile ? isOpenDrawer : openDrawerOnScroll}
        destroyOnClose
        mask
        closable={true}
        closeIcon={<React.Fragment />}
        extra={
          <img
            src={
              isDarkTheme
                ? "/assets/icons/cross-icon.svg"
                : "/assets/icons/cross-icon-light.svg"
            }
            style={{ cursor: "pointer" }}
            width={25}
            height={25}
            onClick={() =>
              onMobile ? setIsOpenDrawer(false) : setOpenDrawerOnScroll(false)
            }
          />
        }
      >
        <img
          className={"logo-on-drawer-on-scroll"}
          src={
            isDarkTheme
              ? "/assets/icons/rtw-logo.svg"
              : "/assets/icons/rtw-logo-light.svg"
          }
          width={250}
          height={38}
          onClick={() => router.push("/")}
        />
        <MobMenu
          mode={"vertical"}
          selectedKeys={[selectKeyDefault]}
          items={onMobile ? menuItem.slice(0, menuItem.length - 1) : menuItem}
          onClick={() =>
            onMobile ? setIsOpenDrawer(false) : setOpenDrawerOnScroll(false)
          }
        />
        {getSwitchContainer()}
      </DrawerWrapper>
    )
  }

  if (
    (screenSize.xs || screenSize.sm || screenSize.md) &&
    !screenSize.lg &&
    !screenSize.xl &&
    !screenSize.xxl
  ) {
    return (
      <MobileNavWrapper>
        {!onScrolling ? (
          <MobileNav className={"before-scrolling"}>
            <img
              className={"logo"}
              src={
                isDarkTheme || router.pathname === "/"
                  ? "/assets/icons/rtw-logo.svg"
                  : "/assets/icons/rtw-logo-light.svg"
              }
              width={175}
              onClick={() => router.push("/")}
              style={{ cursor: "pointer" }}
            />
            {isOpenDrawer ? (
              <img
                src={"/assets/icons/cross-icon.svg"}
                width={30}
                height={30}
                onClick={() => setIsOpenDrawer(true)}
              />
            ) : (
              <img
                src={
                  isDarkTheme || router.pathname === "/"
                    ? "/assets/icons/hamburger.svg"
                    : "/assets/icons/hamburger-light.svg"
                }
                width={30}
                height={30}
                onClick={() => setIsOpenDrawer(true)}
              />
            )}
            {commonDrawerWrapper(true)}
          </MobileNav>
        ) : (
          <DrawerOnScroll>
            <img
              src="/assets/icons/drawer-on-scroll.svg"
              className={"on-mobile"}
              onClick={() => setIsOpenDrawer(true)}
            />
            {commonDrawerWrapper(true)}
          </DrawerOnScroll>
        )}
      </MobileNavWrapper>
    )
  }

  return (
    <Wrapper>
      {!onScrolling ? (
        <NavBar className={"before-scrolling"}>
          <img
            className={"logo"}
            src={
              isDarkTheme || router.pathname === "/"
                ? "/assets/icons/rtw-logo.svg"
                : "/assets/icons/rtw-logo-light.svg"
            }
            width={250}
            height={38}
            onClick={() => router.push("/")}
            style={{ cursor: "pointer", maxWidth: "167px", width: "100%" }}
          />
          <StyledMenu
            mode={"horizontal"}
            items={menuItem}
            selectedKeys={[activeKey || "home"]}
            onHomeLight={!isDarkTheme && router.pathname === "/"}
          />
          {getSwitchContainer()}
        </NavBar>
      ) : (
        <DrawerOnScroll>
          <img
            src="/assets/icons/drawer-on-scroll.svg"
            className="drawer-image"
            onClick={() => setOpenDrawerOnScroll(true)}
          />
          {commonDrawerWrapper(false)}
        </DrawerOnScroll>
      )}
      {showScrollToTop && (
        <img
          className="scroll-to-top"
          src="/assets/icons/scroll-to-top.svg"
          onClick={scrollToTop}
        />
      )}
    </Wrapper>
  )
}

export { HeaderComponent }
