import React from "react"
import styled from "styled-components"

const LogoContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 80px 20px;

  @media only screen and (min-width: 769px) {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    img {
      width: 100%;
      height: auto;
    }
  }

  .grid-item {
    max-width: 300px;
    align-self: center;
  }

  .grid-item:first-child {
    grid-column: 1 / span 3;
    grid-row-start: 1;
    grid-column-end: 1;
    @media only screen and (max-width: 425px) {
      img {
        max-width: 100px;
      }
    }
  }

  .grid-item:nth-child(2) {
    grid-row-start: 1;
    grid-column-end: 4;
  }

  .grid-item:nth-child(3) {
    grid-column: 2;
    grid-row: 2;
  }

  .grid-item:nth-child(4) {
    max-width: 300px;
    grid-column: 1;
    grid-row: 3;
  }

  .grid-item:nth-child(5) {
    grid-column: 3;
    grid-row: 3;
  }
`

const ScalaLogoMobile = () => {
  const largeScreenImages: Array<{ img: string; link: string }> = [
    { img: "/assets/icons/scala6.svg", link: "https://scala-playce.jp/" },
    { img: "/assets/icons/scala4.svg", link: "https://scala-partners.com/" },
    { img: "/assets/icons/scalamain.svg", link: "https://scalagrp.jp/en/" },
    { img: "/assets/icons/scala3.svg", link: "https://scala-service.jp/" },
    { img: "/assets/icons/scala5.svg", link: "https://scala-com.jp/" },
  ]

  return (
    <LogoContainer>
      {largeScreenImages.map((logo, i) => {
        return (
          <a key={i} className={"grid-item"} href={logo.link} target={"_blank"}>
            <img src={logo.img} />
          </a>
        )
      })}
    </LogoContainer>
  )
}

export default ScalaLogoMobile
