import React from "react"
import styled from "styled-components"
import { CompanyHistoryTimeline } from "../../atoms"

interface ComapnyCardProps {
  date: string
  title: string
  details: string
}

const CompanyTimeline = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Ellipse = styled.div`
  position: relative;
  max-width: 730px;
  max-height: 730px;
  /* background: radial-gradient(
    44.93% 44.93% at 47.67% 50%,
    rgba(39, 62, 69, 0.8) 0%,
    rgba(37, 37, 37, 0) 100%
  ); */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    /* background: ${(props) => props.theme.background}; */
    max-width: 500px;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 520px) {
    /* background: ${(props) => props.theme.background}; */
    max-width: 450px;
    height: 100%;
    width: 100%;
  }
  @media (max-width: 470px) {
    /* background: ${(props) => props.theme.background}; */
    max-width: 350px;
    width: 100%;
    height: 100%;
  }
`
const CompanyHistoryCard = styled(CompanyHistoryTimeline)`
  width: 100%;
  margin-bottom: 200px;
  display: flex;
  justify-content: center;
  z-index: 1;
`

const CompanyHistoryComponent: React.FC<ComapnyCardProps> = ({
  date,
  title,
  details,
}) => {
  return (
    <CompanyTimeline>
      <Ellipse>
        <CompanyHistoryCard date={date} title={title} details={details} />
      </Ellipse>
    </CompanyTimeline>
  )
}
export { CompanyHistoryComponent }
