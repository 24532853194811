import { ThemeConfig, theme as mainTheme } from "antd"

const heroHeadline = {
  fontWeight: "700",
  fontSize: "2.625rem",
  lineHeight: "150%",
  fontFamily: "'Montserrat', sans-serif",
}

const heroHeadline2 = {
  fontFamily: '"Montserrat", sans-serif',
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "1.5rem",
  lineHeight: "28px",
}
const heroHeadline3 = {
  ...heroHeadline2,
  lineHeight: "150%",
  fontSize: "2rem",
}
const sectionHeadline = {
  fontWeight: "600",
  fontSize: "2rem",
  lineHeight: "150%",
  fontFamily: "'Montserrat', sans-serif",
}
const sectionHeadline2 = {
  ...sectionHeadline,
  fontWeight: "600",
}
const headline4 = {
  fontWeight: "600",
  fontSize: "2.5rem",
  lineHeight: "150%",
  fontFamily: "'Montserrat', sans-serif",
}

const cardTitle = {
  fontWeight: "700",
  fontSize: "1.375rem",
  lineHeight: "150%",
  fontFamily: "'Montserrat', sans-serif",
}
const cardTitle1 = {
  fontWeight: "400",
  fontSize: "1.125rem",
  lineHeight: "150%",
  fontFamily: "'Lato', sans-serif",
}
const cardTitle2 = {
  fontWeight: "500",
  fontSize: "1.375rem",
  lineHeight: "27px",
  fontStyle: "normal",
  fontFamily: "'Montserrat', sans-serif",
}
const cardTitle4 = {
  fontWeight: "500",
  fontSize: "1rem",
  lineHeight: "27px",
  fontStyle: "normal",
  fontFamily: "'Montserrat', sans-serif",
}
const cardTitle3 = {
  ...cardTitle2,
  fontWeight: "600",
}
const cardTitle5 = {
  ...cardTitle1,
  fontFamily: "'Lato', sans-serif",
}
const paragraph1 = {
  fontWeight: "400",
  fontSize: "1rem",
  lineHeight: "150%",
  fontFamily: "'Lato', sans-serif",
}

const paragraph2 = {
  ...paragraph1,
  fontSize: "1.125rem",
}
const navItemFont = {
  fontWeight: "400",
  fontSize: "1rem",
  fontFamily: "'Inter', sans-serif",
}

const navMobItemFont = {
  fontWeight: "400",
  fontSize: "0.875rem",
  fontFamily: "'Inter', sans-serif",
}
const paragraph3 = {
  ...paragraph1,
  fontWeight: "500",
}
const paragraph4 = {
  ...paragraph1,
  fontWeight: "400",
}

const heroHeadlineMob = {
  ...heroHeadline,
  fontSize: "24px",
}

const sectionHeadlineMob = {
  ...sectionHeadline,
  fontSize: "1.375rem",
}

const cardTitleMob = {
  ...cardTitle,
  fontSize: "1rem",
}
const cardTitleMob2 = {
  ...cardTitle2,
  fontSize: "16px",
}
const paragraph1Mob = {
  ...paragraph1,
  fontSize: "0.875rem",
}

const paragraph2Mob = {
  ...paragraph2,
  fontSize: "1.25rem",
}

const paragraphInfo = {
  ...paragraph2,
  fontSize: "1rem",
  fontWeight: 400,
}

const typographyH1 = {
  fontWeight: "bold",
  fontSize: "44px",
  lineHeight: "73px",
  letterSpacing: "0.04em",
  fontFamily: "Roboto",
}

const typographyMap = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "150%",
  fontFamily: "'Lato', sans-serif",
}

const descriptionMsg = {
  fontFamily: '"Lato", sans-serif',
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "1rem",
  lineHeight: "32px",
}
const detailsText = {
  fontFamily: '"Lato", sans-serif',
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "1.125rem",
  lineHeight: "37px",
}

const typographyMap1 = {
  fontFamily: '"Inter", sans-serif',
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "150%",
}

const typographyH2 = {
  fontWeight: 900,
  fontSize: 30,
  lineHeight: "38px",
  letterSpacing: "0.04em",
  fontFamily: "Roboto",
}

const typographyH2EN = {
  fontWeight: 900,
  fontSize: 18,
  lineHeight: "1rem",
  letterSpacing: "0.05em",
  fontFamily: "Roboto",
}

const typographyH3 = {
  fontFamily: "'Lato', sans-serif",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1.25rem",
  lineHeight: "2rem",
}

const typographyPrimary = {
  fontSize: 16,
  lineHeight: "30px",
  letterSpacing: "0.03em",
  fontFamily: "Roboto",
}
const typographyPrimaryBold = {
  fontSize: 16,
  lineHeight: "30px",
  fontWeight: "bold",
  letterSpacing: "0.03 em",
  fontFamily: "Roboto",
}

const typographySecondary = {
  fontSize: 14,
  lineHeight: "1.25rem",
  letterSpacing: "0.03em",
  fontFamily: "Roboto",
}

const typographySecondaryBold = {
  fontSize: 14,
  fontWeight: "bold",
  lineHeight: "1.25rem",
  letterSpacing: "0.03em",
  fontFamily: "Roboto",
}

const typographyTertiary = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily: "Roboto",
}

const typographyTertiaryBold = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily: "Roboto",
  fontWeight: "bold",
}
const typographyTag = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily: "Roboto",
  fontWeight: "normal",
}
const typographyMapTag = {
  fontSize: 12,
  lineHeight: "30px",
  fontFamily: "Roboto,'Noto Sans JP'",
  fontWeight: "bold",
}

const label = {
  fontWeight: 500,
  fontSize: "1rem",
  lineHeight: "23.17px",
  fontFamily: "'Montserrat', sans-serif",
}

const typographyMB = {
  fontWeight: 900,
  fontSize: 26,
  lineHeight: "28px",
  letterSpacing: "0.02em",
  fontFamily: "Roboto",
}
const typographyMBH2 = {
  fontWeight: 900,
  fontSize: 22,
  lineHeight: "35px",
  letterSpacing: "0.01em",
  fontFamily: "Roboto",
}

const PackDesc = {
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1.375rem",
  fontFamily: "'Noto Sans JP',sans-serif",
}

const PersonName = {
  fontFamily: "'Montserrat', sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "1.25rem",
  lineHeight: "24px",
}

const CompanyIconText = {
  fontFamily: "'Lato', sans-serif",
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "37px",
}

const quoteWriter = {
  fontFamily: '"Inter", sans-serif',
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "1.375rem",
  lineHeight: "150%",
}

const TextLinearGradient = {
  background:
    "linear-gradient(87.75deg,#0058ee 0%,rgba(54, 215, 183, 0.8) 100%),linear-gradient(0deg, #ffffff, #ffffff)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
}

const HistoryCardTitle = {
  fontFamily: '"Montserrat", sans-serif',
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "2rem",
  lineHeight: "120%",
}

const typography = {
  heroHeadline,
  heroHeadline2,
  heroHeadline3,
  sectionHeadline,
  sectionHeadline2,
  cardTitle,
  cardTitle1,
  cardTitle3,
  paragraph1,
  paragraph2,
  heroHeadlineMob,
  sectionHeadlineMob,
  navItemFont,
  navMobItemFont,
  cardTitleMob,
  headline4,
  cardTitle4,
  cardTitle2,
  cardTitle5,
  cardTitleMob2,
  paragraph1Mob,
  paragraph2Mob,
  typographyH1,
  typographyH2,
  typographyH2EN,
  typographyH3,
  typographyPrimary,
  typographyPrimaryBold,
  typographySecondary,
  typographySecondaryBold,
  typographyTertiary,
  typographyTertiaryBold,
  typographyMB,
  typographyMBH2,
  typographyTag,
  typographyMapTag,
  typographyMap,
  quoteWriter,
  PackDesc,
  label,
  CompanyIconText,
  paragraph3,
  paragraphInfo,
  paragraph4,
  typographyMap1,
  descriptionMsg,
  detailsText,
  TextLinearGradient,
  HistoryCardTitle,
  PersonName,
}

export const teamNepal = {
  darkTheme: {
    toggleColor:
      "linear-gradient(88deg, #0958DE 0%, rgba(0,218,175,0.71) 100%)",

    base: "#FFFFFF",
    sameBase: "#FFFFFF",
    differentBase: "#000000",
    fontColor: "#CBCBCB",
    buttonTextColor: "#FFFFFF",
    btnSecondaryColor: "#282828",
    contactUsSectionColor: "#282828",
    indicatorGradient:
      "linear-gradient(87.75deg, #0058ee 0%, rgba(54, 215, 183, 0.8) 100%)",
    inverseIndicatorGradient: "FFFFFF",
    cardBorder: "#484848",

    historyCardGradient:
      "linear-gradient(89.93deg, #232222 0.11%, #29292f 100.93%)",
    cardDateBackground: "232327",
    teamInfoCard: "#282828",
    // contactUsButtonInTeamPage:
    //   "linear-gradient(#121212, #121212) padding-box, linear-gradient(87.75deg, #0058EE 0%, rgba(54, 215, 183, 0.8) 100%) border-box",

    highlightGradient:
      "linear-gradient(87.75deg, #0058EE 0%, rgba(54, 215, 183, 0.8) 100%)",
    buttonColor:
      "linear-gradient(87.75deg, #0058EE 0%, rgba(54, 215, 183, 0.8) 100%)",
    hoveButtonGradient:
      "linear-gradient(87.75deg, #063F9F 0%, rgba(9, 158, 129, 0.99) 100%)",
    coverGradient:
      "linear-gradient(114.21deg, #232350 0%, #0C101B 29.36%, #0C101B 75.91%, #02483A 100%)",
    coverGradientHome:
      "linear-gradient(114.21deg, #232350 0%, #0C101B 29.36%, #0C101B 75.91%, #02483A 100%)",
    coverGradientHeaderHome:
      "linear-gradient(114.21deg,#232350 0%,#0C101B 29.36%,#0C101B 75.91%,#040404 100%)",
    coverGradientHeader:
      "linear-gradient(114.21deg,#232350 0%,#0C101B 29.36%,#000000 75.91%,#062d25 100%)",
    scalaGroupGradientDark:
      "radial-gradient(51.52% 188.35% at 50% 50%, #222426 0%, #181818 100%)",
    bgCoverGradient:
      "linear-gradient(114.21deg, #232350 0%, #0C101B 29.36%, #0C101B 75.91%, #02483A 100%);",
    radialBackgroundGradient:
      "radial-gradient(131.02% 310.61% at -31.02% -25.31%,#0d0c13 0%,#151515 56.25%,#212221 100%)",
    quoteGradientBackground:
      "linear-gradient(87.75deg, #0058EE 0%, rgba(54, 215, 183, 0.8) 100%);",
    blackBackgroundGradient:
      "linear-gradient(148.87deg, #252222 0%, #15151e 101.48%)",
    teamRadialColor:
      "radial-gradient(50% 50% at 47.67% 50%,rgba(35, 37, 82, 0.8) 0%,rgba(37, 37, 37, 0) 100%)",
    background: "#252525",

    menuItemBorderColor: "#5A5A5A",
    footerBorderColor: "#434343",

    // our-services page
    darkBackground: "#111111",
    cardBorderColor: "#111111",
    serviceCardRightLinearGradient:
      "linear-gradient(87.75deg, #0058ee 0%, rgba(54, 215, 183, 0.8) 100%) 1 1 1 0",
    serviceCardLeftLinearGradient:
      "linear-gradient(87.75deg, #0058ee 0%, rgba(54, 215, 183, 0.8) 100%) 1 0 1 1",
    businessTechRadialColor:
      "radial-gradient(44.93% 44.93% at 47.67% 50%, rgba(45, 39, 69, 0.8) 0%, rgba(37, 37, 37, 0) 100%)",

    // services
    softwareSelectionColor: "#111111",
    iconBackgroundGradient:
      "linear-gradient(147.97deg, #1A1919 11.87%, #303030 76.64%)",
    solftwareSolutionBorderColor: "none",

    lifeAtRTWSectionColor: "#1E1E1E",

    hoverBlue: "#001665",
    secondaryDark: "#8E8E8E",
    menuTextColor: "#938F8F",
    primary: "#1D202C",
    textDisabled: "#929292",
    mobNavBackgroundColor: "#1C1C1C",
    gray7: "#8C8C8C",
    gray8: "#595959",
    gray9: "#262626",
    borderColorBase: "#d9d9d9",
    placeholder: "#9f9f9f",
    link: "#1890ff",
    signupLink: "#19389C",
    secondaryLight: "#C2C2C2",
    yellow1: "#F7CD48",
    yellow2: "#EAB82D",
    blue1: "#19389C",
    blue2: "#1751D5",
    blue4: "#2B478B",
    blue5: "#252555",
    blue5Home: "#252555",
    accentMid: "#F5FAFF",
    darkGrey: "#E7E9ED",
    primaryLine: "#DCDCDC",
    face1: "#FFB27D",
    face2: "#E2915A",
    gray: "#F8F8F8",
    alert: "#EB5757",
    lightBlue: "#B9CBF2",
    bgColor: "#f3f4f6",
    borderColor: "#ffffff80",
    textFieldBorderColor: "#ffffff80",
    techGradientDark:
      "radial-gradient(114.05% 268.75% at 50% 50%,#1e1e1e 0%,rgba(32, 34, 71, 0.8) 100%)",
    techToolsBackgroundColor: "#000000",
    locationSectionColor: "#2D2D2D",

    companyIcon: "#929292",
    footerSectionColor: "#252525",
    grayDark: "#20201F",
    grayLight: "#E0E0E0",
    gray43: "#434343",
    borderHoverColor: "#797979",
    whiteTitle: "#F5F5F5",
    readColor: "#929292",
    whiteIcon: "#DDDDDD",
    bgTool: "#1F1E1E",
    bgSectorColor: "#171717",
    efficiencySection: "#171717",

    scrollBarColor: "#292828",
    textColor: "#f5f5f5",
    opportunitiesTextColor: "#D6D6D6",

    descriptionColor: "#D8D8D8",
    conpanyHistorySection: "#D8D8D8",

    cardPlanBG: "#3F3F3F",
    scrollBarGradient:
      "linear-gradient(87.75deg, #0058ee 0%, rgba(54, 215, 183, 0.8) 100%)",
    sectorCardGradient:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.41), rgba(0, 0, 0, 0.41)),linear-gradient(147.59deg,#1a1919 38.91%,#161e24 72.14%,rgba(13, 109, 98, 0) 178.47%)",

    shadowedBox: "none",

    detailsColor: "#ffffff",
    gray29: "#292828",
    black: "#000000",
    blackBackground: "#161414",
    applicationSectionBg: "#1C1A1A",

    textColorOnDark: "#000000",
    sameTextColor: "#FFFFFF",
    switchColor: "#989898",

    baseTextColor: "#D6D6D6",
    cardColor: "#1F1E1E",
    mainDark: "#121212",
    buttonGradientFirst: "#0058EE",
    buttonGradientSecond: "#36D7B7",
    searchBarBorderColor: "#5C5C5C",

    careerPhotosbackground: "#1E1E1E",

    unSeenCircle: "#1f1e1e",
    seenTimeline: "#656565",

    typography,
  },
  lightTheme: {
    toggleColor: "linear-gradient(104deg, #F6AB3B 0%, #DD9675 100%)",
    base: "#FFFFFF",
    sameBase: "#424242",
    differentBase: "#FFFFFF",
    fontColor: "#333333",
    buttonTextColor: "#0956B8",
    btnSecondaryColor: "#FFFFFF",
    contactUsSectionColor: "#F8F8F8",
    indicatorGradient: "#FFFFFF",
    inverseIndicatorGradient:
      "linear-gradient(87.75deg, #0058ee 0%, rgba(54, 215, 183, 0.8) 100%)",
    cardBorder: "#d0cece",

    historyCardGradient: "#FFFFFF",
    cardDateBackground: "#E7E9EC",
    teamInfoCard: "#F5F5F5",
    // contactUsButtonInTeamPage:
    //   "linear-gradient(#121212, #121212) padding-box, linear-gradient(87.75deg, #0058EE 0%, rgba(54, 215, 183, 0.8) 100%) border-box",

    highlightGradient:
      "linear-gradient(87.75deg, #0058EE 0%, rgba(54, 215, 183, 0.8) 100%)",
    buttonColor: "#FFFFFF",

    hoveButtonGradient: "#FFFFFF",
    coverGradient:
      "radial-gradient(119.79% 52.91% at 50% 50%, #fff 0%, #fff 0%, #fdfeff 54.17%, #eff5ff 100%)",
    coverGradientHome:
      "linear-gradient(88deg, #0958DE 0%, rgba(0, 218, 175, 0.71) 100%)",
    coverGradientHeaderHome: "#FFFFFF",
    coverGradientHeader:
      "linear-gradient(114.21deg,#232350 0%,#0C101B 29.36%,#000000 75.91%,#062d25 100%)",
    scalaGroupGradientDark: "#ffffff",
    bgCoverGradient:
      "linear-gradient(114.21deg, #232350 0%, #0C101B 29.36%, #0C101B 75.91%, #02483A 100%);",
    radialBackgroundGradient:
      "radial-gradient(131.02% 310.61% at -31.02% -25.31%,#0d0c13 0%,#151515 56.25%,#212221 100%)",
    quoteGradientBackground:
      "linear-gradient(87.75deg, #0058EE 0%, rgba(54, 215, 183, 0.8) 100%);",
    blackBackgroundGradient:
      "linear-gradient(148.87deg, #252222 0%, #15151e 101.48%)",
    teamRadialColor:
      "linear-gradient(88deg, #0058EE 0%, rgba(54, 215, 183, 0.80) 100%)",
    background: "#252525",

    menuItemBorderColor: "#FFFFFF",
    footerBorderColor: "#E1E1E1",

    darkBackground:
      "radial-gradient(119.79% 52.91% at 50% 50%, #fff 0%, #fff 0%, #fdfeff 54.17%, #eff5ff 100%)",
    cardBorderColor: "#FFFFFF",
    serviceCardRightLinearGradient:
      "linear-gradient(87.75deg, #0058ee 0%, rgba(54, 215, 183, 0.8) 100%) 1 1 1 0",
    serviceCardLeftLinearGradient:
      "linear-gradient(87.75deg, #0058ee 0%, rgba(54, 215, 183, 0.8) 100%) 1 0 1 1",
    businessTechRadialColor: "none",

    // services
    softwareSelectionColor: "#F8F8F8",
    iconBackgroundGradient: "#FFFFFF",
    solftwareSolutionBorderColor: "#DDDDDD",

    lifeAtRTWSectionColor: "#F5F5F5",

    hoverBlue: "#001665",
    secondaryDark: "#8E8E8E",
    menuTextColor: "#938F8F",
    primary: "#1D202C",
    textDisabled: "#929292",
    mobNavBackgroundColor: "#EAF5FB",
    gray7: "#8C8C8C",
    gray8: "#595959",
    gray9: "#262626",
    borderColorBase: "#d9d9d9",
    placeholder: "#9f9f9f",
    link: "#1890ff",
    signupLink: "#19389C",
    secondaryLight: "#C2C2C2",
    yellow1: "#F7CD48",
    yellow2: "#EAB82D",
    blue1: "#19389C",
    blue2: "#1751D5",
    blue4: "#2B478B",

    blue5: "#FFFFFF",
    blue5Home: "#252555",

    accentMid: "#F5FAFF",
    darkGrey: "#E7E9ED",
    primaryLine: "#DCDCDC",
    face1: "#FFB27D",
    face2: "#E2915A",
    gray: "#F8F8F8",
    alert: "#EB5757",
    lightBlue: "#B9CBF2",
    bgColor: "#f3f4f6",

    borderColor: "#ffffff80",
    textFieldBorderColor: "#8b94a5",

    techGradientDark: "#FFFFFF",
    techToolsBackgroundColor: "#0956B8",
    locationSectionColor: "#0956B8",
    companyIcon: "#929292",
    footerSectionColor: "#FFFFFF",
    grayDark:
      "linear-gradient(88deg, #0058EE 0%, rgba(54, 215, 183, 0.80) 100%)",
    grayLight: "#E0E0E0",
    gray43: "#434343",
    borderHoverColor: "#797979",
    whiteTitle: "#F5F5F5",
    readColor: "#929292",
    whiteIcon: "#DDDDDD",
    bgTool: "#FFFFFF",
    bgSectorColor: "#E5EBF5",
    efficiencySection:
      "linear-gradient(88deg, #0058EE 0%, rgba(54, 215, 183, 0.80) 100%)",

    scrollBarColor: "#FFFFFF",
    textColor: "#333333",
    opportunitiesTextColor: "#333333",

    descriptionColor: "#FFFFFF",
    conpanyHistorySection: "#424242",

    cardPlanBG: "#FFFFFF",
    scrollBarGradient:
      "linear-gradient(87.75deg,#0058ee 0%,rgba(54, 215, 183, 0.8) 100%)",
    sectorCardGradient: "#FFFFFF",

    shadowedBox:
      "0px 0px 0px 0px rgba(14, 102, 196, 0.1),2px 8px 19px 0px rgba(14, 102, 196, 0.1),8px 33px 34px 0px rgba(14, 102, 196, 0.09),17px 75px 46px 0px rgba(14, 102, 196, 0.05),30px 133px 54px 0px rgba(14, 102, 196, 0.01),47px 207px 59px 0px rgba(14, 102, 196, 0)",

    detailsColor: "#ffffff",
    gray29: "#7F94DD",
    black: "#000",
    blackBackground: "#FFFFFF",
    applicationSectionBg:
      "radial-gradient(119.79% 52.91% at 50% 50%, #FFF 0%, #FFF 0%, #FDFEFF 54.17%, #EFF5FF 100%)",

    textColorOnDark: "#000000",
    sameTextColor: "#FFFFFF",
    switchColor: "#989898",
    baseTextColor: "#FFFFFF",
    cardColor: "#FFFFFF",
    mainDark: "#F5F5F5",
    buttonGradientFirst: "#0058EE",
    buttonGradientSecond: "#36D7B7",
    searchBarBorderColor: "#5C5C5C",

    careerPhotosbackground: "#F5F5F5",

    unSeenCircle: "#FFFFFF",
    seenTimeline: "#FFFFFF",

    typography,
  },
}

export const admin = {
  // admin colors
  darkTheme: {
    toggleColor:
      "linear-gradient(88deg, #0958DE 0%, rgba(0,218,175,0.71) 100%)",

    base: "#FFFFFF",
    primary: "#1D202C",
    mainDark: "#121212",
    buttonGradientFirst: "#0058EE",
    buttonGradientSecond: "#36D7B7",
    bluishGreen: "#00B6B6",
    fadedGreen: "#34A853",
    adminSideBarColor: "#1B1B1B",
    headerColor: "#1F1E1E",
    cardColor: "#1F1E1E",
    lightYellow: "#FF9900",
    searchBarBorderColor: "#5C5C5C",
    private: "#626262",
    public: "#8CCE9E",
    pending: "#F5D783",
    tableBorder: "#3D3D3D",
    tableRowColor: "none",
    tableHeaderColor: "transparent",
    uploadTextColor: "#DDDDDD",
    uploadIconColor: "#40A9FF",
    borderColor: "#ffffff80",
    borderColorBase: "#d9d9d9",
    cardBackground: "#2d2d2db3",
    baseTextColor: "#D6D6D6",
    switchColor: "#989898",
    sameTextColor: "#FFFFFF",
    textColorOnDark: "#000000",
    tabBarIconColor: "#ffffff1a",
    hoverColor: "#ffffff1a",
    scrollBarGradient:
      "linear-gradient(87.75deg,#0058ee 0%,rgba(54, 215, 183, 0.8) 100%)",
    cardBorderColor: "#0058ee",
  },
  lightTheme: {
    toggleColor: "linear-gradient(104deg, #F6AB3B 0%, #DD9675 100%)",

    base: "#121212",
    primary: "#FFFFFF",
    mainDark: "#F5F5F5",
    buttonGradientFirst: "#00B6B6",
    buttonGradientSecond: "#00B6B6",
    bluishGreen: "#00B6B6",
    fadedGreen: "#34A853",
    adminSideBarColor: "rgba(0, 22, 101, 1)",
    headerColor: "#FFFFFF",
    cardColor: "#FFFFFF",
    lightYellow: "#FF9900",
    searchBarBorderColor: "#5C5C5C",
    private: "#626262",
    public: "#8CCE9E",
    pending: "#F5D783",
    tableBorder: "transparent",
    tableRowColor: "#F8F8F8",
    tableHeaderColor: `#dff2f3`,
    uploadTextColor: "#DDDDDD",
    uploadIconColor: "#40A9FF",
    borderColor: "#000000",
    borderColorBase: "#d9d9d9",
    cardBackground: "#D2D2D2",
    baseTextColor: "#121212",
    switchColor: "#989898",
    sameTextColor: "#FFFFFF",
    textColorOnDark: "#121212",
    tabBarIconColor: "#0000001a",
    hoverColor: "#ffffff1a",
    scrollBarGradient:
      "linear-gradient(87.75deg,#0058ee 0%,rgba(54, 215, 183, 0.8) 100%)",
    cardBorderColor: "#0058ee",
  },
}

export const antdTheme: ThemeConfig = {
  token: {
    fontSize: 16,
    colorText: `${(props) => props.theme.base}`,
    colorPrimary: `${(props) => props.theme.base}`,
    fontFamily: "'Montserrrat', sans-serif",
    colorBgLayout: `${(props) => props.theme.background}`,
    fontSizeHeading1: 42,
    fontSizeHeading2: 32,
    fontSizeHeading3: 22,
  },
  components: {
    Button: {
      colorText: `${(props) => props.theme.base}`,
      fontFamily: "'Lato', sans-serif",
      borderRadius: 5,
      controlHeight: 49,
      controlHeightSM: 39,
      controlHeightLG: 59,
    },
  },
  algorithm: mainTheme.darkAlgorithm,
}
