import styled from "styled-components"
import { ISoftwareSolutionCard } from "../../molecules/SoftwareSolutionCard"
import { AosWrapper } from "../../molecules/AosWrapper"
import { animationProperties } from "../../../utils"
import { useState } from "react"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

const Wrapper = styled.div`
  height: 345px;
  max-width: 310px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.base};
  @media (max-width: 1350px) {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
`

const IconStyle = styled.div<{ isLightTheme: boolean }>`
  background-color: ${(props) => props.theme.cardPlanBG};
  border-radius: 15px;
  padding: 20px;
  z-index: 2;
  border: ${({ isLightTheme }) =>
    !isLightTheme ? "1px solid #DDDDDD" : "none"} !important;
  img {
    height: 57.4px;
    width: 57.4px;
  }
  :hover {
    background: ${(props) => props.theme.highlightGradient};
  }
`

const TextStyle = styled.div`
  ${(props) => props.theme.typography.paragraph2}
  font-size: 1rem;
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
  white-space: break-spaces;
  color: ${(props) => props.theme.fontColor};
  @media (max-width: 1350px) {
    padding: 0px 200px;
    white-space: normal;
  }
  @media (max-width: 768px) {
    padding: 0px 0px;
  }
  @media (max-width: 576px) {
    font-size: 0.875rem;
  }
  @media (max-width: 374px) {
    font-size: 0.625rem;
  }
`
const TitleStyle = styled.div`
  ${(props) => props.theme.typography.cardTitle}
  margin-top:40px;
  text-align: center;
  white-space: break-spaces;
  line-height: 22.6px;
  color: ${(props) => props.theme.sameBase};
  @media (max-width: 576px) {
    font-size: 1.125rem;
    margin-top: 40px;
  }
  @media (max-width: 374px) {
    font-size: 0.75rem;
    margin-top: 30px;
  }
`

export const SoftWareDescriptionCard: React.FC<ISoftwareSolutionCard> = ({
  icon,
  content,
  title,
  lightIcon,
}) => {
  const isDarkTheme = useDeviceTheme()
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleMouseEnter = () => setIsHovered(true)

  const handleMouseLeave = () => setIsHovered(false)
  return (
    <Wrapper>
      <AosWrapper
        duration={animationProperties.duration + 0.5}
        delay={animationProperties.delay + 0.5}
        animateIn={"animate__fadeInUpBig animate__zoomIn"}
        style={{ zIndex: 2 }}
      >
        <IconStyle
          isLightTheme={isDarkTheme}
          className={"icon-inner"}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {isDarkTheme || isHovered ? icon : lightIcon}
        </IconStyle>
      </AosWrapper>

      <TitleStyle>{title}</TitleStyle>
      <TextStyle>{content}</TextStyle>
    </Wrapper>
  )
}
