import React from "react"
import styled from "styled-components"
import { CompanyHistoryComponent } from "../../molecules"
import { CompanyHistory as companyHistoryData } from "./data"
import { AosWrapper } from "../../molecules/AosWrapper"
import { animationProperties } from "../../../utils"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

const Wrapper = styled.div`
  width: 100%;
  background: ${(props) => props.theme.footerSectionColor};
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  .scaling-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .initial-line {
    height: 500px;
    margin-top: -32em;
    z-index: -1;
    @media (max-width: 768px) {
      height: 500px;
      margin-top: -22em;
    }
    @media (max-width: 642px) {
      height: 450px;
      margin-top: -18em;
      z-index: -1;
    }
  }
`

const AllStarContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 928px;
  width: 100%;
`

const Headline = styled.div<{ isDarkTheme: boolean; isLightTheme: boolean }>`
  height: 134px;
  z-index: 1;
  display: flex;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    width: 730px;
    height: 730px;
    top: -20rem;
    z-index: -3;
    background: ${(props) => props.theme.teamRadialColor};
    filter: ${(props) => (!props.isDarkTheme ? "blur(467px)" : "none")};
    opacity: ${(props) => (!props.isDarkTheme ? " 0.22" : "none")};
  }

  @media (max-width: 768px) {
    &::before {
      content: "";
      position: absolute;
      width: 320px;
      height: 320px;
      top: -8rem;
      z-index: -3;
      ${(props) =>
        props.isDarkTheme &&
        `background: ${props.theme.teamRadialColor};;
        filter: blur(467px);
        opacity: 0.22;
      `};
    }
  }

  p {
    ${(props) => props.theme.typography.heroHeadline3}
    color: ${(props) => props.theme.baseTextColor};
    ${(props) =>
      props.isLightTheme &&
      `background: ${props.theme.highlightGradient};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `};
    max-width: 645px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 1.25rem !important;
      max-width: 300px;
    }
  }
  .textGradient {
    font-size: 2rem;
    ${(props) => props.theme.typography.TextLinearGradient}
    @media (max-width: 768px) {
      font-size: 1.25rem !important;
    }
  }
`

const StarImg = styled.div`
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 200px;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
  img {
    width: 101px;
    height: 101px;
    @media (max-width: 768px) {
      width: 63px;
      height: 63px;
    }
  }
`
const CompanyTimeline = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const AllStarComponent: React.FC = () => {
  const isDarkTheme = useDeviceTheme()
  return (
    <Wrapper>
      <AosWrapper>
        <AllStarContent>
          <Headline isDarkTheme={isDarkTheme} isLightTheme={!isDarkTheme}>
            <p>
              {"We are gradually"}{" "}
              <span className={"textGradient"}>{" scaling up"}</span>{" "}
              {"our horizon with a team of "}
              <span className={"textGradient"}>{" all-stars!"}</span>
            </p>
          </Headline>
          <StarImg>
            <img src={"assets/icons/stars.svg"} alt={"stars"} />
          </StarImg>
          <CompanyTimeline>
            {companyHistoryData.map((data, index) => {
              return (
                <div key={index} className="scaling-container">
                  <img
                    src={"/assets/icons/dotted-line.svg"}
                    className={!index && "initial-line"}
                  />
                  <AosWrapper
                    duration={animationProperties.duration + 0.2}
                    delay={animationProperties.delay + 0.2}
                    animateIn={"animate__fadeInUpBig animate__zoomIn"}
                    style={{ position: "relative", zIndex: 2 }}
                  >
                    <CompanyHistoryComponent
                      date={data.date}
                      details={data.description}
                      title={data.title}
                    />
                  </AosWrapper>
                </div>
              )
            })}
          </CompanyTimeline>
        </AllStarContent>
      </AosWrapper>
    </Wrapper>
  )
}
export { AllStarComponent }
