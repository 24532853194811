import React from "react"
import styled from "styled-components"

interface ArticleProps {
  articlephoto: string
  articletitle: string
  readingtime: string
  previewtext: string
  username: string
  releasedate: string
}

const ArticleUserDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  gap: 10px;
`
const ReadTime = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 6px;
  img {
    width: 17px;
    height: 17px;
  }
  .readtime-text {
    ${(props) => props.theme.typography.typographyMap}
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.readColor};
  }
  @media (max-width: 365px) {
    margin-top: 0;
  }
`
const ArticleCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  width: 400px;
  padding: 30px 20px;
  background: ${(props) => props.theme.cardColor};
  border-radius: 25px;
  .cover-image {
    border-radius: 12px;
    object-fit: fill;
  }
  @media (max-width: 460px) {
    width: 340px;
    .cover-image {
      height: 222px;
    }
  }
  @media (max-width: 1420px) {
    width: 340px;
    .cover-image {
      height: 222px;
    }
  }
  @media (max-width: 365px) {
    width: 300px;
  }
  .medium-content {
    ${(props) => props.theme.typography.paragraph4}
    color: ${(props) => props.theme.sameBase};
    height: 72px;
    overflow: hidden;
    margin-top: 10px;
  }
  .read-more {
    ${(props) => props.theme.typography.typographyMap}
    color: ${(props) => props.theme.sameBase};
    margin-top: 10px;
    @media (max-width: 1420px) {
      font-size: 12px;
    }
  }
`
const ArticleDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  height: 44px;
  .article-username {
    ${(props) => props.theme.typography.typographyMap}
    display: flex;
    align-items: center;
    text-align: center;
    color: ${(props) => props.theme.sameBase};
    @media (max-width: 1420px) {
      font-size: 14px;
    }
  }
  .article-date {
    ${(props) => props.theme.typography.typographyMap1}
    display: flex;
    align-items: center;
    text-align: center;
    color: ${(props) => props.theme.sameBase};
    @media (max-width: 1420px) {
      font-size: 12px;
    }
  }
`
const ArticleTitle = styled.div`
  height: 80px;
  margin-top: 30px;
  ${(props) => props.theme.typography.heroHeadline2}
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.base};
  @media (max-width: 1420px) {
    font-size: 1.125rem;
  }
  @media (max-width: 365px) {
    font-size: 0.875rem;
    margin-top: 0;
  }
`

const ArticleCardComponent: React.FC<ArticleProps> = ({
  articlephoto,
  articletitle,
  readingtime,
  previewtext,
  username,
  releasedate,
}) => {
  return (
    <ArticleCardStyle>
      <img
        src={articlephoto}
        width={"100%"}
        height={"222px"}
        className={"cover-image"}
      />
      <ArticleTitle>{articletitle}</ArticleTitle>
      <ReadTime>
        <img src={"assets/imgs/round-time.svg"} />
        <div className={"readtime-text"}>{readingtime}</div>
      </ReadTime>
      <div className={"medium-content"}>{previewtext}</div>
      <div className={"read-more"}>{"Read more"}</div>
      <ArticleUserDetails>
        <img src={"/assets/imgs/user.png"} width={"44px"} height={"44px"} />
        <ArticleDetails>
          <div className={"article-username"}>{username}</div>
          <div className={"article-date"}>{releasedate}</div>
        </ArticleDetails>
      </ArticleUserDetails>
    </ArticleCardStyle>
  )
}

export { ArticleCardComponent }
