import React, { useState } from "react"
import styled from "styled-components"
import HexLarge from "../../../public/assets/imgs/hexagon.svg"
import HexSmall from "../../../public/assets/imgs/hexagon-small.svg"
import HexMedium from "../../../public/assets/imgs/hexagon-medium.svg"

import HexLargeLight from "../../../public/assets/imgs/hexagon-light.svg"
import HexSmallLight from "../../../public/assets/imgs/hexagon-small-light.svg"
import HexMediumLight from "../../../public/assets/imgs/hexagon-medium-light.svg"

import { DescriptivePoints } from "../DescriptivePoints"
import { Grid } from "antd"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

const Container = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .first-image {
    svg {
      width: ${({ type }) => (type === "motivation" ? "558.69px" : "400px")};
      height: ${({ type }) => (type === "motivation" ? "524.96px" : "374px")};
      @media (max-width: 1150px) {
        min-width: 450.96px;
        min-height: 524.96px;
        width: 100%;
        height: auto;
      }
      @media (max-width: 600px) {
        min-width: 300px;
        min-height: ${({ type }) =>
          type === "motivation" ? "384px" : "297px"};
        width: 100%;
        height: auto;
      }
      @media (max-width: 340px) {
        padding-left: 10px;
        path {
          scale: 0.9;
        }
      }
    }
  }
  .inner-hexagon {
    display: flex;
    position: absolute;
    z-index: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 340px) {
      margin-top: -30px;
    }
    img {
      @media (max-width: 340px) {
        scale: 0.9;
      }
      @media (max-width: 600px) {
        height: 70px;
        width: 40px;
      }
    }

    .title {
      color: ${(props) => props.theme.sameBase};
      text-align: center;
      font-weight: 700;
      font-size: 1.125rem;
      margin: 20px 0;
      @media (max-width: 340px) {
        font-size: 1rem;
        margin: 10px 0;
      }
    }
  }
`
const { useBreakpoint } = Grid
interface IHexagonProps {
  type: string
  className: any
}

const Hexagon: React.FC<IHexagonProps> = ({ type, className }) => {
  const breakPoint = useBreakpoint()
  const isDarkTheme = useDeviceTheme()
  const contents = {
    motivation: {
      image: "/assets/imgs/bulb.svg",
      imageOnHover: "/assets/imgs/bulb-hover.svg",
      title: "Motivation",
      array: [
        { id: 1, value: "Competitive Salary" },
        { id: 2, value: "MacBook Air device" },
        { id: 3, value: "Employee of the month opportunity" },
        { id: 4, value: "Lunch and other Allowances" },
        {
          id: 5,
          value: "Performance Evaluation\ntwice a year",
        },
      ],
    },
    growth: {
      image: "/assets/imgs/plants-image.svg",
      imageOnHover: "/assets/imgs/plants-image-hover.svg",
      title: "Self Growth",
      array: [
        { id: 1, value: "R&D on various domains" },
        { id: 2, value: "Leadership challenges" },
      ],
    },
    entertainment: {
      image: "/assets/imgs/entertainment.svg",
      imageOnHover: "/assets/imgs/entertainment-hover.svg",
      title: "Entertainment",
      array: [
        { id: 1, value: "Frequent outings" },
        { id: 2, value: "Team building activities" },
      ],
    },
    workLife: {
      image: "/assets/imgs/smiley-face.svg",
      imageOnHover: "/assets/imgs/smiley-face-hover.svg",
      title: "Work - Life Balance",
      array: [
        { id: 1, value: "5 working days a week" },
        { id: 2, value: "Work from home" },
      ],
    },
  }

  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <Container
      className={`hexagon ${className}`}
      type={type}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="first-image">
        {breakPoint.xs ? (
          type === "motivation" ? (
            isDarkTheme ? (
              <HexSmall />
            ) : (
              <HexSmallLight />
            )
          ) : isDarkTheme ? (
            <HexMedium />
          ) : (
            <HexMediumLight />
          )
        ) : isDarkTheme ? (
          <HexLarge />
        ) : (
          <HexLargeLight />
        )}
      </div>
      <div className="inner-hexagon">
        <img
          src={
            isHovered ? contents?.[type]?.imageOnHover : contents?.[type]?.image
          }
          alt=""
        />
        <span className="title">{contents?.[type]?.title}</span>
        <DescriptivePoints itemArray={contents?.[type]?.array} />
      </div>
    </Container>
  )
}

export { Hexagon }
