import React from "react"
import { CompanyDetailCard } from "../../molecules"
import { ICompanyDetailCard } from "../../molecules/CompanyDetailCard"
import styled from "styled-components"

interface ICompanyDetailGrid {
  items: Array<ICompanyDetailCard>
}

const Wrapper = styled.div`
  width: 1056px;
  margin: auto;
  @media (max-width: 1115px) {
    width: 100%;
  }
`

const GridStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;
  @media (max-width: 1115px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 876px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 576px) {
    gap: 20px;
  }
  @media (max-width: 365px) {
    grid-template-columns: 1fr;
  }
`

const CompanyDetailGrid: React.FC<ICompanyDetailGrid> = ({ items }) => {
  return (
    <Wrapper>
      <GridStyle>
        {items.map((item, index) => (
          <CompanyDetailCard
            key={index}
            title={item.title}
            count={item.count}
            icon={item.icon}
          />
        ))}
      </GridStyle>
    </Wrapper>
  )
}

export { CompanyDetailGrid }
