import { Divider } from "antd"
import styled from "styled-components"
import { ICompanyIcons } from "../../../interface"
import { CompanyIcon } from "../../atoms"

const TechDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;

  @media only screen and (max-width: 1375px) {
    display: flex;
    margin: auto;
    padding-left: 0px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }

  .title {
    ${(props) => props.theme.typography.sectionHeadline2};
    color: ${(props) => props.theme.sameBase};

    @media only screen and (max-width: 500px) {
      font-size: 1.375rem;
      text-align: center;
    }

    @media only screen and (max-width: 375px) {
      width: 230px;
      margin: auto;
    }
  }

  span {
    background: linear-gradient(to right, #0058ee, #36d7b7cc);
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .info {
    ${(props) => props.theme.typography.paragraphInfo};
    color: ${(props) => props.theme.sameBase};
    text-align: justify;
    max-width: 664px;
    margin-top: 19px;

    @media only screen and (max-width: 500px) {
      font-size: 0.875rem;
      text-align: center;
    }
  }
`

const CompanyIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 70px;

  @media only screen and (max-width: 500px) {
    margin-top: 30px;
  }

  .divider {
    background: ${(props) => props.theme.gray43};
    width: 100%;
    width: 1px;
    height: auto;
    margin: 0 60px;

    @media only screen and (max-width: 500px) {
      height: 40px;
      display: flex;
      align-self: center;
      margin: 0 30px;
    }
  }

  .company-icons {
    display: flex;
  }
`

const TechInfo = () => {
  const tech_company_icons: Array<ICompanyIcons> = [
    {
      icon: "/assets/icons/thumb.svg",
      lightIcon: "/assets/icons/thumb-light.svg",
      name: "Open & Respectful",
    },
    {
      icon: "/assets/icons/lock.svg",
      lightIcon: "/assets/icons/lock-light.svg",
      name: "Integrity & Honesty",
    },
    {
      icon: "/assets/icons/users.svg",
      lightIcon: "/assets/icons/users-light.svg",
      name: "Inclusion & Belonging",
    },
  ]

  return (
    <TechDescription>
      <h3 className={"title"}>
        {"Who are we as "}
        <span className={"title"}>{"tech-company?"}</span>
      </h3>
      <p className={"info"}>
        {`We are Japan based software company ready to challenge every problems
      with our tech expertise. We promote co-creation, and support system
      development for a wide range of customers, from major companies to
      startups and local governments.`}
      </p>
      <CompanyIconsWrapper>
        {tech_company_icons?.map((icon, i) => {
          return (
            <div key={i} className={"company-icons"}>
              <CompanyIcon
                icon={icon?.icon}
                lightIcon={icon?.lightIcon}
                name={icon?.name}
              />
              {i != 2 && <Divider type={"vertical"} className={"divider"} />}
            </div>
          )
        })}
      </CompanyIconsWrapper>
    </TechDescription>
  )
}

export default TechInfo
