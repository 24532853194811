import { TextField } from "@project/shared/src/components/atom/TextField"
import { Button, notification } from "antd"
import { useFormik } from "formik"
import { useMutation } from "react-query"
import styled from "styled-components"
import * as yup from "yup"
import { createInquiry } from "../../../services"

const FormCardWrapper = styled.div`
  margin: auto;
  max-width: 637px;
  min-height: auto;
  border-radius: 40px;
  background: ${(props) => props.theme.cardBackground};
  padding: 30px 50px 50px 50px;

  @media (max-width: 500px) {
    padding: 20px 20px;
    border-radius: 20px;
  }
`

const GradientButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  background: ${(props) => props.theme.highlightGradient};
  color: ${(props) => props.theme.base} !important;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  height: 49px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  span > img {
    margin-top: 8px;
    margin-left: 10px;
  }
  @media (max-width: 500px) {
    border-radius: 5px;
    font-size: 0.75rem;
    height: 38px;
    span > img {
      display: none;
    }
  }
`
const InputFieldWrapper = styled.div`
  margin-top: 20px;
  & .text-area {
    width: 100%;
    .ant-input-affix-wrapper {
      background-color: transparent;
    }
    .ant-input {
      padding: 25px 15px 0px 15px;
      background-color: transparent;
      font-size: 1rem;
      @media (max-width: 500px) {
        font-size: 0.875rem;
      }
    }
  }
  & .text-field {
    width: 100%;
    .ant-input-affix-wrapper {
      background-color: transparent;
    }
    .ant-input {
      background-color: transparent;
      font-size: 1rem;
      font-family: "Inter", sans-serif;
      height: 58px;
      @media (max-width: 500px) {
        height: 38px;
        padding: 12px;
        font-size: 0.875rem;
      }
    }
  }
`

interface ContactType {
  full_name: string
  email: string
  phone_no: string
  inquiry: string
}

const ContactForm: React.FC = () => {
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please use valid email address")
      .required("Please enter email address"),
  })

  const notifyUser = (message: string) => {
    notification.success({
      key: "contact-form",
      message: message,
    })
  }

  const { mutate, isLoading } = useMutation(createInquiry, {
    onError: (error: any) => {
      const errorMsg = error?.data?.error?.error
      notifyUser(errorMsg || "Failed to submit inquiry")
    },
    onSuccess: () => {
      notifyUser("Inquiry submitted successfully")
      formik.resetForm()
    },
  })

  const formik = useFormik<ContactType>({
    initialValues: {
      full_name: "",
      email: "",
      phone_no: "",
      inquiry: "",
    },
    validationSchema,
    onSubmit: (values) => mutate(values),
  })

  return (
    <FormCardWrapper>
      <form onSubmit={formik.handleSubmit}>
        <InputFieldWrapper>
          <TextField
            name={"full_name"}
            className={"text-field"}
            borderradius={"6px"}
            value={formik.values.full_name}
            onChange={formik.handleChange}
            placeholder={"What’s your name?"}
          />
        </InputFieldWrapper>

        <InputFieldWrapper>
          <TextField
            name={"email"}
            className={"text-field"}
            borderradius={"6px"}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
            placeholder={"Your email address here"}
          />
        </InputFieldWrapper>

        <InputFieldWrapper>
          <TextField
            name={"phone_no"}
            className={"text-field"}
            borderradius={"6px"}
            value={formik.values.phone_no}
            placeholder={"Your Phone number"}
            onChange={formik.handleChange}
          />
        </InputFieldWrapper>

        <InputFieldWrapper>
          <TextField
            name={"inquiry"}
            type={"textarea"}
            className={"text-area"}
            value={formik.values.inquiry}
            borderradius={"6px"}
            placeholder={"Tell us your inquiry"}
            onChange={formik.handleChange}
            rows={7}
          />
        </InputFieldWrapper>
        <GradientButton loading={isLoading} htmlType={"submit"}>
          {"Send Inquiry"}{" "}
          <span>
            <img src={"assets/icons/arrow.svg"}></img>
          </span>
        </GradientButton>
      </form>
    </FormCardWrapper>
  )
}

export { ContactForm }
