import React from "react"
import styled from "styled-components"
import { GradientText } from "../../../utils"
import { Hexagon } from "../../atoms/Hexagon"

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.mainDark};
  .text {
    margin: 7em 0 5em;
    text-align: center;
    color: ${(props) => props.theme.sameBase};
    span {
      font-weight: 700;
      font-size: 2rem;
      @media (max-width: 600px) {
        font-size: 22px;
      }
    }
  }
  .hexagon-container {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    @media (max-width: 1150px) {
      flex-direction: column;
    }
    .inner-hexagon-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .first {
      margin-right: -6em;
      @media (max-width: 1150px) {
        margin-right: 0;
      }
    }
    .fourth {
      margin-left: -6em;
      @media (max-width: 1150px) {
        margin-left: 0;
      }
      @media (max-width: 500px) {
        margin: 1em 0;
      }
    }
    .second {
      margin-bottom: -2em;
      @media (max-width: 1150px) {
        margin-bottom: 0;
      }
      @media (max-width: 500px) {
        margin: 1em 0;
      }
    }
  }
`

const ExperienceSection = () => {
  return (
    <Container>
      <div className="text">
        <span>
          {"Experience the "}
          <GradientText>{"perks"}</GradientText>
          {" of being valued member of "}
          <GradientText>{"RTW"}</GradientText>
        </span>
      </div>
      <div className="hexagon-container">
        <Hexagon type="motivation" className={"first"} />
        <div className="inner-hexagon-container">
          <Hexagon type="growth" className="second" />
          <Hexagon type="entertainment" className="third" />
        </div>
        <Hexagon type="workLife" className="fourth" />
      </div>
    </Container>
  )
}

export { ExperienceSection }
