import React from "react"
import styled from "styled-components"
import { IVacancy } from "../../../utils/interfaces/vacancy"
import { HiringCard } from "../../atoms"

const CardList = styled.div`
  max-width: 1102px;
  width: 100%;
  height: 671px;
  overflow-y: scroll;
  padding: 0 60px;

  @media (max-width: 768px) {
    max-width: 335px;
    width: 100%;
    padding: 0px;
  }
  @media (max-width: 360px) {
    max-width: 290px;
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
    background: ${(props) => props.theme.scrollBarColor};

    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 2px;
    }
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: ${(props) => props.theme.scrollBarColor};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.scrollBarGradient};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`
interface IVacancyprops {
  vacancyData: IVacancy[]
}

export const HiringCardList: React.FC<IVacancyprops> = ({ vacancyData }) => {
  return (
    <CardList>
      {vacancyData?.map((item, index) => {
        return (
          <HiringCard
            key={index}
            title={item.title}
            position={item.position}
            candidateNumber={item.no_of_candidate.toString()}
          />
        )
      })}
    </CardList>
  )
}
