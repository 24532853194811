import React from "react"
import styled from "styled-components"
import { AosWrapper } from "../../molecules/AosWrapper"
import { animationProperties } from "../../../utils"

const ImagesContainer = styled.div`
  max-width: 1500px;

  img {
    width: 100%;
    height: auto;
  }

  @media only screen and (max-width: 1375px) {
    margin-top: 35px;
  }

  @media only screen and (max-width: 500px) {
    margin-top: 30px;
  }
`

const LargeScreenImages = styled.div`
  @media only screen and (max-width: 1375px) {
    display: none;
  }

  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;

  .column {
    flex: 100%;
    max-width: 310px;
    padding-left: 20px;
  }

  .column img {
    vertical-align: middle;
    width: 100%;
  }

  .column img:not(:last-child) {
    margin-bottom: 20px;
  }

  #long-img {
    height: 100%;
  }
`

const SmallScreenImages = styled.div`
  @media only screen and (min-width: 1375px) {
    display: none;
  }

  @media only screen and (max-width: 1375px) {
    display: flex;
    justify-content: center;
  }

  .row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 40px 40px 60px 40px;

    @media only screen and (max-width: 500px) {
      grid-gap: 10px;
      padding: 0px 20px 0px 20px;
    }
  }

  .grid-item:nth-child(4) {
    grid-column: span 2;
  }
`

const TechImages = () => {
  return (
    <AosWrapper
      animatePreScroll={true}
      animateIn={"animate__fadeInUpBig"}
      duration={animationProperties.duration + 0.2}
      delay={animationProperties.delay + 0.2}
    >
      <ImagesContainer>
        <LargeScreenImages>
          <div className={"column"}>
            <img src={"/assets/imgs/tech4.png"} />
            <img src={"/assets/imgs/tech3.png"} />
          </div>
          <div className={"column"}>
            <img src={"/assets/imgs/tech2.png"} id={"long-img"} />
          </div>
        </LargeScreenImages>
        <SmallScreenImages>
          <div className={"row"}>
            <img src={"/assets/imgs/techm3.svg"} className={"grid-item"} />
            <img src={"/assets/imgs/techm5.svg"} className={"grid-item"} />
            <img src={"/assets/imgs/techm4.svg"} className={"grid-item"} />
            <img src={"/assets/imgs/techm1.svg"} className={"grid-item"} />
            <img src={"/assets/imgs/techm2.svg"} className={"grid-item"} />
          </div>
        </SmallScreenImages>
      </ImagesContainer>
    </AosWrapper>
  )
}

export default TechImages
