import styled from "styled-components"
import { TeamImgCard, TeamImgProps } from "../../atoms"

interface ITeamImgProps {
  items: Array<TeamImgProps>
}

const Wrapper = styled.div`
  width: 1312px;
  margin: auto;
  @media (max-width: 1350px) {
    width: 100%;
  }
`

const GridStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;

  place-items: center;
  padding: 20px;

  @media (max-width: 1160px) {
    padding-inline: 60px;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    padding-inline: 60px;
  }
  @media (max-width: 678px) {
    padding-inline: 30px;
  }
  @media (max-width: 476px) {
    padding-inline: 0;
  }

  @media (max-width: 374px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`

const EmployeeInformationGrid: React.FC<ITeamImgProps> = ({ items }) => {
  return (
    <Wrapper>
      <GridStyle>
        {items.map((item, index) => (
          <TeamImgCard
            key={index}
            image={item.image}
            name={item.name}
            position={item.position}
          />
        ))}
      </GridStyle>
    </Wrapper>
  )
}

export { EmployeeInformationGrid }
