import styled from "styled-components"
import {
  Descripition,
  StyledGradientTitle,
  animationProperties,
} from "../../../utils"
import { TeamImgProps } from "../../atoms"
import { EmployeeInformationGrid } from "../EmployeeInformationGrid"
import { AosWrapper } from "../../molecules/AosWrapper"

const EmployeeList: Array<TeamImgProps> = [
  {
    name: "Bishal Bhattarai",
    image: "/assets/imgs/bishal-dai.png",
    position: "Nepal Branch Manager",
  },
  {
    name: "Prajwol KC",
    image: "/assets/imgs/prajwol-dai.png",
    position: "Team Manager / Software Engineer",
  },
  {
    name: "Sawal Timsina",
    image: "/assets/imgs/sawal-1.png",
    position: "Team Manager / Software Engineer",
  },
  {
    name: "Subha Shrestha",
    image: "/assets/imgs/subha-mam.png",
    position: "HR Manager",
  },
  {
    name: "Rageena Shrestha",
    image: "/assets/imgs/rageena-1.png",
    position: "Lead UI/UX Designer",
  },
]

const Wrapper = styled.div`
  padding: 100px 20px 100px 20px;
  background-color: ${(props) => props.theme.blackBackground};
  @media (max-width: 1350px) {
    padding: 100px 0px;
  }
  @media (max-width: 915px) {
    padding: 100px 0px;
  }
  @media (max-width: 641px) {
    padding: 50px 20px;
  }
  @media (max-width: 374px) {
    padding: 50px 20px;
  }
`
const Headline = styled.div`
  margin-bottom: 20px;
  ${(props) => props.theme.typography.sectionHeadline};
  color: ${(props) => props.theme.sameBase};
  white-space: break-spaces;
  text-align: center;
  @media (max-width: 641px) {
    white-space: normal;
    font-size: 1.375rem;
    margin-bottom: 20px;
  }
`
const DescripitionText = styled(Descripition)`
  margin-bottom: 50px;
  @media (max-width: 641px) {
    white-space: normal;
    margin-bottom: 20px;
  }
`

const EmployeeInformation = () => {
  return (
    <Wrapper id={"information"}>
      <AosWrapper>
        <Headline>
          <StyledGradientTitle> {"Leading "}</StyledGradientTitle>
          {"with Empathy & Compassion to create \n"}
          <StyledGradientTitle> {"productive work"}</StyledGradientTitle>
          {" environment "}
        </Headline>
        <DescripitionText>
          {"The more diverse our team, the more we can achieve"}
        </DescripitionText>
        <AosWrapper
          duration={animationProperties.duration + 0.2}
          delay={animationProperties.delay + 0.2}
          animateIn={"animate__fadeInUpBig animate__zoomIn"}
        >
          <EmployeeInformationGrid items={EmployeeList} />
        </AosWrapper>
      </AosWrapper>
    </Wrapper>
  )
}

export { EmployeeInformation }
