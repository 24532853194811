import React from "react"
import styled from "styled-components"
import { ExperienceContentComponent } from "../../molecules"
import { AosWrapper } from "../../molecules/AosWrapper"

const SectionWrapper = styled.div`
  width: 100%;
  background: #111111;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .dotted-line {
    width: 100%;
    padding-right: 300px;
    @media (max-width: 768px) {
      display: none;
    }
  }
`

const Headline = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 100px;
  color: #d6d6d6;

  @media (max-width: 768px) {
    padding-top: 30px;
  }
  p {
    ${(props) => props.theme.typography.sectionHeadline}
    max-width: 1000px;
    width: 100%;

    @media (max-width: 768px) {
      max-width: 335px;
      width: 100%;
      font-size: 1.375rem;
    }
  }
  .textLinearGradient {
    ${(props) => props.theme.typography.TextLinearGradient}
    font-size: 2rem;

    @media (max-width: 768px) {
      font-size: 1.375rem;
    }
  }
`

const ExperienceSuccessSection: React.FC = () => {
  const projectExperience = [
    {
      cardDate: "2023/04/24",
      cardTitle: "20 Entertaining Uses of ChatGPT You Never Knew Were Possible",
      cardPreviewText:
        "Lorem ipsum dolor sit amet consectetur. Lectus vel odio purus dui sit facilisis facilisi eget mauris. Arcu nunc netus fermentum at. Quisque nec pretium praesent commodo.",
    },
    {
      cardDate: "2023/04/24",
      cardTitle: "20 Entertaining Uses of ChatGPT You Never Knew Were Possible",
      cardPreviewText:
        "Lorem ipsum dolor sit amet consectetur. Lectus vel odio purus dui sit facilisis facilisi eget mauris. Arcu nunc netus fermentum at. Quisque nec pretium praesent commodo.",
    },
    {
      cardDate: "2023/04/24",
      cardTitle: "20 Entertaining Uses of ChatGPT You Never Knew Were Possible",
      cardPreviewText:
        "Lorem ipsum dolor sit amet consectetur. Lectus vel odio purus dui sit facilisis facilisi eget mauris. Arcu nunc netus fermentum at. Quisque nec pretium praesent commodo.",
    },
    {
      cardDate: "2023/04/24",
      cardTitle: "20 Entertaining Uses of ChatGPT You Never Knew Were Possible",
      cardPreviewText:
        "Lorem ipsum dolor sit amet consectetur. Lectus vel odio purus dui sit facilisis facilisi eget mauris. Arcu nunc netus fermentum at. Quisque nec pretium praesent commodo.",
    },
  ]

  return (
    <SectionWrapper>
      <AosWrapper>
        <Headline>
          <p>
            Sharing our
            <span className="textLinearGradient">
              {" "}
              success and experiences{" "}
            </span>
            with the world through our stories!
          </p>
        </Headline>
        <AosWrapper>
          <ExperienceContentComponent
            header="Latest Project Experiences"
            contentDescription="To create innovative and tech-friendly software solutions that meet the evolving needs of businesses and consumers by outstanding group of people to outstanding group of individuals"
            cardContents={projectExperience}
          />
        </AosWrapper>
        <img src="assets/imgs/pointed-line.svg" className="dotted-line" />
        <AosWrapper>
          <ExperienceContentComponent
            header="Trending Topics "
            contentDescription="To create innovative and tech-friendly software solutions that meet the evolving needs of businesses and consumers by outstanding group of people to outstanding group of individuals"
            cardContents={projectExperience}
          />
        </AosWrapper>
        <img src="assets/imgs/pointed-line.svg" className="dotted-line" />
        <AosWrapper>
          <ExperienceContentComponent
            header="Mobile Application"
            contentDescription="To create innovative and tech-friendly software solutions that meet the evolving needs of businesses and consumers by outstanding group of people to outstanding group of individuals"
            cardContents={projectExperience}
          />
        </AosWrapper>
      </AosWrapper>
    </SectionWrapper>
  )
}

export { ExperienceSuccessSection }
