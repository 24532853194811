import React from "react"
import styled from "styled-components"
import { animationProperties } from "../../../utils"
import { AosWrapper } from "../../molecules/AosWrapper"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

interface CardProps {
  icon: string
  lightIcon: string
  title: string
  description: string
  index: number
}

const OuterDiv = styled.div`
  position: relative;
  z-index: 2;

  .linear-gradient {
    position: absolute;
    width: 4px;
    height: 85px;
    top: calc(50% - 85px / 2 - 0.5px);
    border-radius: 100%;
    background: ${(props) => props.theme.highlightGradient};
    @media (max-width: 633px) {
      height: 50px;
      top: calc(50% - 50px / 2 - 0.5px);
    }
  }
  .odd {
    right: -3px !important;
  }
  .even {
    left: -3px;
  }
  .left {
    border-right: none; /* remove the right border */
    left: -73px;
    width: 210px;
    height: 220px;
    position: absolute;
    border: 10px dashed ${(props) => props.theme.cardBorderColor};
    top: 45%;
    @media (max-width: 768px) {
      left: -30px;
      height: 215px;
    }
    @media (max-width: 633px) {
      height: 410px;
      top: 47%;
    }
    @media (max-width: 394px) {
      display: none;
    }
  }
  .left::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border: 2px dashed transparent;
    z-index: -1;
    border-image: ${(props) => props.theme.serviceCardLeftLinearGradient};
  }
  .right {
    right: -73px;
    width: 146px;
    height: 220px;
    position: absolute;
    border: 10px dashed ${(props) => props.theme.cardBorderColor};
    top: 45%;
    border-left: none;
    @media (max-width: 768px) {
      right: -30px;
      height: 215px;
    }
    @media (max-width: 633px) {
      height: 410px;
      top: 47%;
    }
    @media (max-width: 394px) {
      display: none;
    }
  }
  .right::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border: 2px dashed transparent;
    z-index: -1;
    border-image: ${(props) => props.theme.serviceCardRightLinearGradient};
  }
  .card-title {
    padding-bottom: 10px;
  }
`

const Card = styled.div<{ isLightTheme: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 30px;
  gap: 50px;
  isolation: isolate;
  position: relative;
  box-shadow: ${(props) => props.theme.shadowedBox};
  ${(props) => props.isLightTheme && `border: 1px solid #ddd;`};
  z-index: 2;

  max-width: 741px;
  min-height: 138px;
  width: 100%;

  background: ${(props) => props.theme.historyCardGradient};
  border-radius: 30px;
  @media (max-width: 633px) {
    display: flex;
    flex-direction: column;
    max-width: 335px;
    width: 100%;
    gap: 30px;
    padding: 50px 30px;
  }
  @media (max-width: 374px) {
    padding: 40px 15px;
  }

  img {
    @media (max-width: 633px) {
      padding-top: 0px;
    }
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    max-width: 531px;
    min-height: 33px;

    ${(props) => props.theme.typography.cardTitle}
    /* identical to box height, or 33px */

    display: flex;
    align-items: center;

    /* White */

    color: ${(props) => props.theme.fontColor};
    @media (max-width: 768px) {
      font-size: 1rem;
    }
    @media (max-width: 633px) {
      font-size: 1rem;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
  p {
    max-width: 531px;
    min-height: 58px;

    ${(props) => props.theme.typography.fontColor}

    color: ${(props) => props.theme.textColor};
    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
    @media (max-width: 633px) {
      font-size: 0.875rem;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
`

const BusinessTechCardComponent: React.FC<CardProps> = ({
  icon,
  lightIcon,
  title,
  description,
  index,
}) => {
  const isDarkTheme = useDeviceTheme()

  const gradientPosition = (index) => {
    if (index % 2 != 0) {
      return <div className={"linear-gradient even"}></div>
    }
    return <div className={"linear-gradient odd"}></div>
  }
  const cardPosition = (index) => {
    if (index == 3) {
      return <div />
    }

    if (index % 2 != 0) {
      return <div className={"left"} />
    }

    return <div className={"right"} />
  }
  return (
    <OuterDiv style={{ position: "relative", display: "flex" }}>
      <AosWrapper
        duration={animationProperties.duration + 0.5}
        delay={animationProperties.delay + 0.5}
        animateIn={"animate__fadeInUpBig animate__zoomIn"}
      >
        <Card isLightTheme={!isDarkTheme}>
          <img src={isDarkTheme ? icon : lightIcon} />
          <CardContent>
            <h3 className="card-title">{title}</h3>
            <p>{description} </p>
          </CardContent>
        </Card>
        {cardPosition(index)}
        {gradientPosition(index)}
      </AosWrapper>
    </OuterDiv>
  )
}

export { BusinessTechCardComponent }
