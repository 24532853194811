import styled from "styled-components"
import { SoftWareDescriptionCard } from "../../atoms/SoftwareDescriptionCard"
import { ISoftwareSolutionCard } from "../../molecules/SoftwareSolutionCard"
import { AosWrapper } from "../../molecules/AosWrapper"
import { animationProperties } from "../../../utils"

interface ISoftwareDevGrid {
  items: Array<ISoftwareSolutionCard>
}

const Wrapper = styled.div`
  width: 1312px;
  margin: auto;
  @media (max-width: 1350px) {
    width: 100%;
  }
`

const GridStyle = styled.div`
  display: flex;
  flex-flow: row wrap;
  row-gap: 60px;
  column-gap: 220px;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 1350px) {
    gap: 0;
  }
`
const LineAndCardContainer = styled.div`
  @media (max-width: 1350px) {
    & :nth-child(4) {
      .horizontal-line {
        display: none;
      }
    }
  }
`
const HLWrapper = styled.div`
  display: none;
  @media (max-width: 1350px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const HorizontalLine = styled.div`
  @media (max-width: 1350px) {
    height: 150px;
    width: 6px;
    opacity: 0.5;
    background: ${(props) => props.theme.scrollBarGradient};
  }
`
const DotLine = styled.div`
  width: 6px;
  height: 100px;
  background: linear-gradient(180deg, transparent 50%, #252525 50%),
    linear-gradient(87.75deg, #0058ee 0%, rgba(54, 215, 183, 0.8) 100%);
  background-size: 100% 25px;
  opacity: 0.5;
`
const VerticleDotLine = styled.div`
  width: calc(50vw - 540px);
  height: 6px;
  top: 52px;
  position: absolute;
  opacity: 0.5;
  background: linear-gradient(87deg, transparent 50%, #252525 50%),
    linear-gradient(87.75deg, #0058ee 0%, rgba(54, 215, 183, 0.8) 100%);
  background-size: 33px 6px;
  z-index: 1;
  @media (max-width: 1350px) {
    display: none;
  }
`
const LeftVerticleDotLine = styled(VerticleDotLine)`
  right: 1190px;
`
const RightVerticleDotLine = styled(VerticleDotLine)`
  left: 1190px;
`

const Img = styled.img`
  width: 1060px;
  object-fit: contain;
  top: 52px;
  position: absolute;
  z-index: 1;
  @media (max-width: 1350px) {
    display: none;
  }
`

const SoftwareDevelopmentGrid: React.FC<ISoftwareDevGrid> = ({ items }) => {
  return (
    <Wrapper>
      <AosWrapper
        duration={animationProperties.duration + 0.5}
        delay={animationProperties.delay + 0.5}
        animateIn={"animate__fadeInUpBig animate__zoomIn"}
      >
        <HLWrapper>
          <DotLine />
        </HLWrapper>
        <GridStyle>
          <LeftVerticleDotLine />
          <RightVerticleDotLine />

          <Img src={"/assets/imgs/gradient-lines.png"} />
          {items.map((item, index) => (
            <LineAndCardContainer key={index}>
              <HLWrapper>
                <HorizontalLine className={"horizontal-line"} />
              </HLWrapper>
              <SoftWareDescriptionCard
                key={index}
                title={item.title}
                content={item.content}
                icon={item.icon}
                lightIcon={item.lightIcon}
              />
            </LineAndCardContainer>
          ))}
        </GridStyle>
        <HLWrapper>
          <DotLine />
        </HLWrapper>
      </AosWrapper>
    </Wrapper>
  )
}
export { SoftwareDevelopmentGrid }
