import styled from "styled-components"
import {
  Descripition,
  StyledGradientTitle,
  animationProperties,
} from "../../../utils"
import { ContactForm } from "../ContactForm"
import { AosWrapper } from "../../molecules/AosWrapper"

const Wrapper = styled.div`
  padding: 100px 20px 100px 20px;
  background-color: ${(props) => props.theme.blackBackground};
  @media (max-width: 1350px) {
    padding: 100px 0px;
  }
  @media (max-width: 915px) {
    padding: 100px 0px;
  }
  @media (max-width: 641px) {
    padding: 50px 20px;
  }
  @media (max-width: 374px) {
    padding: 50px 20px;
  }
`
const Headline = styled.div`
  margin-bottom: 20px;
  ${(props) => props.theme.typography.sectionHeadline};
  color: ${(props) => props.theme.sameBase};
  white-space: break-spaces;
  text-align: center;
  @media (max-width: 641px) {
    white-space: normal;
    font-size: 1.375rem;
    margin-bottom: 20px;
  }
`
const DescripitionText = styled(Descripition)`
  margin-bottom: 50px;
  @media (max-width: 641px) {
    white-space: normal;
    margin-bottom: 20px;
  }
`

const ContactFormSection = () => {
  return (
    <Wrapper id={"contact"}>
      <AosWrapper>
        <Headline>
          <StyledGradientTitle id="contact-us-form">
            {"Connect "}
          </StyledGradientTitle>
          {"with us effortlessly "}
        </Headline>
        <DescripitionText>
          {"-simply fill out our contact form and we'll take care of the rest!"}
        </DescripitionText>
        <AosWrapper
          duration={animationProperties.duration + 0.2}
          delay={animationProperties.delay + 0.2}
          animateIn={"animate__fadeInUpBig animate__zoomIn"}
        >
          <ContactForm />
        </AosWrapper>
      </AosWrapper>
    </Wrapper>
  )
}

export { ContactFormSection }
