import React from "react"
import styled from "styled-components"
import {
  Descripition,
  Headline,
  StyledGradientTitle,
  animationProperties,
} from "../../../utils"
import { AosWrapper } from "../../molecules/AosWrapper"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

const Wrapper = styled.div`
  padding: 100px 20px;
  background-color: ${(props) => props.theme.footerSectionColor};
  position: relative;
  @media (max-width: 641px) {
    padding: 50px 20px;
  }
`

const HeadlineText = styled(Headline)`
  margin-bottom: 20px;
  color: ${(props) => props.theme.sameBase};
  @media (max-width: 567px) {
    white-space: normal;
    font-size: 1.25rem;
  }
`
const StyledGradientTitleText = styled(StyledGradientTitle)`
  @media (max-width: 567px) {
    font-size: 1.25rem;
  }
`
const DescripitionText = styled(Descripition)`
  margin-bottom: 0;
`
const DottedLine = styled.div`
  width: calc(100% - 40px);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
  }
  @media (max-width: 1274px) {
    display: none;
  }
`

const ImageWrapper = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .company-history-img {
    object-fit: contain;
  }
  @media (max-width: 678px) {
    .company-history-img {
      width: 100%;
      height: min-content;
    }
  }
`

const CompanyHistory: React.FC = () => {
  const isDeviceTheme = useDeviceTheme()
  return (
    <Wrapper>
      <AosWrapper>
        <HeadlineText>
          {"From humble beginnings to "}
          <StyledGradientTitleText>
            {"industry leader "}
          </StyledGradientTitleText>
          {"- our\n"}
          <StyledGradientTitleText>
            {"company history "}
          </StyledGradientTitleText>
          {"is a story of passion And determination"}
        </HeadlineText>
        <DottedLine>
          <img src={"/assets/imgs/dot-box.png"} />
        </DottedLine>
        <DescripitionText>
          {
            "The evolution of our company over the years is a reflection of our adaptability and resilience.\nLet us take you through our journey of expansion from starting to now."
          }
        </DescripitionText>
        <AosWrapper
          duration={animationProperties.duration + 0.2}
          delay={animationProperties.delay + 0.2}
          animateIn={"animate__fadeInUpBig animate__zoomIn"}
        >
          <ImageWrapper>
            <img
              src={
                isDeviceTheme
                  ? "/assets/imgs/company-history.svg"
                  : "/assets/imgs/company-history-light.svg"
              }
              width={"600px"}
              height={"600px"}
              className={"company-history-img"}
            />
          </ImageWrapper>
        </AosWrapper>
      </AosWrapper>
    </Wrapper>
  )
}

export { CompanyHistory }
