import React, { ReactNode } from "react"
import styled from "styled-components"
import { Button } from "../../atoms"
import { AosWrapper } from "../../molecules/AosWrapper"
import { animationProperties } from "../../../utils"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

interface IBannerContent {
  header: string
  title: ReactNode | string
  content: string
}
const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: calc(100vh + 45px);
`
const Header = styled.div`
  ${(props) => props.theme.typography.cardTitle1};
  font-weight: 400;
  text-align: center;
  color: ${(props) => props.theme.base};
  @media (max-width: 876px) {
    font-size: 1rem;
  }
  @media (max-width: 641px) {
    font-size: 0.75rem;
  }
`

const Title = styled.div`
  ${(props) => props.theme.typography.heroHeadline};
  color: ${(props) => props.theme.base};
  white-space: break-spaces;
  text-align: center;

  @media (max-width: 876px) {
    font-size: 1.875rem;
    margin-top: 30px;
  }

  @media (max-width: 641px) {
    font-size: 1.5rem;
    font-weight: 600;
  }
`
const ButtonWrapper = styled.div`
  position: sticky;
  z-index: 2;
  @media (max-width: 641px) {
    button {
      width: 184px;
      height: 39px;
    }
    img {
      height: 13px;
      width: 13px;
    }
    span {
      font-size: 1rem;
    }
  }
`
const Content = styled.div`
  ${(props) => props.theme.typography.cardTitle1};
  color: ${(props) => props.theme.base};
  white-space: break-spaces;
  text-align: center;
  margin: 26px 0;
  @media (max-width: 876px) {
    margin: 30px 0;
    font-size: 1rem;
  }
  @media (max-width: 641px) {
    font-size: 0.75rem;
  }
`

const BannerContent: React.FC<IBannerContent> = ({
  header,
  title,
  content,
}) => {
  const isDarkTheme = useDeviceTheme()
  return (
    <ContentWrapper>
      <AosWrapper offset={0} animatePreScroll={true}>
        <Header>{header.toUpperCase()}</Header>
        <Title>{title}</Title>
        <Content>{content}</Content>
      </AosWrapper>
      <ButtonWrapper>
        <AosWrapper
          animatePreScroll={true}
          animateIn={"animate__fadeInUpBig"}
          duration={animationProperties.duration + 0.2}
          delay={animationProperties.delay + 0.2}
        >
          <Button
            buttonType={"primary"}
            icon={
              isDarkTheme ? (
                <img
                  src={"/assets/icons/linked-in.svg"}
                  width={27}
                  height={27}
                />
              ) : (
                <img
                  src={"/assets/icons/linkedin-light.svg"}
                  width={27}
                  height={27}
                />
              )
            }
            style={{ marginTop: "2px", marginBottom: "2px" }}
            onClick={() =>
              window.open("https://www.linkedin.com/company/readytowork-corp/")
            }
          >
            {"CONNECT WITH US"}
          </Button>
        </AosWrapper>
      </ButtonWrapper>
    </ContentWrapper>
  )
}

export { BannerContent }
