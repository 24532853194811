import useDeviceTheme from "@project/shared/src/useDeviceTheme"

export const Development = {
  "1": [
    {
      icon_name: "AWS",
      icon_link: "/assets/logos/aws.png",
      url: "https://aws.amazon.com/",
    },
    {
      icon_name: "React",
      icon_link: "/assets/logos/react.png",
      url: "https://react.dev/",
    },
    {
      icon_name: "GCP",
      icon_link: "/assets/logos/gcp.png",
      url: "https://cloud.google.com/",
    },
  ],
  "2": [
    {
      icon_name: "Python",
      icon_link: "/assets/logos/python.png",
      url: "https://www.python.org/",
    },
    {
      icon_name: "Terraform",
      icon_link: "/assets/logos/terra-form.png",
      url: "https://www.terraform.io/",
    },
    {
      icon_name: "Docker",
      icon_link: "/assets/logos/docker.png",
      url: "https://www.docker.com/",
    },
    {
      icon_name: "Flutter",
      icon_link: "/assets/logos/flutter.png",
      url: "https://flutter.dev/",
    },
  ],
  "3": [
    {
      icon_name: "Node JS",
      icon_link: "/assets/logos/node.png",
      url: "https://nodejs.org/en",
    },
    {
      icon_name: "Stripe",
      icon_link: "/assets/logos/stripe.png",
      url: "https://stripe.com/en-it",
    },

    {
      icon_name: "Figma",
      icon_link: "/assets/logos/figma.png",
      url: "https://www.figma.com/",
    },
    {
      icon_name: "Golang",
      icon_link: "/assets/logos/go.png",
      url: "https://go.dev/",
    },
  ],
  "4": [
    {
      icon_name: "kubernetes",
      icon_link: "/assets/logos/kubernets.png",
      url: "https://kubernetes.io/",
    },
    {
      icon_name: "Postman",
      icon_link: "/assets/logos/postman.png",
      url: "https://www.postman.com/",
    },
  ],
}

export const Communication = () => {
  const isDarkTheme = useDeviceTheme()
  return {
    "1": [
      {
        icon_name: "Google",
        icon_link: "/assets/logos/google.png",
        url: "https://www.google.com/",
      },
      {
        icon_name: "oVice",
        icon_link: isDarkTheme
          ? "/assets/logos/ovice.png"
          : "/assets/logos/ovice-light.svg",
        url: "https://www.ovice.com/",
      },
    ],
    "2": [
      {
        icon_name: "JIRA",
        icon_link: "/assets/logos/jira.png",
        url: "https://www.atlassian.com/software/jira",
      },
      {
        icon_name: "Medium",
        icon_link: isDarkTheme
          ? "/assets/logos/medium.png"
          : "/assets/logos/medium-light.svg",
        url: "https://medium.com/",
      },
      {
        icon_name: "Slack",
        icon_link: "/assets/logos/slack.png",
        url: "https://slack.com/",
      },
    ],
    "4": [
      {
        icon_name: "Zoho People",
        icon_link: "/assets/logos/people.png",
        url: "https://www.zoho.com/",
      },

      {
        icon_name: "Confluence",
        icon_link: "/assets/logos/confluence.png",
        url: "https://www.atlassian.com/software/confluence",
      },
    ],
  }
}
export const Design = {
  "1": [
    {
      icon_name: "Figma",
      icon_link: "/assets/logos/figma.png",
      url: "https://www.figma.com/",
    },
    {
      icon_name: "Sketch",
      icon_link: "/assets/logos/sketch.png",
      url: "https://www.sketch.com/",
    },
  ],
  "2": [
    {
      icon_name: "After Effects",
      icon_link: "/assets/logos/adobe-ae.png",
      url: "https://www.adobe.com/products/aftereffects.html",
    },
  ],
  "3": [
    {
      icon_name: "Photoshop",
      icon_link: "/assets/logos/adobe-ps.png",
      url: "https://www.adobe.com/products/photoshop.html",
    },
    {
      icon_name: "Illustrator",
      icon_link: "/assets/logos/adobe-ai.png",
      url: "https://www.adobe.com/products/illustrator.html",
    },
  ],
}

export const CommunicationMob = {
  "1": [
    {
      icon_name: "Google",
      icon_link: "/assets/logos/google.png",
      url: "https://www.google.com/",
    },
    {
      icon_name: "Slack",
      icon_link: "/assets/logos/slack.png",
      url: "https://slack.com/",
    },
  ],
  "2": [
    {
      icon_name: "JIRA",
      icon_link: "/assets/logos/jira.png",
      url: "https://www.atlassian.com/software/jira",
    },
    {
      icon_name: "oVice",
      icon_link: "/assets/logos/ovice.png",
      url: "https://www.ovice.com/",
    },
  ],
  "3": [
    {
      icon_name: "Confluence",
      icon_link: "/assets/logos/confluence.png",
      url: "https://www.atlassian.com/software/confluence",
    },
    {
      icon_name: "Zoho People",
      icon_link: "/assets/logos/people.png",
      url: "https://www.zoho.com/",
    },
  ],
  "4": [
    {
      icon_name: "Medium",
      icon_link: "/assets/logos/medium.png",
      url: "https://medium.com/",
    },
  ],
}

export const DevelopmentMob = {
  "1": [
    {
      icon_name: "kubernetes",
      icon_link: "/assets/logos/kubernets.png",
      url: "https://kubernetes.io/",
    },
    {
      icon_name: "Stripe",
      icon_link: "/assets/logos/stripe.png",
      url: "https://stripe.com/en-it",
    },
    {
      icon_name: "Postman",
      icon_link: "/assets/logos/postman.png",
      url: "https://www.postman.com/",
    },
  ],
  "2": [
    {
      icon_name: "React",
      icon_link: "/assets/logos/react.png",
      url: "https://react.dev/",
    },
    {
      icon_name: "Docker",
      icon_link: "/assets/logos/docker.png",
      url: "https://www.docker.com/",
    },
    {
      icon_name: "AWS",
      icon_link: "/assets/logos/aws.png",
      url: "https://aws.amazon.com/",
    },
  ],
  "3": [
    {
      icon_name: "Flutter",
      icon_link: "/assets/logos/flutter.png",
      url: "https://flutter.dev/",
    },
    {
      icon_name: "Node JS",
      icon_link: "/assets/logos/node.png",
      url: "https://nodejs.org/en",
    },
    {
      icon_name: "GCP",
      icon_link: "/assets/logos/gcp.png",
      url: "https://cloud.google.com/",
    },
  ],
  "4": [
    {
      icon_name: "Figma",
      icon_link: "/assets/logos/figma.png",
      url: "https://www.figma.com/",
    },
    {
      icon_name: "Golang",
      icon_link: "/assets/logos/go.png",
      url: "https://go.dev/",
    },
  ],
  "5": [
    {
      icon_name: "Python",
      icon_link: "/assets/logos/python.png",
      url: "https://www.python.org/",
    },
    {
      icon_name: "Terraform",
      icon_link: "/assets/logos/terra-form.png",
      url: "https://www.terraform.io/",
    },
  ],
}

export const DesignMob = {
  "1": [
    {
      icon_name: "Figma",
      icon_link: "/assets/logos/figma.png",
      url: "https://www.figma.com/",
    },
    {
      icon_name: "Sketch",
      icon_link: "/assets/logos/sketch.png",
      url: "https://www.sketch.com/",
    },
  ],
  "2": [
    {
      icon_name: "Illustrator",
      icon_link: "/assets/logos/adobe-ai.png",
      url: "https://www.adobe.com/products/aftereffects.html",
    },
  ],
  "3": [
    {
      icon_name: "Photoshop",
      icon_link: "/assets/logos/adobe-ps.png",
      url: "https://www.adobe.com/products/photoshop.html",
    },
    {
      icon_name: "After Effects",
      icon_link: "/assets/logos/adobe-ae.png",
      url: "https://www.adobe.com/products/illustrator.html",
    },
  ],
}
