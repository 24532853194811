import React from "react"
import styled from "styled-components"

interface IProps {
  timelineData: Array<any>
  activeIndex: number
  onSideLineClick: (value: number) => void
  ref?: any
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 52px;

  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 375px) {
    padding-right: 20px;
  }

  .line {
    width: 1px;
    height: 85px;
    display: flex;
    margin: auto;

    @media only screen and (max-width: 768px) {
      height: 85px;
    }
  }

  .active-timeline {
    border: 1px dashed linear-gradient(87.75deg, #0058ee 100%, #36d7b7 80%);
    background: linear-gradient(#0058ee 0%, #36d7b7 80%);
    transition: 0.3 ease;
  }

  .seen-timeline {
    border: 1px dashed ${(props) => props.theme.seenTimeline};
  }

  .unseen-timeline {
    border: 1px dashed ${(props) => props.theme.seenTimeline};
  }

  .circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }

  .active-circle {
    height: 20px;
    width: 20px;
    background: linear-gradient(
      87.75deg,
      #0058ee 0%,
      rgba(54, 215, 183, 0.8) 100%
    );
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    transition: 0.3 ease;
  }

  .seen-circle {
    /* stylelint-disable property-no-vendor-prefix */
    border-radius: 50px;
    border: 1px solid transparent;
    background: linear-gradient(
        to right bottom,
        #0058ee,
        rgba(54, 215, 183, 0.8)
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);

    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  .unseen-circle {
    border: 1px solid ${(props) => props.theme.seenTimeline};
    background: ${(props) => props.theme.unSeenCircle};
  }
`
const TimelineComponent: React.FC<IProps> = ({
  timelineData,
  activeIndex,
  onSideLineClick,
  ref,
}) => {
  return (
    <Wrapper ref={ref}>
      {timelineData?.map((_, index) => {
        return (
          <div key={index} onClick={() => onSideLineClick(index)}>
            <div
              className={
                activeIndex === index
                  ? "active-circle circle"
                  : activeIndex > index
                  ? "seen-circle circle"
                  : "unseen-circle circle"
              }
            />
            {index !== timelineData?.length - 1 ? (
              <div
                className={
                  activeIndex === index
                    ? "active-timeline line"
                    : activeIndex > index
                    ? "seen-timeline line"
                    : "unseen-timeline line"
                }
              />
            ) : null}
          </div>
        )
      })}
    </Wrapper>
  )
}

export { TimelineComponent }
