import styled from "styled-components"
import { Descripition, GradientText } from "../../../utils"
import { ISoftwareSolutionCard } from "../../molecules/SoftwareSolutionCard"
import { SoftwareDevelopmentGrid } from "../SoftWareDevelopmentGrid"
import { AosWrapper } from "../../molecules/AosWrapper"

const SolutionDevelopmentList: Array<ISoftwareSolutionCard> = [
  {
    title: "Uncovering user goals",
    icon: <img src={"/assets/icons/s-bluepage.svg"} />,
    lightIcon: <img src={"/assets/icons/s-bluepage-light.svg"} />,
    content:
      "We investigate the requirements\n of user along with their business\n and services. The collected\n  resources are empathized,\n organized and processed to derive\n client’s actual requirement.",
  },
  {
    title: "Scripting the behaviour",
    icon: <img src={"/assets/icons/s-code.svg"} />,
    lightIcon: <img src={"/assets/icons/s-code-light.svg"} />,
    content:
      "We have ample of well\n experienced developers who are\n dedicated to develop the solution\n with the latest tech & tools. We\n offer generous support system to\n keep customer in loop in every\n step.",
  },
  {
    title: "Deploying the product",
    icon: <img src={"/assets/icons/s-hill.svg"} />,
    lightIcon: <img src={"/assets/icons/s-hill-light.svg"} />,
    content: "We are proud to launch our\n software with precision.",
  },
  {
    title: "Visualizing the Product",
    icon: <img src={"/assets/icons/s-visual.svg"} />,
    lightIcon: <img src={"/assets/icons/s-visual-light.svg"} />,
    content:
      "With the help of tools like Figma, we\n focus to develop the User Interface\n and Experience of the software  that\n best matches the requirement of the\n consumer.",
  },
  {
    title: "Assessing the quality",
    icon: <img src={"/assets/icons/s-badge.svg"} />,
    lightIcon: <img src={"/assets/icons/s-badge-light.svg"} />,
    content:
      "We assure every thing you see on\n software are well tested and\n implemented to meet standard quality\n of software.",
  },
]

const Wrapper = styled.div`
  padding: 100px 20px;
  background-color: ${(props) => props.theme.footerSectionColor};
  @media (max-width: 1350px) {
    padding-bottom: 60px;
  }
  @media (max-width: 641px) {
    padding: 60px 20px;
  }
`

const Headline = styled.div`
  margin-bottom: 20px;
  ${(props) => props.theme.typography.sectionHeadline};
  color: ${(props) => props.theme.sameBase};
  white-space: break-spaces;
  text-align: center;
  @media (max-width: 641px) {
    font-size: 1.375rem;
  }
`
const StyledGradientTitle = styled(GradientText)`
  ${(props) => props.theme.typography.sectionHeadline};
  @media (max-width: 641px) {
    font-size: 1.375rem;
  }
`
const DescripitionText = styled(Descripition)`
  margin-bottom: 50px;
  @media (max-width: 1350px) {
    margin-bottom: 0;
  }
`

const SoftwareDevelopmentSection = () => {
  return (
    <Wrapper id={"culture"}>
      <AosWrapper>
        <Headline>
          {"Iterative journey of a"}
          <StyledGradientTitle> {"software "}</StyledGradientTitle>
          {"in RTW"}
        </Headline>
        <DescripitionText>
          {
            "RTW believes in iterative cycles  to ensure the product quality and meeting the\n user expectations."
          }
        </DescripitionText>
        <SoftwareDevelopmentGrid items={SolutionDevelopmentList} />
      </AosWrapper>
    </Wrapper>
  )
}

export { SoftwareDevelopmentSection }
