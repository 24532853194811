import React from "react"
import styled from "styled-components"

const Container = styled.div`
  img {
    width: 100%;
    height: auto;
    max-width: 350px;

    @media only screen and (max-width: 768px) {
      max-width: 295px;
      max-height: 390px;
      object-fit: contain;
    }

    @media only screen and (max-width: 500px) {
      max-width: 288px;
      height: 378px;
      object-fit: contain;
    }

    @media only screen and (max-width: 425px) {
      max-width: 288px;
      height: 355px;
      object-fit: contain;
    }
  }
`

interface IProps {
  imgageSrc: string
}

const UserImage: React.FC<IProps> = ({ imgageSrc }) => {
  return (
    <Container>
      <img src={imgageSrc || "/assets/imgs/ceo.png"} />
    </Container>
  )
}

export { UserImage }
