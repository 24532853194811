import React from "react"
import styled from "styled-components"

export interface ICompanyDetailCard {
  icon: React.ReactNode
  count: string
  title: string
}
const IconStyle = styled.div`
  height: 70px;
  width: 70px;

  svg {
    height: 100%;
    width: 100%;
    fill: ${(props) => props.theme.base};
  }
  @media (max-width: 576px) {
    height: 32px;
    width: 32px;
  }
`
const CardWrapper = styled.div`
  min-height: 234px;
  min-width: 234px;
  border: 2px solid ${(props) => props.theme.borderColor};
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.base};
  background-color: transparent;
  transition: background-color 1.5s;
  &:hover {
    border: 2px solid ${(props) => props.theme.borderHoverColor};
    border-radius: 25px;
    color: ${(props) => props.theme.hoverBlue};
    background-color: ${(props) => props.theme.base};
    svg {
      stroke: none;
      fill: ${(props) => props.theme.hoverBlue};
    }
  }
  @media (max-width: 576px) {
    min-height: 154px;
    min-width: 154px;
  }
`
const CountStyle = styled.div`
  ${(props) => props.theme.typography.headline4}
  @media (max-width: 576px) {
    font-size: 1.25rem;
  }
`
const TitleStyle = styled.div`
  ${(props) => props.theme.typography.navMobItemFont}
  @media (max-width: 576px) {
    font-size: 0.75rem;
  }
`

export const CompanyDetailCard: React.FC<ICompanyDetailCard> = ({
  icon,
  count,
  title,
}) => {
  return (
    <CardWrapper>
      <IconStyle>{icon}</IconStyle>
      <CountStyle>{count}</CountStyle>
      <TitleStyle>{title.toLocaleUpperCase()}</TitleStyle>
    </CardWrapper>
  )
}
