import styled from "styled-components"

const Container = styled.div`
  img {
    width: 100%;
    height: auto;
    max-width: 650px;
    @media only screen and (max-width: 876px) {
      width: 100%;
      max-height: 300px;
      object-fit: contain;
      margin-top: 70px;
      padding-inline: 10px;
    }
  }
`
interface IProps {
  imageSrc: string
}

const BannerImage: React.FC<IProps> = ({ imageSrc }) => {
  return (
    <Container>
      <img src={imageSrc} />
    </Container>
  )
}

export { BannerImage }
