import { antdTheme, teamNepal } from "@project/shared"
import "animate.css/animate.min.css"
import { ConfigProvider, theme as antTheme } from "antd"
import { AppProps } from "next/app"
import { QueryClient, QueryClientProvider } from "react-query"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import styled, { ThemeProvider } from "styled-components"
import { HeaderComponent } from "../components"
import { GlobalStyles } from "../utils"
import React, { useEffect, useState } from "react"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

const queryClient = new QueryClient({ defaultOptions: {} })

const MainWrapper = styled.div<any>`
  ${({ isContentLoaded }) =>
    isContentLoaded &&
    `height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      .animating-logo {
        @media (max-width: 650px) {
          width: 100%;
        }
      }
    `};
`

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [isDOMLoaded, setIsDOMLoaded] = useState(false)
  const isDarkTheme = useDeviceTheme()

  useEffect(() => {
    const handleLoad = () => {
      setIsDOMLoaded(true)
    }
    if (document.readyState === "complete") {
      setIsDOMLoaded(true)
    } else {
      window.addEventListener("load", handleLoad)
    }
    return () => {
      window.removeEventListener("load", handleLoad)
    }
  }, [])

  return (
    <MainWrapper isContentLoaded={!isDOMLoaded}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider
          theme={isDarkTheme ? teamNepal.darkTheme : teamNepal.lightTheme}
        >
          <ConfigProvider
            theme={{
              ...antdTheme,
              algorithm: isDarkTheme
                ? antTheme.darkAlgorithm
                : antTheme.defaultAlgorithm,
            }}
          >
            <GlobalStyles />
            {!isDOMLoaded ? (
              <img src="/assets/RTW-LOGO-BLUE.gif" className="animating-logo" />
            ) : (
              <>
                <HeaderComponent />
                <Component {...pageProps} />
              </>
            )}
          </ConfigProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </MainWrapper>
  )
}

export default MyApp
