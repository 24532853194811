import { IStoriesProps } from "../../molecules/SuccessTimelineComponent"

export const SUCCESS_STORIES_DATA: IStoriesProps[] = [
  {
    name: "Bishal Bhattarai",
    position: "Nepal Branch Manager",
    story: (
      <>
        <p className={"info"}>
          {
            "I began my exciting and of course challenging Journey with ReadyToWork as a Software Engineer during the initial stage of the company in 2020. Being the first member in a software team, I was conscious of the challenges and opportunities it can bring and was motivated to continue expanding my knowledge towards technical skills, leading teams alongside managerial abilities."
          }
        </p>
        <p className={"info second-parag"}>
          {
            "Today, I work as Branch Manager of the company where I continue to drive the company towards success with its mission, vision and values. We introduce an open and flexible environment and solutions to engage and improve technical performance of the team effectively. The company has been phenomenal for shaping my professional expertise and now hereby sit as a guardian of high leading productive teams in the company."
          }
        </p>
      </>
    ),
    imgSrc: "/assets/imgs/bishal.png",
  },
  {
    name: "Prajwol KC",
    position: "Team Manager / Software Engineer",
    story: (
      <>
        <p className={"info"}>
          {
            "As a technology evangelist I always wanted to a part of an organization that drives the digital value. Being a part of ReadyToWork, I am delighted to achieve that enriching experience. Here I have had an amazing journey with the opportunity to learn something new every single day., contributed significantly to the company improvement through their energetic drive and passion for their work."
          }
        </p>
        <p className={"info second-parag"}>
          {
            "Through every challenges and opportunities, I pursue to grow together with a view to be a better and stronger person and employee. I have always been given the opportunity to learn and work on a wide range of modern trending projects of implementing wide range of technology based systems in the organisation and this is driven with a boundless working culture."
          }
        </p>
      </>
    ),
    imgSrc: "/assets/imgs/prajwol.png",
  },
  {
    name: "Sawal Timisina",
    position: "Team Manager / Software Engineer",
    story: (
      <>
        <p className={"info"}>
          {
            "I am proud to have been associated with ReadyToWork, a company that has been my professional home for over two years now. From day one, I was welcomed into a team that was friendly, supportive, and always willing to lend a helping hand. As a full stack developer, I had the privilege of working with some of the best technical minds in the industry. ReadyToWork provided me with ample opportunities to learn new technologies, work on challenging projects, and grow both as a developer and a person."
          }
        </p>
        <p className={"info second-parag"}>
          {
            "As I progressed in my career, I was fortunate enough to be recognized for my leadership skills, and I was promoted to Assistant Team Manager in April 2022. This new role has been a challenging yet rewarding experience, and I owe it all to the support and guidance I received from my colleagues at RTW."
          }
        </p>
      </>
    ),
    imgSrc: "/assets/imgs/sawal.png",
  },
  {
    name: "Subha Shrestha",
    position: "HR Manager",
    story: (
      <>
        <p className={"info"}>
          {
            "As I reflect on my journey in this company over the past year as an HR Manager, I am grateful for the opportunities I’ve had to learn, contribute, and grow as a professional. It has been an incredible journey of challenges, opportunities, and growth."
          }
        </p>
        <p className={"info second-parag"}>
          {
            "Through my experiences at this company has been instrumental in my growth and development as an HR practitioner. I learned the importance of taking a strategic approach to HR and focusing on employee engagement and employee management as a key driver of organizational success. My growth and success have been able to reach new heights of personal and professional development, and I am truly thankful for the support and encouragement that the company provided along the way."
          }
        </p>
      </>
    ),
    imgSrc: "/assets/imgs/subha.png",
  },
  {
    name: "Rageena Shrestha",
    position: "Lead UI/UX Designer",
    story: (
      <>
        <p className={"info"}>
          {
            "As I stepped on a career as a UI/UX Designer, I always wanted to explore in diverse industries each presenting unique opportunities and challenges. Following the path, I was privileged enough to be associated as a part of RTW where I was provided enough opportunities to leverage my skills. Over the year, I have broadened my perspective, sharpened my underlying abilities and gained ample of experiences from RTW."
          }
        </p>
        <p className={"info second-parag"}>
          {
            "I am very thankful towards company and my team mates for making my journey in RTW progressive and full of amazing experiences. I look forward to grow together with RTW."
          }
        </p>
      </>
    ),
    imgSrc: "/assets/imgs/rageena.png",
  },
]
