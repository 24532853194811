import styled from "styled-components"
import { GradientText } from "../../../utils"
import { ISoftwareSolutionCard } from "../../molecules/SoftwareSolutionCard"
import { SoftwareSolutionGrid } from "../SoftwareSolutionGrid"
import { AosWrapper } from "../../molecules/AosWrapper"

const Wrapper = styled.div`
  padding: 100px 20px;
  background-color: ${(props) => props.theme.softwareSelectionColor};
  @media (max-width: 641px) {
    padding: 60px 20px;
  }
`

const Headline = styled.div`
  margin-bottom: 55px;
  ${(props) => props.theme.typography.sectionHeadline};
  color: ${(props) => props.theme.sameBase};
  white-space: break-spaces;
  text-align: center;
  @media (max-width: 641px) {
    font-size: 1.375rem;
    margin-bottom: 40px;
  }
`
const StyledGradientTitle = styled(GradientText)`
  ${(props) => props.theme.typography.sectionHeadline};
  @media (max-width: 641px) {
    font-size: 1.375rem;
  }
`
const SolutionList: Array<ISoftwareSolutionCard> = [
  {
    title: "Design Thinking",
    icon: <img src={"/assets/icons/solution1.svg"} />,
    lightIcon: <img src={"/assets/icons/solution1-light.svg"} />,
    content:
      "RTW extensively embraces the design thinking approach that prioritizes understanding the needs and perspectives of users to create innovative solutions that are user-centric, intuitive, and effective.",
  },
  {
    title: "Usable and standard code base",
    icon: <img src={"/assets/icons/solution2.svg"} />,
    lightIcon: <img src={"/assets/icons/solution2-light.svg"} />,
    content:
      "Establishing coding standards and best practices that all developers adhere to is must in RTW. We emphasize on maintaining code quality, facilitating collaboration among developers,  ensuring the scalability and maintainability of software projects. ",
  },
  {
    title: "Mandatory PR Review ",
    icon: <img src={"/assets/icons/solution3.svg"} />,
    lightIcon: <img src={"/assets/icons/solution3-light.svg"} />,
    content:
      "By religiously following PR review culture, RTW upholds code quality, fostering collaboration and learning, and mitigating risks in software development projects. ",
  },
  {
    title: "Continuous Testing and Integration",
    icon: <img src={"/assets/icons/solution4.svg"} />,
    lightIcon: <img src={"/assets/icons/solution4-light.svg"} />,
    content:
      "We practice Continuous Testing and Integration here in RTW. We believe Prioritizing frequent code integration & automated testing throughout the development process is key to maintaining service quality.",
  },
  {
    title: "Software Quality Assurance",
    icon: <img src={"/assets/icons/solution5.svg"} />,
    lightIcon: <img src={"/assets/icons/solution5-light.svg"} />,
    content:
      "Our aim is to deliver dependable, efficient, and secure services that exceed expectations. In RTW, we ensure that our products and processes adhere to established quality benchmarks and meet customer expectations.",
  },
  {
    title: "Software Risk Management",
    icon: <img src={"/assets/icons/solution6.svg"} />,
    lightIcon: <img src={"/assets/icons/solution6-light.svg"} />,
    content:
      "Our focus lies in identifying and addressing potential threats and uncertainties that could impact the success of software projects. We aim to minimize adverse effects, enhance project outcomes, and increase the likelihood of project success.",
  },
]

const SoftwareSolutionSection = () => {
  return (
    <AosWrapper>
      <Wrapper id={"culture"}>
        <Headline>
          {
            "Experience our industry-leading best practices for \n unparalleled "
          }
          <StyledGradientTitle> {"software solutions."}</StyledGradientTitle>
        </Headline>
        <SoftwareSolutionGrid items={SolutionList} />
      </Wrapper>
    </AosWrapper>
  )
}

export { SoftwareSolutionSection }
