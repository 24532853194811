import React, { useState } from "react"
import styled from "styled-components"
import { ToolTechCardComponent } from "../../atoms"
import { Grid } from "antd"
import {
  Communication,
  CommunicationMob,
  Development,
  DevelopmentMob,
  Design,
  DesignMob,
} from "./data"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { AosWrapper } from "../../molecules/AosWrapper"

const Wrapper = styled.div`
  width: 100%;
  background: ${(props) => props.theme.techToolsBackgroundColor};
`

const TechToolSection = styled.div`
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
  padding: 100px 0px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 60px 0px 100px 0;
  }
  .slick-slider {
    position: relative;
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
    @media (max-width: 768px) {
      padding: 0px;
    }
  }

  .slick-slide {
    width: 100%;
    padding: 20px;
    @media (max-width: 425px) {
      padding: 10px;
    }
  }

  .horizontal-indicator {
    display: flex;
    justify-content: center;
  }

  .indicator {
    width: 80px;
    height: 6px;
    background: ${(props) => props.theme.gray29};
    margin: 10px;
    border-radius: 5px;
    padding: 0px;
    @media (max-width: 768px) {
      width: 50px;
    }
    ::before {
      content: "";
    }
  }
  .slick-list .slick-track div .slick-active {
    @media (min-width: 475px) {
      width: 320px !important;
    }
    @media (min-width: 768px) {
      width: 400px !important;
    }
  }
  .slick-active .indicator {
    background: ${(props) => props.theme.indicatorGradient};
  }
  .slick-list {
    padding: 10px;
    @media (max-width: 375px) {
      padding: 6px;
    }
  }
  .slick-arrow {
    display: none !important;
    right: 0;
  }
  .slick-dots li {
    width: auto;
  }

  .slick-slide > div {
    display: grid;
    place-items: center;
    width: 100%;
    margin: auto;
    @media (max-width: 500px) {
      display: flex;
    }
  }

  .slick-center {
    transition: 1s all ease;
  }
`

const TechToolHeader = styled.div`
  text-align: center;
  width: 100%;

  p {
    ${(props) => props.theme.typography.sectionHeadline2}
    color: ${(props) => props.theme.detailsColor};
    @media only screen and (max-width: 768px) {
      font-size: 22px;
      padding: 0 20px;
    }
    .text-gradient {
      background: ${(props) => props.theme.indicatorGradient};
      /* stylelint-disable property-no-vendor-prefix */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-size: 32px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }

    br {
      @media (max-width: 475px) {
        display: none;
      }
    }
  }
`

const TechToolSectionComponent: React.FC = () => {
  let devData = Development,
    designData = Design,
    comData = Communication()
  const { useBreakpoint } = Grid
  const screenSize = useBreakpoint()
  if (screenSize.xs) {
    devData = DevelopmentMob
    designData = DesignMob
    comData = CommunicationMob
  }

  const items = [
    {
      id: 1,
      title: "Communication & skills",
      data: comData,
    },
    {
      id: 2,
      title: "Development ",
      data: devData,
    },
    {
      id: 3,
      title: "Design",
      data: designData,
    },
  ]

  const [currentSlide, setCurrentSlide] = useState(1)

  const handleSlideChange = (index) => {
    setCurrentSlide(index)
  }

  const settings = {
    swipeToSlide: true,
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    variableWidth: false,
    centerPadding: "400px",
    autoplay: true,
    initialSlide: 2,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 1,
          initialSlide: 2,
          dots: true,
          centerPadding: "400px",
          className: "center",
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          initialSlide: 2,
          dots: true,
          centerPadding: "300px",
          className: "center",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          initialSlide: 2,
          dots: true,
          centerPadding: "200px",
          className: "center",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          initialSlide: 2,
          centerPadding: "40px",
          dots: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          initialSlide: 2,
          centerPadding: "20px",
          dots: true,
          className: "center",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          initialSlide: 2,
          centerPadding: "10px",
          dots: true,
          className: "center",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          initialSlide: 2,
          centerPadding: "10px",
          dots: true,
          className: "center",
          centerMode: true,
        },
      },
    ],
    appendDots: (dots) => (
      <div className={"horizontal-indicator"}>
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <button
        key={i}
        className={`indicator ${i === currentSlide ? "active" : ""}`}
        onClick={() => handleSlideChange(i)}
      />
    ),
  }

  return (
    <Wrapper id={"tech-tool"}>
      <AosWrapper>
        <TechToolSection>
          <TechToolHeader>
            <p>
              {"Wide Range of "}
              <span className={"text-gradient"}> {" Tech & Tools "} </span>
              {" Supporting"} <br />
              {"Our Problem-Solving Skills"}
            </p>
          </TechToolHeader>
          <Slider {...settings}>
            {items.map((item) => (
              <ToolTechCardComponent
                key={item.id}
                keyId={item.id}
                title={item.title}
                iconDetails={item.data}
              />
            ))}
          </Slider>
        </TechToolSection>
      </AosWrapper>
    </Wrapper>
  )
}
export { TechToolSectionComponent }
