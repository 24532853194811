import React from "react"
import styled from "styled-components"
import {
  IOrganizationCard,
  OrganizationCard,
} from "../../molecules/OrganizationCard"
import { AosWrapper } from "../../molecules/AosWrapper"
import { animationProperties } from "../../../utils"

interface IOrganizationCultureGrid {
  items: Array<IOrganizationCard>
}

const Wrapper = styled.div`
  width: 1312px;
  margin: auto;
  @media (max-width: 1350px) {
    width: 100%;
  }
`

const GridStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 60px;
  column-gap: 20px;
  @media (max-width: 1350px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 915px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  @media (max-width: 374px) {
    gap: 20px;
  }
`

const OrganizationCultureGrid: React.FC<IOrganizationCultureGrid> = ({
  items,
}) => {
  return (
    <Wrapper>
      <AosWrapper
        animateIn={"animate__fadeInUpBig animate__zoomIn"}
        duration={animationProperties.duration + 0.2}
        delay={animationProperties.delay + 0.2}
      >
        <GridStyle>
          {items.map((item, index) => (
            <OrganizationCard
              key={index}
              title={item.title}
              content={item.content}
              icon={item.icon}
              lightIcon={item.lightIcon}
              footer={item.footer}
            />
          ))}
        </GridStyle>
      </AosWrapper>
    </Wrapper>
  )
}

export { OrganizationCultureGrid }
