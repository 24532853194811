import useDeviceTheme from "@project/shared/src/useDeviceTheme"
import React from "react"
import styled from "styled-components"

interface MapCardProps {
  country?: string
  description?: string
  location?: string
  location2?: string
  officeType?: string
  email?: string
}
const MapCardStyle = styled.div`
  padding: 50px;
  min-width: 473px;
  min-height: 473px;
  box-sizing: border-box;
  border-radius: 50px;
  background: ${(props) => props.theme.locationSectionColor};
  border: 1px solid ${(props) => props.theme.cardBorder};
  box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.25);
  opacity: 0.7;
  @media (max-width: 1115px) {
    width: 100%;
    scroll-snap-align: start;
  }
  @media (max-width: 576px) {
    min-width: 335px;
    min-height: 330px;
    border-radius: 25px;
    padding: 40px 28px 22px 28px;
    box-shadow: none;
  }
  @media (max-width: 370px) {
    min-width: 280px;
    min-height: 300px;
    padding: 20px 18px;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => props.theme.typography.heroHeadline2}
  color: ${(props) => props.theme.base};
  @media (max-width: 576px) {
    font-size: 20px;
    font-weight: 600;
  }
`

const Description = styled.div`
  ${(props) => props.theme.typography.descriptionMsg}
  color: ${(props) => props.theme.descriptionColor};
  white-space: break-spaces;
  margin-top: 20px;
  margin-bottom: 30px;
  height: 96px;
  @media (max-width: 576px) {
    height: auto;
    font-size: 12px;
  }
  @media (max-width: 370px) {
    white-space: normal;
  }
`

const SpecificDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-top: 30px;
  .location-div {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 576px) {
    gap: 10px;
    margin-top: 20px;
    img {
      width: 18px;
      height: 18px;
    }
  }
`

const DetailsText = styled.div`
  ${(props) => props.theme.typography.paragraph1}
  color: ${(props) => props.theme.detailsColor};
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: normal;
  }
`

const MapCard: React.FC<MapCardProps> = ({
  country,
  description,
  location,
  location2,
  officeType,
  email,
}) => {
  const isDarkTheme = useDeviceTheme()
  return (
    <MapCardStyle>
      <Title>{country.toUpperCase()}</Title>
      <Description>{description}</Description>
      <SpecificDetails>
        <img
          src={
            isDarkTheme
              ? "/assets/icons/office.svg"
              : "/assets/icons/office-light.svg"
          }
          width={"32px"}
          height={"32px"}
        />
        <DetailsText>{officeType}</DetailsText>
      </SpecificDetails>
      <SpecificDetails>
        <img
          src={
            isDarkTheme
              ? "/assets/icons/location.svg"
              : "/assets/icons/location-light.svg"
          }
          width={"32px"}
          height={"32px"}
        />
        <div className="location-div">
          <DetailsText>{location}</DetailsText>
          <DetailsText>{location2}</DetailsText>
        </div>
      </SpecificDetails>

      <SpecificDetails>
        <img
          src={
            isDarkTheme
              ? "/assets/icons/email.svg"
              : "/assets/icons/email-light.svg"
          }
          width={"32px"}
          height={"32px"}
        />
        <DetailsText>{email}</DetailsText>
      </SpecificDetails>
    </MapCardStyle>
  )
}

export { MapCard }
