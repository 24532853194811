import React from "react"
import styled from "styled-components"
import { Card } from "../Card"

interface IconData {
  icon_name: string
  icon_link: string
  url: string
}

interface TechIconProps {
  keyId: number
  title: string
  iconDetails: Record<string, IconData[]>
}

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 650px;
  height: 560px;

  @media (max-width: 768px) {
    max-width: 700px;
    height: 530px;
  }

  @media (max-width: 500px) {
    max-width: 700px;
    height: 580px;
  }

  @media (max-width: 425px) {
    max-width: 500px;
    width: 100%;
    height: 550px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background-color: ${(props) => props.theme.bgTool};
  width: 100%;
  @media (max-width: 425px) {
    gap: 30px;
  }

  p {
    ${(props) => props.theme.typography.cardTitle3}
    color:  ${(props) => props.theme.sameBase};
    @media (max-width: 768px) {
      font-size: 1.125rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 25px;
    width: 100%;
  }

  .tech-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }

  .tech {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: max-content;
    gap: 8px;
  }

  .tech > .icons > img {
    position: relative;
    object-fit: contain;
    width: 40px;
    height: 40px;
    @media (max-width: 500px) {
      width: 25px;
      height: 25px;
    }
  }

  .icon-name {
    ${(props) => props.theme.typography.cardTitle4};
    color: ${(props) => props.theme.sameBase};
    display: flex;
    align-items: center;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }
`

const ToolTechCardComponent: React.FC<TechIconProps> = ({
  keyId,
  title,
  iconDetails,
}) => {
  return (
    <StyledCard borderRadius={"50px"}>
      <Content>
        <p>{title}</p>
        <div className={"content"} key={keyId}>
          {Object.keys(iconDetails).map((value) => (
            <div className={"tech-row"} key={value}>
              {iconDetails[value].map((item) => (
                <a href={item?.url} target={"_blank"} key={item.icon_name}>
                  <div className={"tech"}>
                    <div className={"icons"}>
                      <img src={item.icon_link} alt={item.icon_name} />
                    </div>
                    <div className={"icon-name"}>{item.icon_name}</div>
                  </div>
                </a>
              ))}
            </div>
          ))}
        </div>
      </Content>
    </StyledCard>
  )
}

export { ToolTechCardComponent }
