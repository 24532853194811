import styled from "styled-components"
import { Descripition, Headline, StyledGradientTitle } from "../../../utils"
import { Button } from "../../atoms"
import { AosWrapper } from "../../molecules/AosWrapper"

const Wrapper = styled.div`
  padding: 100px 100px;
  background-color: ${(props) => props.theme.careerPhotosbackground};
  @media (max-width: 641px) {
    padding: 50px 20px;
  }
`
export const HeadlineText = styled(Headline)`
  margin-bottom: 20px;
  @media (max-width: 567px) {
    white-space: normal;
    font-size: 1.25rem;
  }
`
export const StyledGradientTitleText = styled(StyledGradientTitle)`
  @media (max-width: 567px) {
    font-size: 1.25rem;
  }
`
export const DescripitionText = styled(Descripition)`
  margin-bottom: 50px;
`

const LargeScreenImages = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }

  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 20px;

  .column {
    flex: 100%;
    max-width: 432px;
    padding-left: 20px;
    .lrg-scr-img {
      vertical-align: middle;
      width: 100%;
      filter: grayscale(100%);
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      &:hover {
        filter: grayscale(0);
      }
    }
  }
  #long-img {
    height: 100%;
  }
`
const SmallScreenImages = styled.div`
  @media only screen and (min-width: 768px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }

  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  place-items: center;
  margin-top: 10px;
  gap: 10px;

  .column {
    flex: 100%;
    max-width: auto;
    img {
      vertical-align: middle;
      width: 100%;
      filter: grayscale(100%);
      &:hover {
        filter: grayscale(0);
      }
    }
  }

  #long-img {
    height: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
`

const ViewButton = styled(Button)`
  width: 150px;
  @media (max-width: 600px) {
    width: 90vw;
    height: 38px;
  }
`

const RWTLife: React.FC = () => {
  return (
    <Wrapper>
      <AosWrapper>
        <HeadlineText>
          {"Life at Ready to "}
          <StyledGradientTitleText>{"Work "}</StyledGradientTitleText>
        </HeadlineText>
        <DescripitionText>
          {
            "RTW Happy hours : unwinding and bonding after work, socializing and making \nteam bond stronger"
          }
        </DescripitionText>

        <LargeScreenImages>
          <div className={"column"}>
            <img
              className={"lrg-scr-img"}
              src={"/assets/imgs/life-1.png"}
              id={"long-img"}
            />
          </div>
          <div className={"column"}>
            <img
              className={"lrg-scr-img"}
              src={"/assets/imgs/life-2.png"}
              id={"long-img"}
            />
          </div>
          <div className={"column"}>
            <img className={"lrg-scr-img"} src={"/assets/imgs/life-3.png"} />
            <img className={"lrg-scr-img"} src={"/assets/imgs/life-4.png"} />
          </div>
        </LargeScreenImages>
        <LargeScreenImages>
          <div className={"column"}>
            <img className={"lrg-scr-img"} src={"/assets/imgs/life-5.png"} />
            <img className={"lrg-scr-img"} src={"/assets/imgs/life-6.png"} />
          </div>
          <div className={"column"}>
            <img
              className={"lrg-scr-img"}
              src={"/assets/imgs/life-7.png"}
              id={"long-img"}
            />
          </div>
          <div className={"column"}>
            <img
              className={"lrg-scr-img"}
              src={"/assets/imgs/life-8.png"}
              id={"long-img"}
            />
          </div>
        </LargeScreenImages>

        <SmallScreenImages>
          <div className={"column"}>
            <img src={"/assets/imgs/life-1.png"} />
          </div>
          <div className={"column"}>
            <img src={"/assets/imgs/life-2.png"} />
          </div>
        </SmallScreenImages>
        <SmallScreenImages>
          <div className={"column"}>
            <img src={"/assets/imgs/life-3.png"} />
          </div>
        </SmallScreenImages>
        <SmallScreenImages>
          <div className={"column"}>
            <img src={"/assets/imgs/life-4.png"} />
          </div>
        </SmallScreenImages>

        <SmallScreenImages>
          <div className={"column"}>
            <img src={"/assets/imgs/life-7.png"} />
          </div>
          <div className={"column"}>
            <img src={"/assets/imgs/life-8.png"} />
          </div>
        </SmallScreenImages>
        <SmallScreenImages>
          <div className={"column"}>
            <img src={"/assets/imgs/life-5.png"} />
          </div>
        </SmallScreenImages>
        <SmallScreenImages>
          <div className={"column"}>
            <img src={"/assets/imgs/life-6.png"} />
          </div>
        </SmallScreenImages>
        <ButtonWrapper>
          <ViewButton
            buttonType={"border-gradient"}
            iconAlignment={"right"}
            onClick={() => null}
          >
            {"View More"}
          </ViewButton>
        </ButtonWrapper>
      </AosWrapper>
    </Wrapper>
  )
}

export { RWTLife }
