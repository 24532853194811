import React from "react"
import styled from "styled-components"
import { BusinessTechCardComponent } from "../../atoms"
import { AosWrapper } from "../../molecules/AosWrapper"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

const Wrapper = styled.div`
  background: ${(props) => props.theme.darkBackground};
`
const BusinessTechWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
  position: relative;

  @media (max-width: 633px) {
    padding: 60px 5px;
    gap: 60px;
  }
`
const Headline = styled.div`
  max-width: 1000px;
  min-height: 96px;
  ${(props) => props.theme.typography.sectionHeadline}
  text-align: center;
  color: ${(props) => props.theme.sameBase};
  width: 100%;
  @media (max-width: 768px) {
    font-size: 1.375rem;
    max-width: 335px;
  }

  .textGradient {
    ${(props) => props.theme.typography.TextLinearGradient}
    font-size: 2rem;
    @media (max-width: 768px) {
      font-size: 1.375rem;
    }
  }
`

const CardList = styled.div<{ isLightTheme: boolean }>`
  position: relative;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 375px) {
    padding: 0 20px 0 20px;
  }

  .gradient {
    width: 100%;
    height: 150%;
    position: absolute;

    background: ${(props) => props.theme.businessTechRadialColor};
    ${(props) =>
      props.isLightTheme &&
      `${props.theme.teamRadialColor};
      filter: blur(467px);
        opacity: 0.22;
      `};
    z-index: 1;
    top: -40%;
    @media (max-width: 768px) {
      display: none;
    }
  }
`

const BusinessTechSection: React.FC = () => {
  const isDarkTheme = useDeviceTheme()
  const businessDetails = [
    {
      icon: "assets/imgs/support.svg",
      lightIcon: "assets/imgs/support-light.svg",
      title: "IT support and maintenance",
      description:
        "RTW ensures to take care of clients in long term & support to monitor, maintain and troubleshoot technology infrastructure",
    },
    {
      icon: "assets/imgs/design-and-development.svg",
      lightIcon: "assets/imgs/design-and-development-light.svg",
      title: "SAAS Development",
      description:
        "We design, develop, and deliver software under the survellience of  highly skilled engineers and leaders to deliver the quality of software",
    },
    {
      icon: "assets/imgs/user-analytics.svg",
      lightIcon: "assets/imgs/user-analytics-light.svg",
      title: "IT consulting and Digital Advisory",
      description:
        "We well-attend clients to navigate the rapidly changing landscape of technology and innovation with our strong consulting advisory team",
    },
    {
      icon: "assets/imgs/conveyer.svg",
      lightIcon: "assets/imgs/conveyer-light.svg",
      title: "Monitoring Services",
      description:
        "We regularly monitor the performance of applications to ensure that they are operating as intended and users can access them without any issue",
    },
  ]
  return (
    <Wrapper>
      <AosWrapper>
        <BusinessTechWrapper>
          <Headline>
            {"Building secure and scalable software's that drives"}{" "}
            <span className={"textGradient"}>{"technology"}</span> {""}
            {"ahead with your"}{" "}
            <span className={"textGradient"}>{"businesses"}</span>
          </Headline>
          <CardList isLightTheme={!isDarkTheme}>
            {businessDetails.map((detail, index) => (
              <BusinessTechCardComponent
                title={detail.title}
                description={detail.description}
                icon={detail.icon}
                lightIcon={detail.lightIcon}
                index={index}
                key={index}
              />
            ))}
            <div className={"gradient"}></div>
          </CardList>
        </BusinessTechWrapper>
      </AosWrapper>
    </Wrapper>
  )
}

export { BusinessTechSection }
