import { useQuery } from "react-query"
import styled from "styled-components"
import { getActiveVacancyList } from "../../../services"
import { HiringCardList } from "../../molecules"
import { AosWrapper } from "../../molecules/AosWrapper"
import {
  DescripitionText,
  HeadlineText,
  StyledGradientTitleText,
} from "../LifetAtRtwSection"
import Fallback from "../../../public/assets/imgs/fallback.svg"
import { UserButton } from "@project/shared"
import { useRouter } from "next/router"

const Wrapper = styled.div`
  width: 100%;
  padding: 100px;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.applicationSectionBg};

  @media (max-width: 641px) {
    padding: 50px 20px;
  }
  .fallback {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 70vh;
    .main-text {
      font-weight: 700;
      font-size: 32px;
      display: flex;
      color: ${(props) => props.theme.opportunitiesTextColor};
      margin-top: 0.5em;
      text-align: center;
    }
    .sub-text {
      margin-top: 1em;
      color: ${(props) => props.theme.fontColor};
      width: 90vw;
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      white-space: break-spaces;
    }
    .submit-button {
      width: 210px;
      margin-top: 5em;
    }
  }
`

const HiringSection: React.FC = () => {
  const router = useRouter()
  const { data: vacancyData } = useQuery(
    ["active-vacancy-list"],
    getActiveVacancyList,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <Wrapper>
      <AosWrapper>
        {vacancyData?.length ? (
          <>
            {" "}
            <HeadlineText>
              {"We are "}
              <StyledGradientTitleText>{"hiring "}</StyledGradientTitleText>
              {": "}
              <StyledGradientTitleText>{"Join "}</StyledGradientTitleText>
              {"our dynamic team !"}
            </HeadlineText>
            <DescripitionText>
              {
                " RTW Happy hours : unwinding and bonding after work, socializing and\nmaking team bond stronger"
              }
            </DescripitionText>
            <HiringCardList vacancyData={vacancyData} />
          </>
        ) : (
          <div className="fallback">
            <Fallback />
            <span className="main-text">
              {"We're currently closed for job opportunities"}
            </span>
            <span className="sub-text">
              {
                "Sorry to deflate your hopes of joining our team as we don't have any job vacancy for\nyou right now.  Please drop your CV here and we will contact you as soon as we\nhave suitable position for you."
              }
            </span>

            <UserButton
              buttonType={"secondary"}
              className={"submit-button"}
              onClick={() => router.push("/contact-us#contact-us-form")}
            >
              {"Drop your CV"}
            </UserButton>
          </div>
        )}
      </AosWrapper>
    </Wrapper>
  )
}

export { HiringSection }
