import { TextField } from "@project/shared/src/components/atom"
import { UploadComponent } from "@project/shared/src/components/atom/Upload"
import { useFormik } from "formik"
import styled from "styled-components"
import { Descripition, Headline, StyledGradientTitle } from "../../../utils"
import { Button } from "../../atoms"

const Wrapper = styled.div`
  padding: 100px 20px;
  background-color: ${(props) => props.theme.bgSectorColor};
  @media (max-width: 641px) {
    padding: 50px 20px;
  }
`

const CardHeadlineText = styled(Headline)`
  margin-bottom: 20px;
  text-align: justify;
  @media (max-width: 567px) {
    white-space: normal;
    font-size: 1.375rem;
  }
`

const Headercard = styled.div`
  padding: 30px 40px;
  max-width: 983px;
  margin: auto;
  background: ${(props) => props.theme.quoteGradientBackground};
  border-radius: 36px;
`

const CardDescripitionText = styled(Descripition)`
  text-align: justify;
  margin-bottom: 0;
  @media (max-width: 567px) {
    white-space: normal;
    font-size: 0.875rem;
  }
`

const HeadlineText = styled(Headline)`
  margin: 50px 0 20px 0;

  @media (max-width: 567px) {
    white-space: normal;
    font-size: 1.25rem;
  }
`
const StyledGradientTitleText = styled(StyledGradientTitle)`
  @media (max-width: 567px) {
    font-size: 1.25rem;
  }
`
const DescripitionText = styled(Descripition)`
  margin-bottom: 0;
`

const FormCardWrapper = styled.div`
  margin: auto;
  max-width: 862px;
  min-height: auto;
  padding: 20px 50px 50px 50px;

  @media (max-width: 500px) {
    padding: 20px 20px;
  }
`

const InputFieldWrapper = styled.div`
  margin-top: 30px;

  & .text-area {
    width: 100%;
    color: ${(props) => props.theme.baseTextColor};
    .ant-input-affix-wrapper {
      background-color: transparent;
      color: ${(props) => props.theme.base};
      :hover {
        border: 1px solid ${(props) => props.theme.base};
      }
    }
    .ant-input {
      padding: 25px 15px 0px 15px;
      background-color: transparent;
      border-color: ${(props) => props.theme.searchBarBorderColor};
      color: ${(props) => props.theme.base};
      font-size: 1rem;
      border-radius: 5px;
      margin-bottom: 5px;
      @media (max-width: 567px) {
        font-size: 0.875rem;
      }
    }
  }
  & .text-field {
    width: 100%;
    color: ${(props) => props.theme.baseTextColor};
    font-size: 0.875rem;

    .ant-input-affix-wrapper {
      background-color: transparent;

      color: ${(props) => props.theme.base};
      :hover {
        border: 1px solid ${(props) => props.theme.base};
      }
    }
    .ant-input {
      background-color: transparent;
      color: ${(props) => props.theme.base};
      font-size: 0.875rem;
      height: 58px;
      border-radius: 5px;
      border-color: ${(props) => props.theme.searchBarBorderColor};
      @media (max-width: 567px) {
        height: 38px;
        padding: 12px;
        font-size: 0.875rem;
      }
    }
  }
`
const TextFieldWrapper = styled(TextField)`
  & > div > div {
    font-size: 0.875rem;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  justify-content: flex-end;
  gap: 20px;

  @media (max-width: 576px) {
    button {
      height: 38px;
    }
  }
`
const ButtonContainer = styled(Button)`
  width: 185px;
  @media (max-width: 576px) {
    & > span {
      font-size: 0.75rem;
    }
  }
`

const UploadComponentWrapper = styled(UploadComponent)`
  margin-top: 30px;
  color: white;
  & .label {
    font-size: 0.875rem;
  }
  .ant-upload-text {
    font-size: 1rem !important;
  }
  .ant-upload-hint {
    font-size: 0.875rem !important;
  }

  @media (max-width: 576px) {
    .ant-upload-text {
      font-size: 0.875rem !important;
    }

    .ant-upload-hint {
      font-size: 0.75rem !important;
    }
  }
`

const ApplicationSection: React.FC = () => {
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      //handleContactForm()
    },
  })
  return (
    <Wrapper>
      <Headercard>
        <CardHeadlineText>{"Software Engineer"}</CardHeadlineText>
        <CardDescripitionText>
          {
            "Lorem ipsum dolor sit amet consectetur. Sit porttitor at egestas adipiscing fringilla vivamus. Justo et \nmauris tortor integer augue enim fusce leo. Proin dictum condimentum amet nam. Risus risus \n ullamcorper donec aenean phasellus sem enim."
          }
        </CardDescripitionText>
      </Headercard>
      <HeadlineText>
        <StyledGradientTitleText>{"Connect "}</StyledGradientTitleText>
        {"with us effortlessly"}
      </HeadlineText>
      <DescripitionText>
        {"-simply fill out our contact form and we'll take care of the rest!"}
      </DescripitionText>
      <FormCardWrapper>
        <form>
          <InputFieldWrapper>
            <TextFieldWrapper
              label={"FULL NAME"}
              name={"username"}
              className={"text-field"}
            />
          </InputFieldWrapper>

          <InputFieldWrapper>
            <TextFieldWrapper
              label={"EMAIL ADDRESS"}
              name={"email"}
              className={"text-field"}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </InputFieldWrapper>

          <InputFieldWrapper>
            <TextFieldWrapper
              label={"PHONE NUMBER"}
              name={"number"}
              className={"text-field"}
            />
          </InputFieldWrapper>
          <UploadComponentWrapper
            labelText={"UPLOAD YOUR CV"}
            borderColor={"#5C5C5C"}
          />
          <InputFieldWrapper>
            <TextFieldWrapper
              label={"TELL US MORE ABOUT YOURSELF"}
              name={"inquiry"}
              type={"textarea"}
              className={"text-area"}
              rows={10}
              maxLength={500}
              showCounting={true}
            />
          </InputFieldWrapper>
          <ButtonWrapper>
            <ButtonContainer buttonType={"secondary"} onClick={() => null}>
              {"Cancel"}
            </ButtonContainer>
            <ButtonContainer buttonType={"default"} onClick={() => null}>
              {"Apply"}
            </ButtonContainer>
          </ButtonWrapper>
        </form>
      </FormCardWrapper>
    </Wrapper>
  )
}

export { ApplicationSection }
