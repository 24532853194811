import { API } from "@project/shared/src/api"
import { IQueryParams } from "../utils/interfaces"
import { VacancyList } from "../utils/interfaces/vacancy"

export const services = "services"

export const getActiveVacancyList = async (
  context
): Promise<Array<VacancyList>> => {
  const { page, page_size } = Object(context.queryKey[1]) as IQueryParams

  const { data } = await API.get(`/vacancy/active`, {
    params: {
      page,
      page_size,
    },
  })
  return data
}

export const createInquiry = async (data): Promise<any> => {
  API.post(`/contact`, data)
}
