import styled from "styled-components"

export const Container = styled.div`
  margin: auto;
  width: 100%;
  .section-header {
    margin-bottom: 37px;
    color: ${(props) => props.theme.textColor};
    font-weight: 600px;
    @media only screen and (max-width: 768px) {
      text-align: center;
    }
  }
  .final-section {
    height: 10px;
    background-color: #2b2b2b;
  }
`

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  padding: 62px 100px;
  background-color: ${(props) => props.theme.footerSectionColor};

  @media only screen and (max-width: 1130px) {
    padding: 62px 40px;
  }

  @media only screen and (max-width: 768px) {
    padding: 60px 20px;
    flex-direction: column;
    align-items: center;
  }

  .divider {
    background: ${(props) => props.theme.footerBorderColor};
    width: 100%;
    width: 1px;
    height: auto;
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 1px;
      margin: 40px 40px;
    }
  }

  .second-divider {
    @media only screen and (max-width: 760px) {
      display: none;
    }
  }

  .section-header {
    font-size: ${(props) => props.theme.typography.cardTitle};
    font-family: Montserrat, sans-serif;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
      font-size: ${(props) => props.theme.typography.paragraph2};
      font-family: Montserrat, sans-serif;
      font-weight: 600;
    }
  }
`

export const SectionCommonProperty = styled.div`
  font-size: ${(props) => props.theme.typography.cardTitleMob};
  color: ${(props) => props.theme.fontColor};
`

export const FirstSection = styled(SectionCommonProperty)`
  padding-right: 90px;
  .footer-quote {
    font-size: ${(props) => props.theme.typography.cardTitleMob};
    font-weight: 700;
    font-family: Open Sans, sans-serif;
    color: ${(props) => props.theme.base};
    margin-top: 30px;
    font-style: italic;
    text-align: center;
    display: none;
    @media only screen and (max-width: 768px) {
      display: inline-block;
      width: 100%;
    }
  }

  @media only screen and (max-width: 1130px) {
    padding-right: 20px;
  }

  @media only screen and (max-width: 1024px) {
    padding-right: 10px;
  }

  p {
    margin-top: 26px;
    max-width: 372px;
    @media only screen and (max-width: 768px) {
      max-width: 100%;
      text-align: center;
    }

    @media only screen and (max-width: 390px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  img {
    @media only screen and (max-width: 900px) {
      max-width: 200px;
    }

    @media only screen and (max-width: 768px) {
      max-width: 335px;
      margin: auto;
      display: flex;
    }

    @media only screen and (max-width: 422px) {
      max-width: 290px;
    }

    @media only screen and (max-width: 375px) {
      max-width: 250px;
      height: auto;
    }

    @media only screen and (max-width: 330px) {
      max-width: 230px;
      height: auto;
    }
  }
`

export const SecondSection = styled(SectionCommonProperty)`
  padding: 0 40px 0 40px;
  display: flex;
  flex-direction: column;
  max-width: 502px;

  @media only screen and (max-width: 1024px) {
    padding: 0 10px 0 10px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0px;
    width: 100%;
  }

  & .links-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
  }

  nav {
    flex-direction: row;
  }

  li {
    list-style: none;
    list-style-type: none;
    width: 100%;
    min-height: 50px;
    max-width: 160px;
    min-width: 160px;

    @media only screen and (max-width: 390px) {
      min-height: 30px;
      max-width: 120px;
      min-width: 120px;
    }
  }
  .right-align {
    @media only screen and (max-width: 768px) {
      text-align: end;
    }
  }
  a {
    text-decoration: underline;
    @media only screen and (max-width: 390px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
`

export const ThirdSection = styled(SectionCommonProperty)`
  padding: 0 0 0 40px;
  font-size: ${(props) => props.theme.typography.cardTitle};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 1024px) {
    padding: 0 10px 0 10px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }

  p {
    margin-bottom: 30px;
    color: ${(props) => props.theme.base};
  }

  .fb-icon {
    margin-right: 32px;

    @media only screen and (max-width: 768px) {
      margin-right: 12px;
    }
  }

  img {
    @media only screen and (max-width: 768px) {
      width: 20px;
    }
  }
  .follow-icons {
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`

export const BottomSection = styled.div`
  width: 100%;
  background: ${(props) => props.theme.grayDark};
  height: 67px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .divider {
    height: 32px;
    background: ${(props) => props.theme.footerBorderColor};
    width: 100%;
    width: 1px;
    margin: 0 30px;

    @media only screen and (max-width: 530px) {
      display: none;
    }
  }

  .bottom-text {
    ${(props) => props.theme.paragraph2};
    color: ${(props) => props.theme.grayLight};
  }

  a {
    text-decoration: underline;
    @media only screen and (max-width: 530px) {
      display: none;
    }
  }
  @media only screen and (max-width: 530px) {
    height: 59px;
    .ending-text {
      display: none;
    }
    .bottom-text {
      font-size: 12px;
    }
  }
`

export const JpxSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  gap: 30px;
  .text {
    ${(props) => props.theme.typography.descriptionMsg}
    font-family: Open Sans, sans-serif;
    color: ${(props) => props.theme.sameBase};
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
