import { Button as AntdButton, ButtonProps } from "antd"
import React from "react"
import styled from "styled-components"

interface IButton extends ButtonProps {
  buttonType?: "primary" | "border-gradient" | "default" | "secondary"
  iconAlignment?: "left" | "right"
  width?: string
}
const StyledButton = styled(AntdButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border: none;
  width: 226px;
  height: 49px;
  background-color: ${(props) => props.theme.btnSecondaryColor};
  flex-direction: ${(props: IButton) =>
    props.iconAlignment === "right" ? "row-reverse" : "row"};
  @media (max-width: 641px) {
    gap: 10px;
  }
  color: ${(props) => props.theme.buttonTextColor};
`
const StyledPrimaryLargeButton = styled(StyledButton)`
  background: ${(props) => props.theme.buttonColor} !important;
  color: ${(props) => props.theme.buttonTextColor} !important;
  padding: 16px 25px;
  width: 240px;
  height: 59px;
  &:hover,
  &:active {
    background-image: ${(props) => props.theme.hoveButtonGradient};
  }
`

const StyledSecondaryButton = styled(StyledButton)`
  width: ${(props: IButton) => (props.width ? props.width : "226px")};
  color: ${(props) => props.theme.base} !important;
  height: 49px;
  background: ${(props) => props.theme.highlightGradient};
`

export const UserButton: React.FC<IButton> = (props) => {
  const { buttonType, ...rest } = props
  switch (buttonType) {
    case "primary":
      return <StyledPrimaryLargeButton {...rest} />
    case "secondary":
      return <StyledSecondaryButton {...rest} />
    default:
      return <StyledButton {...rest} />
  }
}
