import {
  CaretDownFilled,
  DownOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import { Select } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

interface ISelect {
  width?: string
  height?: string
  className?: string
  admin?: boolean
  radius?: string
  onBlur?: any
  clear?: boolean
  defaultValue?: string | string[] | number | number[]
  options?: any[]
  value?: any
  onChange?: any
  suffix?: string
  dark?: boolean
  onFocus?: any
  placeholder?: string
  error?: any
  name?: any
  bgColor?: string
  required?: boolean
  label?: string
  loading?: boolean
  open?: boolean
  hide?: boolean
  native?: boolean
  shadow?: string
  max_width?: string
  showSearch?: boolean
  filterOption?: any
  onSearch?: any
  disabled?: any
}

const SelectWithStyle = styled(Select)`
  width: ${(props: ISelect) => {
    return props.width ? `${props.width} !important` : "auto"
  }};
  .ant-select-selector {
    border-radius: 5px;
    min-height: ${(props: ISelect) => {
      return props.height ? `${props.height} !important` : "40px"
    }};
    background-color: ${(props) => `${props.theme.cardColor} !important`};
    border: 1px solid ${(props) => props.theme.borderColor} !important;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    color: ${(props) => `${props.theme.base} !important`};
  }
`

const WrapperDiv = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? "none" : "flex")};
  flex-direction: column;
  .ant-select-selection-placeholder {
    color: ${(props) => `${props.theme.base} !important`};
  }
`

const Error = styled.div`
  color: ${(props) => props.theme.alert};
  font-size: 12px;
  margin-top: 2px;
`

export const Label = styled.div`
  margin-right: 10px;
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 1rem;
`

const RequiredLabel = styled.div`
  line-height: 20px;
  color: #f5222d;
  min-width: 40px;
  display: flex;
  justify-content: center;
  height: 22px;
  background: #fff1f0;
  border: 1px solid #ffa39e;
  border-radius: 2px;
`
const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 2px;
`

const StyledCaretDownFilled = styled(CaretDownFilled)`
  color: black;
  pointer-events: none;
`
const SelectComponent = (props: ISelect) => {
  const { Option } = Select
  const {
    options,
    clear,
    suffix,
    loading,
    admin,
    label,
    required,
    hide,
    onChange,
    name,
    dark,
    value,
    onSearch,
    disabled,
    ...rest
  } = props

  const { t } = useTranslation()

  return (
    <WrapperDiv hide={hide}>
      {label && (
        <LabelContainer>
          <Label>{label}</Label>
          {required && <RequiredLabel>{`${t("Required")}`}</RequiredLabel>}
        </LabelContainer>
      )}
      <SelectWithStyle
        allowClear={clear}
        admin={admin}
        suffixIcon={
          loading ? (
            <LoadingOutlined color="#000" />
          ) : suffix === "caretdown" ? (
            <StyledCaretDownFilled />
          ) : suffix === "down" ? (
            <DownOutlined />
          ) : null
        }
        onChange={onChange}
        name={name}
        dark={dark}
        value={value}
        onSearch={onSearch}
        showArrow
        disabled={disabled}
        {...rest}
        popupClassName="dropdown-style"
      >
        {options &&
          options?.map((option: any, index: number) =>
            typeof option == "object" ? (
              <Option
                key={index}
                value={option.value}
                disabled={option.disabled}
                // style={{ color: theme.cardColor }}
              >
                {option.name}
              </Option>
            ) : (
              <Option
                key={index}
                value={option}
                disabled={option.disabled}
                // style={{ color: theme.cardColor }}
              >
                {option}
              </Option>
            )
          )}
      </SelectWithStyle>
      {props.error && <Error>{props.error}</Error>}
    </WrapperDiv>
  )
}

export { SelectComponent }
