import { Switch } from "antd"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

interface ISwitchProps {
  label?: string
  showStatus?: boolean
  checkedText?: string
  unCheckedText?: string
  getSwitchValue?: (value: boolean) => void
  checkedValue?: boolean
}

const Container = styled.div`
  .label {
    font-weight: 500;
    font-size: 1rem;
    color: ${(props) => props.theme.baseTextColor};
    margin: 10px 0;
  }
  .ant-switch {
    background-color: ${(props) => props.theme.switchColor} !important;
  }
  .ant-switch.ant-switch-checked {
    background: linear-gradient(
      to right,
      ${(props) => props.theme.buttonGradientFirst},
      ${(props) => props.theme.buttonGradientSecond}
    ) !important;
  }
`

const SwitchComponent: React.FC<ISwitchProps> = ({
  label,
  showStatus,
  checkedText,
  unCheckedText,
  getSwitchValue,
  checkedValue = false,
}) => {
  const [checked, setChecked] = useState<boolean>(true)
  useEffect(() => {
    setChecked(checkedValue)
  }, [checkedValue])
  return (
    <Container>
      <p className="label">{label}</p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Switch
          checked={checked}
          onChange={(value) => {
            getSwitchValue && getSwitchValue(value)
            setChecked(value)
          }}
          style={{ marginRight: 10 }}
        />
        {showStatus && (
          <p className="label">{!checked ? unCheckedText : checkedText}</p>
        )}
      </div>
    </Container>
  )
}

export { SwitchComponent }
