import styled from "styled-components"
import { GradientText } from "../../../utils"
import { UniversalBannerContent } from "../UniversalBannerContent"

const StyledGradientTitle = styled(GradientText)`
  ${(props) => props.theme.typography.heroHeadline};
  @media (max-width: 876px) {
    font-size: 1.875px;
  }
  @media (max-width: 1024px) {
    font-size: 1.9rem;
  }
  @media (max-width: 641px) {
    font-size: 1.5rem;
    font-weight: 600;
  }
`

const Wrapper = styled.div`
  background: ${(props) => props.theme.coverGradient};
  background-color: ${(props) => props.theme.blue5};
  height: 100vh;
  width: 100%;
  vertical-align: middle;
  display: flex;
  justify-content: center;
`
interface ICommonBannerProps {
  textBeforegradient?: string
  textOnGradient?: string
  textAfterGradient?: string
  content?: string
  bannerImage?: string
  secondTextOnGradient?: string
  textAferSecondGradient?: string
}

const CommonBanner: React.FC<ICommonBannerProps> = ({
  textBeforegradient,
  textOnGradient,
  textAfterGradient,
  content,
  bannerImage,
  secondTextOnGradient,
  textAferSecondGradient,
}) => {
  return (
    <Wrapper id={"banner"}>
      <UniversalBannerContent
        title={
          <>
            <>{textBeforegradient} </>
            <StyledGradientTitle>{textOnGradient} </StyledGradientTitle>
            {textAfterGradient}
            <StyledGradientTitle>{secondTextOnGradient} </StyledGradientTitle>
            {textAferSecondGradient}
          </>
        }
        content={content}
        imagepath={bannerImage}
        iconpath={"/assets/icons/messenger.svg"}
      />
    </Wrapper>
  )
}

export { CommonBanner }
