import React from "react"
import styled from "styled-components"
import TechInfo from "./TechInfo"
import TechImages from "./TechImages"
import { AosWrapper } from "../../molecules/AosWrapper"

const Wrapper = styled.div`
  background: ${(props) => props.theme.techGradientDark};
`
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  max-width: 1500px;
  width: 100%;
  @media only screen and (max-width: 1375px) {
    display: flex;
    flex-direction: column;
    padding: 60px 0px 60px 0px;
  }
`

const TechCompany: React.FC = () => {
  return (
    <Wrapper id={"about-rtw"}>
      <AosWrapper>
        <Container>
          <TechInfo />
          <TechImages />
        </Container>
      </AosWrapper>
    </Wrapper>
  )
}

export { TechCompany }
