import React, { useRef } from "react"
import styled from "styled-components"
import { GradientText, animationProperties } from "../../../utils"
import { ArticleCardComponent } from "../../atoms"
import Slider from "react-slick"
import { AosWrapper } from "../../molecules/AosWrapper"

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.mainDark};
  padding: 100px 0;
  .slick-arrow {
    display: none !important;
    right: 0;
  }
  @media (max-width: 641px) {
    padding: 60px 0;
  }

  .slick-slide > div {
    padding-right: 20px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-top: 50px;
  img {
    cursor: pointer;
  }
  @media (max-width: 365px) {
    .next-previous {
      width: 20px;
      height: 20px;
    }
  }
`

const Headline = styled.div`
  margin-bottom: 60px;
  ${(props) => props.theme.typography.sectionHeadline2};
  color: ${(props) => props.theme.sameBase};
  white-space: break-spaces;
  text-align: center;
  @media (max-width: 641px) {
    font-size: 1.375rem;
    margin-bottom: 40px;
  }
`
const StyledGradientTitle = styled(GradientText)`
  ${(props) => props.theme.typography.sectionHeadline2};
  @media (max-width: 641px) {
    font-size: 1.375rem;
  }
`

const MediumList = [
  {
    articlephoto: "/assets/imgs/medium1.png",
    articletitle: "A Brief Introduction to Test-Driven Development(TDD)",
    readingtime: "9 min read",
    previewtext:
      "Test Driven Development (TDD) is a software development process in which the test cases are written before writing the code…",
    link: "https://medium.com/readytowork-org/a-brief-introduction-to-test-driven-development-tdd-71a22b5b2887",

    username: "Pratik Adhikari",
    releasedate: "Apr 10",
  },
  {
    articlephoto: "/assets/imgs/medium2.png",
    articletitle: "Build A Multiplayer Game In Go",
    readingtime: "14 min read",
    previewtext:
      "In this article, we will be building a paddle game that can be played by two players at once. Previously…",
    link: "https://medium.com/readytowork-org/build-a-multiplayer-game-in-go-5d07442fbde2",

    username: "Simant Thapa Magar",
    releasedate: "May 5",
  },
  {
    articlephoto: "/assets/imgs/medium3.png",
    articletitle: "Microservices in Gin Golang",
    readingtime: "6 min read",
    previewtext:
      "Before we start, this is one of the practice sessions. And you might need a computer with a basic Go-Gin application…",
    link: "https://medium.com/readytowork-org/microservice-in-gin-golang-91851930036a",

    username: "Sagar Shrestha",
    releasedate: "May 2",
  },
  {
    articlephoto: "/assets/imgs/medium4.png",
    articletitle: "Streamlining Your Website Deployment",
    readingtime: "5 min read",
    previewtext:
      "In today’s fast-paced digital world, having a website that loads quickly and reliably is crucial for businesses and individuals…",
    link: "https://medium.com/readytowork-org/streamlining-your-website-deployment-how-to-host-a-static-website-on-aws-s3-and-automate-ci-cd-84955a444104",

    username: "Kshitiz Shrestha",
    releasedate: "Apr 30",
  },
  {
    articlephoto: "/assets/imgs/medium5.png",
    articletitle: "GeoFencing in React Native",
    readingtime: "5 min read",
    previewtext:
      "Geo-fence is a location-based technology that creates a virtual boundary around a physical location or area, such as a store…",
    link: "https://medium.com/readytowork-org/geofencing-in-react-native-4d8cf42fe90c",

    username: "Dipesh Koirala",
    releasedate: "24 Mar",
  },
]

const MediumSection = () => {
  const sliderRef: any = useRef()

  const next = () => {
    sliderRef.current.slickNext()
  }
  const previous = () => {
    sliderRef.current.slickPrev()
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    adaptiveHeight: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: "linear",
    variableWidth: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
        },
      },
    ],
  }

  return (
    <Wrapper id={"blogs"}>
      <AosWrapper>
        <Headline>
          {"Sharing our "}
          <StyledGradientTitle>
            {" "}
            {"expertise and insights "}
          </StyledGradientTitle>
          {"with the world\n through our blog!"}
        </Headline>
        <AosWrapper
          animateIn={"animate__fadeInUpBig animate__zoomIn"}
          duration={animationProperties.duration + 0.2}
          delay={animationProperties.delay + 0.2}
        >
          <Slider ref={(slider) => (sliderRef.current = slider)} {...settings}>
            {MediumList.map((item, index) => (
              <a href={item.link} key={index} target="_blank">
                <ArticleCardComponent
                  articlephoto={item.articlephoto}
                  articletitle={item.articletitle}
                  readingtime={item.readingtime}
                  previewtext={item.previewtext}
                  username={item.username}
                  releasedate={item.releasedate}
                />
              </a>
            ))}
          </Slider>
          <ButtonWrapper>
            <img
              src={"/assets/icons/left.svg"}
              className={"next-previous"}
              onClick={previous}
            />
            <img
              src={"/assets/icons/right.svg"}
              className={"next-previous"}
              onClick={next}
            />
          </ButtonWrapper>
        </AosWrapper>
      </AosWrapper>
    </Wrapper>
  )
}

export { MediumSection }
