import React from "react"
import styled from "styled-components"

const ScalaGropLogos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const LargeScreenScalaLogos = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  margin: auto;

  @media only screen and (max-width: 1024px) {
    img {
      width: 100%;
      height: auto;
    }
  }
`

const FirstFlex = styled(LargeScreenScalaLogos)`
  margin-top: 80px;
  max-width: 977px;
`

const ThirdFlex = styled(LargeScreenScalaLogos)`
  margin-top: 50px;
  max-width: 977px;
`
const SecondFlex = styled(LargeScreenScalaLogos)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`

const ScalaLogoLarge = () => {
  const largeScreenImages: Array<{ img: string; link: string }> = [
    { img: "/assets/icons/scala6.svg", link: "https://scala-playce.jp/" },
    { img: "/assets/icons/scala5.svg", link: "https://scala-com.jp/" },
    { img: "/assets/icons/scalamain.svg", link: "https://scalagrp.jp/en/" },
    { img: "/assets/icons/scala3.svg", link: "https://scala-service.jp/" },
    { img: "/assets/icons/scala4.svg", link: "https://scala-partners.com/" },
  ]

  return (
    <ScalaGropLogos>
      <FirstFlex>
        {largeScreenImages?.slice(0, 2).map((logo, i) => {
          return (
            <a
              key={i}
              className={"flex-item"}
              href={logo.link}
              target={"_blank"}
            >
              <img src={logo.img} />
            </a>
          )
        })}
      </FirstFlex>
      <SecondFlex>
        {largeScreenImages?.slice(2, 3).map((logo, i) => {
          return (
            <a
              key={i}
              className={"flex-item"}
              href={logo.link}
              target={"_blank"}
            >
              <img src={logo.img} />
            </a>
          )
        })}
      </SecondFlex>
      <ThirdFlex className={"flex-item"}>
        {largeScreenImages?.slice(3, 5).map((logo, i) => {
          return (
            <a key={i} href={logo.link} target={"_blank"}>
              <img src={logo.img} />
            </a>
          )
        })}
      </ThirdFlex>
    </ScalaGropLogos>
  )
}

export default ScalaLogoLarge
