import React from "react"
import styled from "styled-components"
import { Headline } from "../../../utils"
import { AosWrapper } from "../../molecules/AosWrapper"

export interface IQuoteComponent {
  quote: string
  quoteMaster: string
}

const Wrapper = styled.div`
  padding: 100px 20px;
  background: ${(props) => props.theme.quoteGradientBackground};
  .quote-svg {
    position: absolute;
    width: 178px;
    height: 126px;
    object-fit: contain;
    @media (max-width: 786px) {
      width: 57px;
      height: 40px;
    }
  }
  .non-reverse-img {
    top: -80px;
    left: 4%;
    @media (max-width: 786px) {
      top: -19px;
      left: 0;
    }
  }
  .reverse-img {
    transform: rotate(180deg);
    bottom: -40px;
    right: 10%;
    @media (max-width: 786px) {
      bottom: -5px;
      right: 15%;
    }
  }
`
const Content = styled.div`
  position: relative;
  max-width: 1500px;
  margin: auto;
`
const Quote = styled(Headline)`
  color: ${(props) => props.theme.baseTextColor};
  font-size: 2.625rem;
  margin-bottom: 0;
  white-space: break-spaces;
  @media (max-width: 641px) {
    font-size: 1.375rem;
    margin-bottom: 10px;
  }
  @media (max-width: 374px) {
    font-size: 1.25rem;
  }
`

const QuoteMaster = styled.div`
  ${(props) => props.theme.typography.quoteWriter}
  color: ${(props) => props.theme.baseTextColor};
  text-align: center;
  @media (max-width: 641px) {
    font-size: 0.875rem;
  }
  @media (max-width: 374px) {
    font-size: 0.75rem;
  }
`

const QuoteComponent: React.FC<IQuoteComponent> = ({ quote, quoteMaster }) => {
  return (
    <Wrapper>
      <Content>
        <img
          src={"/assets/icons/quote.svg"}
          className={"quote-svg non-reverse-img"}
        />
        <AosWrapper>
          <Quote>{quote}</Quote>
          <QuoteMaster>{`- ${quoteMaster}`}</QuoteMaster>
        </AosWrapper>
        <img
          src={"/assets/icons/quote.svg"}
          className={"quote-svg reverse-img"}
        />
      </Content>
    </Wrapper>
  )
}

export { QuoteComponent }
