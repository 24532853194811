import React, { useState } from "react"
import styled from "styled-components"
import { Card } from "../Card/index"
import { useRouter } from "next/router"

interface VacancyProps {
  title: string
  position: string
  candidateNumber: string
}

const VacancyCard = styled(Card)`
  padding: 60px 80px;
  gap: 40px;

  max-width: 1042px;
  /* height: 227px; */
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90.58deg, #1c1c1c 8.93%, #0a0a0a 99.59%);
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #626262;
  margin-bottom: -25px;
  @media (max-width: 768px) {
    gap: 20px;
    max-width: 323px;
    flex-direction: column;
    padding: 25px 20px 50px;
    width: 100%;
  }
  @media (max-width: 360px) {
    max-width: 270px;
    width: 100%;
  }
`

const VacancyDetails = styled.div`
  width: 100%;
`

const Headline = styled.div`
  ${(props) => props.theme.typography.heroHeadline2}
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.baseTextColor};
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

const Positions = styled.div`
  ${(props) => props.theme.typography.cardTitle1}
  display: flex;
  white-space: pre;

  color: ${(props) => props.theme.fontColor};
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`
const CandidateNumber = styled.div`
  white-space: pre;
  ${(props) => props.theme.typography.cardTitle1}
  color: ${(props) => props.theme.fontColor};
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    max-width: 283px;
    height: 38px;
    width: 100%;
  }
`
const ApplyButton = styled.button`
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 32px;
  gap: 20px;
  max-width: 169px;
  height: 49px;
  width: 100%;

  border: 1px solid ${(props) => props.theme.baseTextColor};
  border-radius: 10px;
  background: #1f1e1e;
  @media (max-width: 768px) {
    max-width: 283px;
    height: 38px;
    width: 100%;
  }

  p {
    ${(props) => props.theme.typography.paragraph3}
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.baseTextColor};
    @media (max-width: 768px) {
      font-size: 0.75rem;
    }
  }
`

const HiringCard: React.FC<VacancyProps> = ({
  title,
  position,
  candidateNumber,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const router = useRouter()
  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  return (
    <VacancyCard
      gradientBorder={isHovered}
      gradientBorderLeft={"1px"}
      gradientBorderRight={"1px"}
      gradientBorderTop={"1px"}
      borderRadius={"25px"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <VacancyDetails>
        <Headline>{title}</Headline>
        <Positions>
          {"Position:"}{" "}
          <span>
            {""}
            {position}{" "}
          </span>
        </Positions>
        <CandidateNumber>
          {"No. of Candidates: "} {candidateNumber}
        </CandidateNumber>
      </VacancyDetails>
      <ButtonWrapper>
        <ApplyButton onClick={() => router.push("career/apply")}>
          <p>{"Apply"}</p>
          <span>
            <img src={"assets/icons/arrow.svg"}></img>
          </span>
        </ApplyButton>
      </ButtonWrapper>
    </VacancyCard>
  )
}

export { HiringCard }
