import React from "react"
import { animationProperties } from "../../../utils"
import { AnimationOnScroll } from "react-animation-on-scroll"

const AosWrapper = ({ children, ...props }) => {
  return (
    <AnimationOnScroll
      animateIn={"animate__fadeIn"}
      animateOut={"animate__fadeOut"}
      delay={animationProperties.delay}
      duration={animationProperties.duration}
      offset={animationProperties.offset}
      animatePreScroll={false}
      animateOnce={true}
      {...props}
    >
      {children}
    </AnimationOnScroll>
  )
}

export { AosWrapper }
