import React, { PropsWithChildren } from "react"
import styled from "styled-components"

const TextStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  .gradient-button {
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    background: linear-gradient(
      ${(props) => props.theme.buttonGradientFirst},
      ${(props) => props.theme.buttonGradientSecond}
    );
  }
  .value {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => props.theme.sameBase};
    margin-left: 10px;
    @media (max-width: 600px) {
      font-size: 0.825rem;
      white-space: break-spaces;
    }
  }
`

interface IDescriptivePointsProps {
  itemArray: {
    id: number
    value: string
  }[]
}

const DescriptivePoints: ({
  itemArray,
}: PropsWithChildren<IDescriptivePointsProps>) => any = ({ itemArray }) => {
  return itemArray?.map((item, index) => (
    <TextStyle key={index}>
      <div className="gradient-button" />
      <span className="value">{item.value}</span>
    </TextStyle>
  ))
}

export { DescriptivePoints }
