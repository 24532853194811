import React from "react"
import styled from "styled-components"
import { Descripition, Headline, StyledGradientTitle } from "../../../utils"
import { Button } from "../../atoms"
import { ArrowRightOutlined } from "@ant-design/icons"
import { AosWrapper } from "../../molecules/AosWrapper"
import { useRouter } from "next/router"

const Wrapper = styled.div`
  padding: 100px 20px;
  position: relative;
  background-color: ${(props) => props.theme.contactUsSectionColor};
  @media (max-width: 641px) {
    padding: 50px 20px;
  }
`

const HeadlineText = styled(Headline)`
  margin-bottom: 20px;
  @media (max-width: 567px) {
    white-space: normal;
    font-size: 1.25rem;
  }
`
const StyledGradientTitleText = styled(StyledGradientTitle)`
  @media (max-width: 567px) {
    font-size: 1.25rem;
  }
`
const DescripitionText = styled(Descripition)`
  margin-bottom: 0;
  @media (max-width: 374px) {
    font-size: 0.875rem;
    white-space: normal;
    margin-bottom: 40px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  @media (max-width: 576px) {
    button {
      height: 38px;
    }
  }
`

const ContactUsComponent = () => {
  const router = useRouter()
  return (
    <Wrapper>
      <AosWrapper>
        <HeadlineText>
          {" Don't hesitate to reach out to us -  "}
          <StyledGradientTitleText>
            {"we're here to help!"}
          </StyledGradientTitleText>
        </HeadlineText>

        <DescripitionText>
          {
            "To create innovative and tech-friendly software solutions that meet the evolving needs of businesses and\n consumers by outstanding group of people to outstanding group of individuals"
          }
        </DescripitionText>
        <ButtonWrapper>
          <Button
            buttonType={"secondary"}
            iconAlignment={"right"}
            icon={<ArrowRightOutlined />}
            onClick={() => router.push("/contact-us")}
          >
            {"CONTACT US"}
          </Button>
        </ButtonWrapper>
      </AosWrapper>
    </Wrapper>
  )
}

export { ContactUsComponent }
