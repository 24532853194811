import React from "react"
import styled from "styled-components"
import { NewsCardComponent } from "../../atoms"

interface CardContent {
  cardDate: string
  cardTitle: string
  cardPreviewText: string
}
interface ExperienceContentProps {
  header: string
  contentDescription: string
  cardContents: CardContent[]
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 100px 0px 0px 100px;
  gap: 20px;

  max-width: 100vw;
  width: 100%;
  background: #111111;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 10px 50px 10px;
  }
`
const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  height: 714px;
  @media (max-width: 768px) {
    justify-content: center;
    height: auto;
  }

  h3 {
    max-width: 436px;
    ${(props) => props.theme.typography.heroHeadline3}
    display: flex;
    align-items: center;
    width: 100%;

    color: ${(props) => props.theme.baseTextColor};
    @media (max-width: 768px) {
      justify-content: center;
      text-align: center;
      font-size: 1.375rem;
    }
  }

  p {
    max-width: 436px;
    width: 100%;
    ${(props) => props.theme.typography.paragraph2}

    color: #cbcbcb;
    @media (max-width: 768px) {
      text-align: center;
      font-size: 0.875rem;
    }
  }
`
// const ButtonWrapper = styled.div``
const DiscoverButtonDesk = styled.button`
  /* box-sizing: border-box; */
  display: none;

  /* Auto layout */
  /* 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 32px;
  gap: 20px;

  width: 226px;
  height: 49px; */

  /* White */
  /* 
  border: 1px solid ${(props) => props.theme.baseTextColor};
  border-radius: 10px;
  background: #111111; */
  @media (max-width: 768px) {
    display: none;
  }

  p {
    width: 104px;
    height: 19px;
    ${(props) => props.theme.typography.paragraph1}
    font-weight: 500;
    display: flex;
    align-items: center;

    /* White */

    color: ${(props) => props.theme.baseTextColor};
  }
`
const DiscoverButtonMob = styled(DiscoverButtonDesk)`
  width: 0px;
  display: none;

  @media (max-width: 768px) {
    display: none;
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 345px;
    height: 38px;
    width: 100%; */
  }
  p {
    @media (max-width: 768px) {
      font-size: 0.75rem;
      gap: 20px;
    }
  }
`
const CardList = styled.div`
  display: flex;
  overflow-x: scroll;
  gap: 40px;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    overflow-x: hidden;
    overflow-y: scroll;
    max-width: 354px;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    scroll-snap-type: y mandatory;

    ::-webkit-scrollbar {
      background: ${(props) => props.theme.scrollBarColor};
      width: 2px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background: ${(props) => props.theme.scrollBarColor};
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: ${(props) => props.theme.scrollBarGradient};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`
const ExperienceContentComponent: React.FC<ExperienceContentProps> = ({
  cardContents,
  header,
  contentDescription,
}) => {
  return (
    <Wrapper>
      <ContentHeader>
        <h3>{header}</h3>
        <p>{contentDescription}</p>
        <DiscoverButtonDesk>
          <p>Discover Now</p> <img src="assets/icons/arrow.svg" />
        </DiscoverButtonDesk>
      </ContentHeader>
      <CardList>
        {cardContents.map((content, index) => (
          <NewsCardComponent
            key={index}
            title={content.cardTitle}
            date={content.cardDate}
            previewText={content.cardPreviewText}
          />
        ))}
      </CardList>
      <DiscoverButtonMob>
        <p>Discover Now</p> <img src="assets/icons/arrow.svg" />
      </DiscoverButtonMob>
    </Wrapper>
  )
}

export { ExperienceContentComponent }
