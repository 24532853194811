import { ButtonProps, Button as AntdButton } from "antd"
import React from "react"
import styled from "styled-components"

interface IButton extends ButtonProps {
  buttonType?:
    | "primary"
    | "border-gradient"
    | "default"
    | "secondary"
    | "cancel"
  iconAlignment?: "left" | "right"
}
const StyledButton = styled(AntdButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border: none;
  width: 226px;
  height: 49px;
  background-color: ${(props) => props.theme.btnSecondaryColor};
  flex-direction: ${(props: IButton) =>
    props.iconAlignment === "right" ? "row-reverse" : "row"};
  @media (max-width: 641px) {
    gap: 10px;
  }
`
const StyledPrimaryLargeButton = styled(StyledButton)`
  background-image: ${(props) => props.theme.highlightGradient};
  padding: 16px 25px;
  width: 240px;
  height: 59px;
  &:hover,
  &:active {
    background-image: ${(props) => props.theme.hoveButtonGradient};
  }
`
const StyledDefaultButton = styled(StyledButton)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.borderColor};
  height: 44px;
`

const StyledSecondaryButton = styled(StyledButton)`
  width: 384px;
  height: 54px;
  background: linear-gradient(
    87.75deg,
    ${(props) => props.theme.buttonGradientFirst},
    ${(props) => props.theme.buttonGradientSecond}
  );
  border-image-source: ${(props) => props.theme.highlightGradient};
  color: ${(props) => props.theme.base} !important;
`
const StyledCancelButton = styled(StyledButton)`
  border: 1px solid ${(props) => props.theme.base};
  width: 226px;
  height: 49px;
  &:hover,
  &:active {
    border: 1px solid transparent;
    background: linear-gradient(
          ${(props) => props.theme.mainDark},
          ${(props) => props.theme.mainDark}
        )
        padding-box,
      ${(props) => props.theme.highlightGradient} border-box;
    border-image-source: ${(props) => props.theme.highlightGradient};
    color: ${(props) => props.theme.base};
  }
`

export const UserButton: React.FC<IButton> = (props) => {
  const { buttonType, ...rest } = props
  switch (buttonType) {
    case "primary":
      return <StyledPrimaryLargeButton {...rest} />
    case "secondary":
      return <StyledSecondaryButton {...rest} />
    case "cancel":
      return <StyledCancelButton {...rest} />
    default:
      return <StyledDefaultButton {...rest} />
  }
}
