import styled from "styled-components"

export const GradientText = styled.span`
  background: ${(props) => props.theme.highlightGradient};
  background-clip: text;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const Headline = styled.div`
  margin-bottom: 20px;
  ${(props) => props.theme.typography.sectionHeadline};
  color: ${(props) => props.theme.textColor};
  white-space: break-spaces;
  text-align: center;
  @media (max-width: 641px) {
    font-size: 1.375rem;
    margin-bottom: 40px;
  }
`
export const StyledGradientTitle = styled(GradientText)`
  ${(props) => props.theme.typography.sectionHeadline};
  @media (max-width: 641px) {
    font-size: 1.375rem;
  }
`

export const StyledGradientTitleInverse = styled.span`
  ${(props) => props.theme.typography.sectionHeadline};
  background: ${(props) => props.theme.indicatorGradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 876px) {
    font-size: 1.875rem;
  }
  @media (max-width: 641px) {
    font-size: 1.5rem;
    font-weight: 600;
  }
`

export const Descripition = styled.div`
  ${(props) => props.theme.typography.paragraph1}
  font-weight: 400;
  text-align: center;
  white-space: break-spaces;
  font-style: normal;
  color: ${(props) => props.theme.sameBase};
  margin-bottom: 40px;
  @media (max-width: 576px) {
    font-size: 0.875rem;
    white-space: normal;
    margin-bottom: 40px;
  }
  @media (max-width: 374px) {
    font-size: 0.625rem;
    margin-top: 10px;
  }
`
