import React, { ReactNode } from "react"
import styled from "styled-components"
import { BannerImage } from "../../atoms/BannerImage"
import { MessangerIcon } from "../../atoms/MessangerIconComponent"
import { AosWrapper } from "../../molecules/AosWrapper"

interface IUniversalBannerContent {
  title: ReactNode | string
  content: string
  imagepath: string
  iconpath: string
}

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh + 40px);
  padding: 0px 100px;
  width: 100%;
  @media (max-width: 1200px) {
    padding: 0px 40px;
  }
  @media (max-width: 625px) {
    flex-direction: column;
    padding: 0px;
  }
  @media (max-width: 425px) {
    flex-direction: column;
    padding: 0px;
  }
`

const ContentHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 876px) {
    align-items: center;
    padding-top: 10px;
  }
  @media (max-width: 625px) {
    padding: 10px;
  }
`

const Title = styled.div`
  ${(props) => props.theme.typography.heroHeadline};
  color: ${(props) => props.theme.sameBase};
  width: 632px;
  @media (max-width: 1024px) {
    font-size: 1.9rem;
    width: 442px;
    margin-top: 30px;
  }
  @media (max-width: 876px) {
    font-size: 1.625rem;
    max-width: 300px;
    text-align: center;
  }

  @media (max-width: 625px) {
    font-size: 24px;
    max-width: 300px;
    text-align: center;
    font-weight: 600;
  }
  @media (max-width: 425px) {
    font-size: 24px;
    max-width: 280px;
    text-align: center;
    font-weight: 600;
  }
`

const Content = styled.div`
  ${(props) => props.theme.typography.cardTitle1};
  font-size: 1.25rem;
  color: ${(props) => props.theme.fontColor};
  margin: 26px 0;
  width: 566px;
  @media (max-width: 1024px) {
    font-size: 1.125rem;
    max-width: 414px;
    margin-top: 30px;
  }
  @media (max-width: 876px) {
    text-align: center;
    margin: 0;
  }
  @media (max-width: 641px) {
    font-size: 12px;
    max-width: 280px;
    margin-top: 30px;
  }
  @media (max-width: 425px) {
    font-size: 12px;
    max-width: 280px;
    text-align: center;
  }
`

const UniversalBannerContent: React.FC<IUniversalBannerContent> = ({
  title,
  content,
  imagepath,
  iconpath,
}) => {
  return (
    <AosWrapper offset={0} animatePreScroll={true}>
      <ContentWrapper>
        <ContentHeaderWrapper>
          <Title> {title} </Title>
          <Content>{content}</Content>
        </ContentHeaderWrapper>
        <BannerImage imageSrc={imagepath} />
        <MessangerIcon imageSrc={iconpath} />
      </ContentWrapper>
    </AosWrapper>
  )
}

export { UniversalBannerContent }
