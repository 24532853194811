import React from "react"
import { CompanyDetailGrid } from "../CompanyDetailGrid"
import { ICompanyDetailCard } from "../../molecules/CompanyDetailCard"
import styled from "styled-components"
import ClientIcon from "./../../../public/assets/icons/client.svg"
import ProductIcon from "./../../../public/assets/icons/product.svg"
import EmployeeIcon from "./../../../public/assets/icons/company.svg"
import PublicationIcon from "./../../../public/assets/icons/publication.svg"
import { AosWrapper } from "../../molecules/AosWrapper"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

const CompanyDetailWrapper = styled.div<{ isDarkTheme: boolean }>`
  background: ${({ isDarkTheme }) =>
    isDarkTheme
      ? `url("/assets/imgs/group.svg") no-repeat center`
      : `url("/assets/imgs/group-light.svg") no-repeat center`};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  background-size: cover;
`

const CompanyDetailList: Array<ICompanyDetailCard> = [
  {
    title: "Clients",
    icon: <ClientIcon />,
    count: "100+",
  },
  {
    title: "Published Articles",
    icon: <PublicationIcon />,
    count: "200+",
  },
  {
    title: "Products",
    icon: <ProductIcon />,
    count: "211+",
  },
  {
    title: "Employees",
    icon: <EmployeeIcon />,
    count: "50+",
  },
]

const CompanyDetailSection: React.FC = () => {
  const isDeviceTheme = useDeviceTheme()
  return (
    <CompanyDetailWrapper id={"statistics"} isDarkTheme={isDeviceTheme}>
      <AosWrapper>
        <CompanyDetailGrid items={CompanyDetailList} />
      </AosWrapper>
    </CompanyDetailWrapper>
  )
}

export { CompanyDetailSection }
