import { Divider } from "antd"
import Link from "next/link"
import { useRouter } from "next/router"
import {
  BottomSection,
  Container,
  FirstSection,
  FooterWrapper,
  SecondSection,
  ThirdSection,
} from "./styles"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

const Footer = () => {
  const router = useRouter()
  const isDarkTheme = useDeviceTheme()
  return (
    <Container>
      <FooterWrapper>
        <FirstSection>
          <img
            src={
              isDarkTheme
                ? "/assets/imgs/footer-logo.svg"
                : "/assets/imgs/footer-logo-light.svg"
            }
            width={172}
            height={28}
            className={"footer-logo"}
            onClick={() => router.push("/")}
            style={{ cursor: "pointer" }}
          />
          <p className={"footer-quote"} style={{ textAlign: "center" }}>
            {` “ Established by international group of innovative entrepreneurs. “
        `}
          </p>
          <p>
            {
              "RTW is a  Japan based software company ready to challenge every problems with our technological proficiency. We promote co-creation, and support system development for a wide range of customers, from major companies to startups and local governments."
            }
          </p>
        </FirstSection>
        <Divider type={"vertical"} className={"divider"} />
        <SecondSection>
          <p className={"section-header"}>{"Quick Links"}</p>
          <div className={"links-container"}>
            <div>
              <nav>
                <ul>
                  <li>
                    <Link href={"/#home"}>
                      <a>{"Home"}</a>
                    </Link>
                  </li>
                  <li>
                    <Link href={"/#about-rtw"}>
                      <a>{"About Ready to work"}</a>
                    </Link>
                  </li>
                  <li>
                    <Link href={"/#our-expirence"}>
                      <a>{"Our Experiences"}</a>
                    </Link>
                  </li>
                  <li className={"right-align"}>
                    <Link href={"/#culture"}>
                      <a>{"Organization Culture"}</a>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div>
              <nav>
                <ul>
                  <li className={"right-align"}>
                    <Link href={"/#tech-tool"}>
                      <a>{"Tech & tools"}</a>
                    </Link>
                  </li>
                  <li className={"right-align"}>
                    <Link href={"/#success-stories"}>
                      <a>{"Success Stories"}</a>
                    </Link>
                  </li>
                  <li className={"right-align"}>
                    <Link href={"/#statistics"}>
                      <a>{"Statistics"}</a>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </SecondSection>
        <Divider type={"vertical"} className={"divider"} />
        <ThirdSection>
          <div className={"follow-icons"}>
            <p className={"section-header"}>{"Follow us"}</p>
            <a
              href={"https://www.facebook.com/readytowork.corp"}
              target={"_blank"}
            >
              <img
                src={
                  isDarkTheme
                    ? "/assets/icons/fb.svg"
                    : "/assets/icons/fb-light.svg"
                }
                width={28}
                height={28}
                className={"fb-icon"}
              />
            </a>
            <a
              href={
                "https://www.linkedin.com/company/readytowork-corp/mycompany/"
              }
              target={"_blank"}
            >
              <img
                src={
                  isDarkTheme
                    ? "/assets/icons/linkedin.svg"
                    : "/assets/icons/linkedin-light.svg"
                }
              />
            </a>
          </div>
          <div className={"social-wrapper"}>
            <p className={"section-header"}>{"Articles"}</p>
            <a href={"https://medium.com/readytowork-org"} target={"_blank"}>
              <img
                src={
                  isDarkTheme
                    ? "/assets/icons/article.svg"
                    : "/assets/logos/medium-light.svg"
                }
                width={28}
                height={28}
              />
            </a>
          </div>
        </ThirdSection>
      </FooterWrapper>
      <BottomSection>
        <p className={"bottom-text"}> {"Copyright © 2024 readytowork"}</p>
        <Divider type={"vertical"} className={"divider"} />
        <div className={"bottom-text ending-text"}>
          {"Crafted with Love from NEPAL ❤️  JAPAN "}
        </div>
      </BottomSection>
      <div className="final-section" />
    </Container>
  )
}

export { Footer }
