import React, { useState } from "react"
import styled from "styled-components"
import { Card } from "../../atoms"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

export interface ISoftwareSolutionCard {
  icon: React.ReactNode
  lightIcon?: React.ReactNode
  title: string
  content: string
}
const IconOuterStyle = styled.div`
  border-radius: 15px 15px 20px 20px;
  padding-top: 1px;
  background-image: ${(props) => props.theme.highlightGradient};
`
const IconStyle = styled.div`
  background: ${(props) => props.theme.iconBackgroundGradient};
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.solftwareSolutionBorderColor};
  padding: 10px;
  img {
    height: 74px;
    width: 74px;
    @media (max-width: 374px) {
      height: 55px;
      width: 55px;
    }
  }
`

const CardWrapper = styled(Card)`
  position: relative;
  padding: 10px 30px 10px 30px;
  min-height: 350px;
  max-width: 410px;
  width: 100%;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  color: ${(props) => props.theme.base};
  @media (max-width: 1350px) {
    max-width: 100%;
    width: 100%;
    padding: 30px;
  }
  @media (max-width: 951px) {
    min-height: 280px;
  }
  /* @media (max-width: 576px) {
    min-width: 100%;
    min-height: 402px;
  } */
  @media (max-width: 374px) {
    min-height: 300px;
  }
  &:hover {
    background-color: ${(props) => props.theme.highlightGradient};
    & > .icon-outer > .icon-inner {
      background: ${(props) => props.theme.highlightGradient};
    }
    & > .icon-outer {
      background: transparent;
    }
    & > .card-line {
      display: none;
    }
  }
`

const CardOuterDiv = styled.div`
  position: absolute;
  top: -1px;
  left: 0px;
  right: 0px;
  height: 20px;
  border-radius: 50px;
  background: ${(props) => props.theme.highlightGradient};
  z-index: -1;
`

const TextStyle = styled.div`
  ${(props) => props.theme.typography.paragraph2}
  font-size: 14px;
  min-height: 105px;
  font-weight: 400;
  margin-top: 16px;
  text-align: left;
  white-space: break-spaces;
  color: ${(props) => props.theme.fontColor};
  @media (max-width: 1350px) {
    min-height: 50px;
  }
  @media (max-width: 576px) {
    font-size: 0.875rem;
  }
  @media (max-width: 374px) {
    font-size: 0.625rem;
    margin-top: 15px;
  }
`
const TitleStyle = styled.div`
  ${(props) => props.theme.typography.cardTitle}
  margin-top:30px;
  text-align: left;
  font-size: 18px;
  white-space: break-spaces;
  color: ${(props) => props.theme.textColor};
  @media (max-width: 576px) {
    font-size: 1.125rem;
    /* margin-top: 40px; */
  }
  @media (max-width: 374px) {
    font-size: 0.75rem;
    /* margin-top: 30px; */
  }
`
const StyledCardWrapper = styled.div`
  border-radius: 56px 56px 56px 56px;
  padding-top: 0px;
  background-image: ${(props) => props.theme.highlightGradient};

  &:hover {
    border-radius: 50px 50px 56px 56px;
    padding-top: 1px;
  }
`

export const SoftWareSolutionCard: React.FC<ISoftwareSolutionCard> = ({
  icon,
  content,
  title,
  lightIcon,
}) => {
  const isDarkTheme = useDeviceTheme()
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleMouseEnter = () => setIsHovered(true)

  const handleMouseLeave = () => setIsHovered(false)
  return (
    <StyledCardWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardWrapper>
        <CardOuterDiv />
        {/* <CardLine className={"card-line"}></CardLine> */}
        <IconOuterStyle className={"icon-outer"}>
          <IconStyle className={"icon-inner"}>
            {isDarkTheme || isHovered ? icon : lightIcon}
          </IconStyle>
        </IconOuterStyle>
        <TitleStyle>{title}</TitleStyle>
        <TextStyle>{content}</TextStyle>
      </CardWrapper>
    </StyledCardWrapper>
  )
}
