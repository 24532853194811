import React from "react"
import styled from "styled-components"
import { UserImage } from "../../atoms"

export interface IStoriesProps {
  name: string
  position: string
  story: React.ReactElement
  index?: number
  imgSrc?: string
  refChild?: any
  id?: string
  className?: any
}

const Wrapper = styled.div<{ isLastScrollItem: boolean; isFirstItem: boolean }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
  scroll-snap-align: center;
  height: 680px;

  @media only screen and (max-width: 1024px) {
    height: 620px;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    min-height: 955px;
    align-items: flex-start;
    justify-content: flex-start;
    scroll-snap-align: start;
  }

  h3,
  h4 {
    color: ${(props) => props.theme.sameBase};
  }

  h3 {
    font-family: Lato, sans-serif;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 29px;
    margin-bottom: 20px;

    @media only screen and (max-width: 500px) {
      font-size: 1.125rem;
      line-height: 22px;
      font-weight: 700;
    }
  }

  h4 {
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 26px;
    margin-bottom: 20px;

    @media only screen and (max-width: 500px) {
      font-size: 0.875rem;
      line-height: 16px;
    }
  }

  .info {
    ${(props) => props.theme.paragraphInfo}
    color: ${(props) => props?.theme?.fontColor};
    max-width: 635px;
    padding-right: 55px;
    text-align: justify;

    @media only screen and (max-width: 500px) {
      font-size: 0.75rem;
      line-height: 18px;
      text-align: left;
    }

    @media only screen and (max-width: 768px) {
      padding-right: 0px;
      padding-bottom: 30px;
    }
  }
  .second-parag {
    margin-top: 10px;
    @media only screen and (max-width: 768px) {
      margin-top: -25px;
    }
  }
`

const MainTitle = styled.p`
  ${(props) => props.theme.typography.sectionHeadline};
  line-height: 48px;
  color: ${(props) => props.theme.sameBase};
  span {
    font-size: 32px;
    background: linear-gradient(87.75deg, #0058ee 100%, #36d7b7 80%);
    background-clip: text;
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  margin-bottom: 70px;

  @media (max-width: 769px) {
    display: none;
  }
`

const SuccessTimelineComponent: React.FC<IStoriesProps> = ({
  name,
  position,
  story,
  index,
  imgSrc,
  refChild,
  id,
  className,
}) => {
  return (
    <Wrapper
      isLastScrollItem={index === 5 ? true : false}
      isFirstItem={index === 0 ? true : false}
      ref={refChild}
      id={id}
      className={className}
    >
      <div className={"info-section"}>
        <MainTitle>
          {"Success stories in "} <br />
          <span>{"Ready to Work"}</span>
        </MainTitle>
        <h3>{name}</h3>
        <h4>{position}</h4>
        {story}
      </div>
      <UserImage imgageSrc={imgSrc} />
    </Wrapper>
  )
}

export { SuccessTimelineComponent }
