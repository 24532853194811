import React from "react"
import styled from "styled-components"

interface CompanyHistoryTimelineProps {
  date: string
  title: string
  details: string
}

const CompanyHistoryCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0px;
  max-width: 859px;
  max-height: 415px;
  background: ${(props) => props.theme.historyCardGradient};
  box-shadow: ${(props) => props.theme.shadowedBox};
  border-radius: 40px;
  height: 100%;
  z-index: 11;

  @media (max-width: 768px) {
    padding: 25px 0px;
    max-width: 600px;
  }

  @media (max-width: 426px) {
    max-width: 300px;
  }

  .title {
    ${(props) => props.theme.typography.HistoryCardTitle}
    padding-top: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${(props) => props.theme.sameBase};
    height: 100%;

    @media (max-width: 426px) {
      font-size: 1rem;
    }
  }

  .description {
    padding-top: 20px;
    padding-left: 77px;
    padding-right: 77px;
    ${(props) => props.theme.typography.paragraphInfo}
    text-align: center;
    color: ${(props) => props.theme.conpanyHistorySection};
    height: 100%;

    @media (max-width: 426px) {
      font-size: 0.875rem;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`
const DateCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 32px;
  gap: 21px;

  max-width: 215px;
  max-height: 69px;

  background: ${(props) => props.theme.cardDateBackground};
  border-left: 1px solid #717070;
  border-radius: 41px;

  @media (max-width: 426px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 32px;
    gap: 14px;
    width: 141.8px;
    height: 48px;
    background: ${(props) => props.theme.cardDateBackground};
    border-left: 1px solid #717070;
    border-radius: 41px;
  }

  img {
    @media (max-width: 426px) {
      width: 14px;
      height: 14px;
    }
  }

  p {
    ${(props) => props.theme.typography.heroHeadline2}
    text-align: center;
    letter-spacing: 0.1em;
    color: ${(props) => props.theme.sameBase};
    @media (max-width: 426px) {
      font-size: 1rem;
      text-align: center;
      letter-spacing: 0.1em;
    }
  }
`

const CompanyHistoryTimeline: React.FC<CompanyHistoryTimelineProps> = ({
  date,
  title,
  details,
}) => {
  return (
    <CompanyHistoryCard>
      <DateCard>
        <img src={"/assets/icons/date-rectaangle.svg"} />
        <p>{date}</p>
      </DateCard>
      <p className={"title"}>{title}</p>
      <p className={"description"}>{details}</p>
    </CompanyHistoryCard>
  )
}

export { CompanyHistoryTimeline }
