import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

let CKeditorG = null

const Wrapper = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: column;
  .label {
    font-size: 1em;
    margin: 20px 0 10px;
    font-weight: 500;
  }

  .ck-content {
    min-height: ${({ content_min_height }) =>
      content_min_height ? content_min_height : "300px"};
  }
  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
  .ck.ck-editor__main > .ck-editor__editable.ck-focused {
    background-color: ${(props) => props.theme.cardColor} !important;
    border-radius: 5px 5px 0 0;
    padding: 10px 25px;
  }

  .ck-toolbar {
    background: ${(props) => props.theme.cardBackground} !important;
    border-top: 1px solid ${(props) => props.theme.borderColor} !important;
  }

  .ck-rounded-corners .ck.ck-toolbar {
    border-radius: 0 0 5px 5px;
  }

  .ck-dropdown__button {
    background-color: ${(props) => props.theme.cardBackground} !important;
    .ck-button__label {
      color: ${(props) => props.theme.baseTextColor} !important;
    }
    svg {
      color: ${(props) => props.theme.baseTextColor} !important;
    }
  }

  .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-on:active:focus,
  .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-off:active:focus,
  .ck.ck-button:active,
  .ck.ck-button:focus {
    outline: none;
    box-shadow: none;
  }

  .ck-dropdown__panel,
  .ck.ck-dropdown .ck.ck-list {
    background-color: ${(props) => props.theme.cardColor} !important;
    .ck.ck-list__item .ck-button.ck-on {
      background-color: ${(props) => props.theme.baseTextColor};
      color: ${(props) => props.theme.cardColor};
    }
  }

  .ck.ck-button.ck-on:not(.ck-disabled):hover {
    background-color: none;
  }

  button.ck.ck-button.ck-on {
    svg {
      color: ${(props) => props.theme.textColorOnDark};
    }
  }
  button.ck.ck-button:hover {
    svg {
      color: ${(props) => props.theme.textColorOnDark};
    }
    color: ${(props) => props.theme.textColorOnDark};
  }

  .ck-editor__main {
    display: flex !important;
    flex-direction: column-reverse !important;
    border: 1px solid ${(props) => props.theme.borderColor} !important;
    border-radius: 5px;
  }
  .ck-toolbar__separator {
    margin: 0px auto;
  }
  .ck-dropdown {
    margin: auto;
    border: none;
  }

  .ck-button {
    margin: auto;
  }

  .ck-file-dialog-button {
    margin-inline: auto;
  }
`
export const Error = styled.div<any>`
  margin-top: 5px;
  color: ${(props) => props.theme.alert};
  font-size: 12px;
`
interface ITextEditorProps {
  id: string
  name: string
  style?: React.CSSProperties
  disabled: boolean
  content_min_height?: string
  // eslint-disable-next-line no-unused-vars
  onChange: (a: any) => void
  value: any
  error?: any
  blur?: any
  label?: string
}

const createUploadAdapter = (loader) => {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append("file", loader.file)

        fetch("https://jsonplaceholder.typicode.com/photos", {
          method: "POST",
          body: data,
        })
          .then((response) => {
            if (response.ok) {
              return response.json()
            }
            reject(response.statusText)
          })
          .then((data) => {
            resolve({ default: data.url })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  }
}

export default createUploadAdapter

const TextEditor: React.FC<Partial<ITextEditorProps>> = ({
  id,
  name,
  style,
  disabled,
  onChange,
  content_min_height = "300px",
  value,
  error,
  blur,
  label,
}) => {
  const editorRef = useRef<any>(null)

  const [editorLoaded, setEditorLoaded] = useState(false)
  const { CKEditor, Editor } = editorRef.current || {}

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react")?.CKEditor,
      Editor: require("ckeditor5-custom-build"),
    }
    setEditorLoaded(true)
  }, [])

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file
            .then((file: Blob) => {
              const reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = () => resolve({ default: reader.result })
            })
            .catch((err) => reject(err))
        })
      },
    }
  }
  const uploadPlugin = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdaptor = (loader) => {
      return uploadAdapter(loader)
    }
  }

  return (
    <>
      {editorLoaded && (
        <Wrapper content_min_height={content_min_height}>
          <span className="label">{label}</span>
          <CKEditor
            id={id}
            name={name}
            style={style}
            config={{
              extraPlugins: [uploadPlugin],
              ckfinder: {
                theme: "dark",
              },
            }}
            editor={Editor}
            onBlur={blur}
            disabled={disabled}
            isEnabled={true}
            data={value}
            onChange={(_, editor) => {
              const data = editor.getData()
              onChange && onChange(data)
            }}
            onReady={(editor: any) => {
              editor?.ui
                ?.getEditableElement()
                ?.parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.getEditableElement()
                )
              CKeditorG = editor
              editor.plugins.get("FileRepository").createUploadAdapter =
                createUploadAdapter
            }}
            onError={(error, { willEditorRestart }) => {
              if (willEditorRestart) {
                CKeditorG?.ui?.view?.toolbar?.element.remove()
              }
            }}
          />
          {error && <Error>{error}</Error>}
        </Wrapper>
      )}
    </>
  )
}

export { TextEditor }
