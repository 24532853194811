import React, { useRef, useState } from "react"
import styled from "styled-components"
import { SUCCESS_STORIES_DATA } from "./helpers"
import { AosWrapper } from "../../molecules/AosWrapper"
import { TimelineComponent } from "../../molecules/TimelineComponent"
import { SuccessTimelineComponent } from "../../molecules/SuccessTimelineComponent"

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.bgSectorColor};
  width: 100%;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 90px 100px 90px 150px;

  @media only screen and (max-width: 1100px) {
    padding: 100px 40px 100px 40px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0px 20px 0px 20px;
    min-height: 764px;
  }
`

const ScrollableDiv = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 620px;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
  scroll-snap-align: start;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    height: 0;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    max-height: 955px;
    scroll-snap-align: center;
  }
`

const MainTitle = styled.p`
  ${(props) => props?.theme?.typography?.sectionHeadline2};
  line-height: 48px;
  color: ${(props) => props.theme.base};
  span {
    font-size: 2rem;
    background: linear-gradient(87.75deg, #0058ee 100%, #36d7b7 80%);
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    @media only screen and (max-width: 768px) {
      font-size: 1.375rem;
      line-height: 33px;
    }
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 20px 40px 20px;
    font-size: 1.375rem;
    line-height: 33px;
  }

  @media only screen and (min-width: 769px) {
    display: none;
  }
`

const SuccessStories = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const containerRef = useRef(null)
  const containerChildRef = useRef(null)

  const handleScroll = () => {
    const listContainer = document.getElementById("parent")
    const scrollTop = listContainer.scrollTop
    const itemHeight = listContainer.scrollHeight / SUCCESS_STORIES_DATA?.length
    const visibleIndex = Math.floor(scrollTop / itemHeight)
    setActiveIndex(visibleIndex)
  }

  const scrollToItem = (index: number) => {
    const listItem: any = document.getElementsByClassName("list-item")?.[index]
    listItem.classList.remove("hidden")

    const container = document.getElementById("parent")
    container.scrollTop = listItem?.offsetTop - container?.offsetTop
  }

  return (
    <Wrapper id={"success-stories"}>
      <AosWrapper>
        <MainTitle>
          {"Success stories in "} <br />
          <span>{"Ready to Work"}</span>
        </MainTitle>
        <Container>
          <TimelineComponent
            onSideLineClick={scrollToItem}
            timelineData={SUCCESS_STORIES_DATA}
            activeIndex={activeIndex}
          />
          <ScrollableDiv
            ref={containerRef}
            id={"parent"}
            onScroll={handleScroll}
          >
            {SUCCESS_STORIES_DATA?.map((success_story, i) => {
              return (
                <SuccessTimelineComponent
                  className={"list-item"}
                  refChild={containerChildRef}
                  key={i}
                  name={success_story?.name}
                  position={success_story?.position.toUpperCase()}
                  story={success_story?.story}
                  index={i}
                  imgSrc={success_story?.imgSrc}
                />
              )
            })}
          </ScrollableDiv>
        </Container>
      </AosWrapper>
    </Wrapper>
  )
}

export { SuccessStories }
