import { Pagination, Table } from "antd"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import { SpinProps } from "antd/lib/spin"
import { TablePaginationConfig } from "antd/lib/table"
import React from "react"
import styled from "styled-components"

const TableWrapper = styled(Table)`
  font-size: 16px;
  border-radius: 20px 20px 20px 20px;

  .ant-table-tbody td {
    text-align: center;
  }

  .ant-table-thead > tr > th {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: ${(props) => props.theme.base} !important;
    border-top: 1px solid ${(props) => props.theme.tableBorder};
    background-color: ${(props) => props.theme.tableHeaderColor} !important;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody td {
    color: ${(props) => props.theme.baseTextColor} !important;
  }

  .ant-table-cell {
    font-size: 14px;
    border-bottom: 1px solid ${(props) => props.theme.tableBorder} !important;
    border-left: 1px solid ${(props) => props.theme.tableBorder};
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-bottom: none;
    :last-child {
      border-right: 1px solid ${(props) => props.theme.tableBorder};
    }
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    border: none;
  }

  * > .ant-table-row {
    background-color: ${(props) => props.theme.cardColor};
    border: none;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }

  .ant-table-thead th:before {
    display: none;
  }

  .ant-table-row:nth-child(even) {
    background-color: ${(props) => props.theme.tableRowColor};
  }

  .ant-table-thead > .antd-table-cell ::before {
    height: 0;
  }
  .ant-pagination-item {
    border: 0.5px solid ${(props) => props.theme.disabled};
    border-radius: 5px;
    background-color: transparent !important;
    a {
      color: ${(props) => props.theme.baseTextColor} !important;
    }
  }
  .ant-pagination-item-active {
    background: linear-gradient(
      87.75deg,
      ${(props) => props.theme.buttonGradientFirst},
      ${(props) => props.theme.buttonGradientSecond}
    );
    border: none;
    a {
      color: ${(props) => props.theme.sameTextColor} !important;
    }
  }
`
const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 25px 25px 10px 10px;

  .ant-pagination-item-active {
    background: linear-gradient(
      87.75deg,
      ${(props) => props.theme.buttonGradientFirst},
      ${(props) => props.theme.buttonGradientSecond}
    );
    border: none;
    a {
      color: ${(props) => props.theme.base} !important;
    }
  }
  .ant-pagination-item {
    border: 0.5px solid ${(props) => props.theme.disabled};
    border-radius: 5px;
    background-color: transparent !important;
    a {
      color: ${(props) => props.theme.base};
    }
  }
`

const StyledLabel = styled.p`
  margin: 10px 0 3px 20px;
  padding: 0 10px;
  font-size: 12px;
`

interface TableComponentProps {
  key?: string
  className?: string
  dataSource?: any[]
  columns?: any
  pagination?: false | TablePaginationConfig
  loading?: boolean | SpinProps
  size?: SizeType
  bordered?: boolean
  onChange?: any
  scroll?: any
  current?: number
  total?: number
  pageSize?: number
}

const TableComponent: React.FC<TableComponentProps> = ({
  key,
  className,
  dataSource,
  columns,
  pagination,
  scroll,
  loading,
  size,
  bordered,
  onChange,
  current,
  total,
  pageSize,

  ...props
}) => {
  return (
    <>
      <TableWrapper
        className={className}
        key={key}
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        loading={loading}
        size={size}
        bordered={bordered}
        scroll={scroll}
        onChange={onChange}
        {...props}
      />
      <PaginationWrapper>
        <Pagination
          current={current}
          hideOnSinglePage={true}
          total={total}
          showSizeChanger={false}
          pageSize={pageSize || 10}
          onChange={onChange}
          showTotal={() => (
            <StyledLabel
              style={{
                marginTop: 3,
                fontSize: 12,
                fontWeight: "500",
              }}
            ></StyledLabel>
          )}
        />
      </PaginationWrapper>
    </>
  )
}

export { TableComponent }
