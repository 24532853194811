import { Switch } from "antd"
import React from "react"
import styled from "styled-components"
import useDeviceTheme from "../../../useDeviceTheme"

const SwitchContainerStyle = styled(Switch)<{
  isDarkTheme: boolean
  switchChecked: boolean
}>`
  .ant-switch-inner {
    background: ${(props) => props.theme.toggleColor};
    border: none;
    .ant-switch-inner-checked {
      display: flex;
      justify-content: center;
      img {
        margin-top: 2px;
        margin-right: 5px;
      }
    }
    .ant-switch-inner-unchecked {
      display: flex;
      justify-content: center;
      img {
        margin-top: 5px;
        margin-left: 5px;
      }
    }
  }
  .ant-switch-handle::before {
    content: "";
    background-image: ${({ isDarkTheme, switchChecked }) =>
      !isDarkTheme
        ? switchChecked
          ? `url("/assets/icons/theme-dark-on-light-mode.svg")`
          : `url("/assets/icons/theme-light.svg")`
        : switchChecked
        ? `url("/assets/icons/theme-dark.svg")`
        : `url("/assets/icons/theme-light-on-dark-mode.svg")`};
    background-position: center;
    background-size: contain;
  }
`
interface ISwitchContainer {
  checked: boolean
  onChange: (value: boolean) => void
}

const SwitchContainer: React.FC<ISwitchContainer> = ({ checked, onChange }) => {
  const isDarkTheme = useDeviceTheme()

  return (
    <SwitchContainerStyle
      switchChecked={checked}
      isDarkTheme={isDarkTheme}
      checked={checked}
      unCheckedChildren={
        <img
          src={
            isDarkTheme
              ? "/assets/icons/theme-dark.svg"
              : "/assets/icons/theme-dark-on-light-mode.svg"
          }
          height={19}
          width={19}
        />
      }
      checkedChildren={
        <img
          src={
            isDarkTheme
              ? "/assets/icons/theme-light-on-dark-mode.svg"
              : "/assets/icons/theme-light.svg"
          }
          height={19}
          width={19}
        />
      }
      onChange={(value) => {
        localStorage.setItem("current-theme", value ? "dark" : "light")
        window.dispatchEvent(new Event("storage"))
        onChange(value)
      }}
    />
  )
}

export { SwitchContainer }
