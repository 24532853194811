import React, { CSSProperties } from "react"
import styled from "styled-components"

interface CardProps {
  gradientBorder?: boolean
  gradientBorderTop?: string
  gradientBorderBottom?: string
  gradientBorderLeft?: string
  gradientBorderRight?: string
  backgroundColor?: string
  borderRadius?: string
  hideShadow?: boolean
  style?: CSSProperties
  className?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const CardBg = styled.div`
  background: ${(props) => props.theme.scrollBarGradient};
  border-radius: ${({ borderRadius }: CardProps) => borderRadius};
  padding-top: ${({ gradientBorderTop }: CardProps) => gradientBorderTop};
  padding-bottom: ${({ gradientBorderBottom }: CardProps) =>
    gradientBorderBottom};
  padding-left: ${({ gradientBorderLeft }: CardProps) => gradientBorderLeft};
  padding-right: ${({ gradientBorderRight }: CardProps) => gradientBorderRight};
`

const StyleCard = styled.div`
  background: ${(props) => props.backgroundColor ?? props.theme.cardColor};
  border-radius: ${({ borderRadius }: CardProps) => borderRadius};
  box-shadow: ${(props) =>
    props.hideShadow ? "none" : props.theme.shadowedBox};
`

export const Card: React.FC<CardProps> = ({
  children,
  gradientBorder,
  borderRadius,
  gradientBorderTop,
  gradientBorderBottom,
  gradientBorderLeft,
  gradientBorderRight,
  className,
  onMouseEnter,
  onMouseLeave,
  style,
  hideShadow,
  ...rest
}) => {
  const _children = (
    <StyleCard
      borderRadius={borderRadius}
      className={className}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      hideShadow={hideShadow}
      {...rest}
    >
      {children}
    </StyleCard>
  )

  if (gradientBorder) {
    return (
      <CardBg
        borderRadius={borderRadius}
        gradientBorderTop={gradientBorderTop}
        gradientBorderBottom={gradientBorderBottom}
        gradientBorderLeft={gradientBorderLeft}
        gradientBorderRight={gradientBorderRight}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...rest}
      >
        {_children}
      </CardBg>
    )
  }
  return _children
}
