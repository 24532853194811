import React from "react"
import styled from "styled-components"
import { ICompanyIcons } from "../../../interface"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

const IconsContainer = styled.div`
  max-width: 202px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: 500px) {
    max-width: 101px;
  }

  img {
    height: 42px;
    width: 42px;
    color: ${(props) => props.theme.companyIcon};
    @media only screen and (max-width: 500px) {
      height: 20px;
      width: 20px;
    }
  }

  p {
    color: ${(props) => props.theme.sameBase};
    ${(props) => props.theme.typography.CompanyIconText};
    @media only screen and (max-width: 500px) {
      font-size: 0.75rem;
      line-height: 15px;
    }
  }
`

const CompanyIcon: React.FC<ICompanyIcons> = ({ icon, name, lightIcon }) => {
  const isDarkTheme = useDeviceTheme()
  return (
    <IconsContainer>
      <img src={isDarkTheme ? icon : lightIcon} />
      <p>{name}</p>
    </IconsContainer>
  )
}

export { CompanyIcon }
