import React from "react"
import styled from "styled-components"
import { Card } from "../../atoms"
import parse from "html-react-parser"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

export interface IOrganizationCard {
  icon: React.ReactNode
  lightIcon: React.ReactNode
  title: string
  content: string
  footer: string
}
const IconStyle = styled.div`
  img {
    height: 100px;
    width: 100px;
    @media (max-width: 374px) {
      height: 75px;
      width: 75px;
    }
  }
`
const CardWrapper = styled(Card)`
  padding: 30px;
  min-height: 482px;
  min-width: 424px;
  width: 100%;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.base};
  @media (max-width: 576px) {
    min-width: 335px;
    min-height: 402px;
  }
  @media (max-width: 374px) {
    min-width: 250px;
    min-height: 390px;
  }
`
const TextStyle = styled.div`
  ${(props) => props.theme.typography.paragraph2}
  font-size: 1rem;
  font-weight: 400;
  margin-top: 20px;
  text-align: center;
  white-space: break-spaces;
  color: ${(props) => props.theme.fontColor};
  @media (max-width: 576px) {
    font-size: 0.875rem;
    white-space: normal;
  }
  @media (max-width: 374px) {
    font-size: 0.625rem;
    margin-top: 15px;
  }
`
const TitleStyle = styled.div`
  ${(props) => props.theme.typography.cardTitle}
  margin-top:50px;
  text-align: center;
  white-space: break-spaces;
  color: ${(props) => props.theme.textColor};
  @media (max-width: 576px) {
    font-size: 1.125rem;
    margin-top: 40px;
  }
  @media (max-width: 374px) {
    font-size: 0.75rem;
    margin-top: 30px;
  }
`
const FooterStyle = styled.div`
  ${(props) => props.theme.typography.paragraph2}
  font-size: 1rem;
  font-weight: 400;
  margin-top: 20px;
  text-align: center;
  white-space: break-spaces;
  font-style: normal;
  color: ${(props) => props.theme.fontColor};
  @media (max-width: 576px) {
    font-size: 0.75rem;
    font-weight: 400;
  }
  @media (max-width: 374px) {
    font-size: 0.625rem;
    margin-top: 10px;
  }
`
export const OrganizationCard: React.FC<IOrganizationCard> = ({
  lightIcon,
  icon,
  content,
  title,
  footer,
}) => {
  const iseDeviceTheme = useDeviceTheme()
  return (
    <CardWrapper
      gradientBorder={true}
      borderRadius={"50px"}
      gradientBorderTop={"1px"}
    >
      <IconStyle>{iseDeviceTheme ? icon : lightIcon}</IconStyle>
      <TitleStyle>{title}</TitleStyle>
      <TextStyle>{parse(content)}</TextStyle>
      <FooterStyle> {footer.toUpperCase()}</FooterStyle>
    </CardWrapper>
  )
}
