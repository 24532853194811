import React from "react"
import styled from "styled-components"
import { Card } from "../../atoms/"
import { AosWrapper } from "../../molecules/AosWrapper"

const MultipleSector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
  width: 100%;

  background: ${(props) => props.theme.bgSectorColor};

  @media (max-width: 1290px) {
    height: auto;
    padding: 30px 30px;
    width: 100%;
  }
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
    gap: 40px;
    width: 100%;
    background: ${(props) => props.theme.bgSectorColor};
  }
`

const MultipleSectorContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  max-width: 1212px;

  @media (max-width: 1290px) {
    padding: 60px 20px;
    width: 100%;
  }
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 100%;
  }
`
const MultipleSectorHeader = styled.div`
  display: flex;
  text-align: left;
  padding-right: 54px;
  height: 198px;
  justify-content: center;

  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
    height: auto;
    max-width: 335px;
    padding-right: 0px;
    padding-bottom: 50px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1290px) {
    width: auto;
    height: auto;
  }

  p {
    ${(props) => props.theme.typography.sectionHeadline};
    color: ${(props) => props.theme.sameBase};
    max-width: 475px;

    @media (max-width: 1050px) {
      /* width: 60%; */
      height: auto;
      font-size: 1.375rem;
      line-height: 150%;
    }
  }

  .text-gradient {
    ${(props) => props.theme.typography.sectionHeadline};
    ${(props) => props.theme.typography.TextLinearGradient};

    @media (max-width: 1050px) {
      font-size: 1.375rem;
      line-height: 150%;
    }
  }
`
const SectorCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50px;
  max-width: 562px;
  max-height: 575px;
  width: 100%;
  gap: 60px;
  margin-bottom: 10px;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
  scroll-snap-align: start;
  scroll-behavior: smooth;
  @media (max-width: 768px) {
    font-size: 1.375rem;
    line-height: 150%;
    max-width: 335px;
    margin-bottom: 3rem;
    height: 420px;
  }

  @media (max-width: 340px) {
    height: 500px !important;
  }

  @media only screen and (min-width: 857px) and (max-width: 1290px) {
    line-height: 150%;
    height: 450px;
    scroll-snap-type: y mandatory;
    padding: 0px 0px 50px 0px;
  }
  @media only screen and (min-width: 768px) and (max-width: 857px) {
    height: 490px;
  }

  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
    background: ${(props) => props.theme.scrollBarColor};

    @media only screen and (min-width: 320px) and (max-width: 768px) {
      display: none;
    }
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: ${(props) => props.theme.scrollBarColor};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.scrollBarGradient};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`
const SectorCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 522px;
  border-radius: 50px;
  background: ${(props) => props.theme.sectorCardGradient};
  scroll-snap-align: start;

  @media (max-width: 768px) {
    width: 100%;
  }
`
const SectorContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
  max-width: 509px;
  @media (max-width: 768px) {
    padding: 35px;
    width: 100%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1290px) {
    padding: 50px 50px;
  }
`

const SectorIcon = styled.div`
  img {
    max-width: 167.75px;
    max-height: 126px;
  }
`
const SectorDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  max-width: 498px;

  @media only screen and (min-width: 768px) and (max-width: 1290px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-top: 30px;
  }
`

const SectorTitle = styled.div`
  max-width: 493px;
  ${(props) => props.theme.typography.cardTitle};
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  color: ${(props) => props.theme.textColor};
  padding-top: 50px;

  @media only screen and (min-width: 320px) and (max-width: 377px) {
    font-size: 1.125rem !important;
  }

  @media only screen and (min-width: 377px) and (max-width: 768px) {
    font-size: 1.25rem !important;
  }

  @media (max-width: 768px) {
    padding-top: 0px;
  }
`

const SectorDescription = styled.div`
  max-width: 450px;
  ${(props) => props.theme.typography.descriptionMsg};
  text-align: center;
  color: ${(props) => props.theme.sameBase};
  padding-top: 20px;

  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 100% !important;
    max-width: 245px;
    font-size: 0.875rem;
  }
`

const MultipleSectorComponent: React.FC = () => {
  const contents = [
    {
      id: "0",
      icon_link: "/assets/icons/saas.svg",
      title: "SAAS Development",
      description:
        "Our company specializes in SaaS (Software as a Service) development, crafting innovative and scalable solutions tailored to meet the unique needs of businesses across various industries.",
    },
    {
      id: "1",
      icon_link: "/assets/icons/livestream.svg",
      title: "Live Streaming",
      description:
        "At our company, we are at the forefront of live streaming technology, empowering businesses to engage and connect with their audiences in real-time.",
    },
    {
      id: "2",
      icon_link: "/assets/icons/iot.svg",
      title: "Digital Marketplace",
      description:
        "Embracing the digital era, our company is dedicated to facilitating seamless digital transformation journeys for organizations seeking to modernize their operations and stay ahead in today's competitive landscape.",
    },
    // {
    //   id: "3",
    //   icon_link: "/assets/icons/nft.svg",
    //   title: "NFT Marketplace",
    //   description:
    //     "Lorem ipsum dolor sit amet consectetur. Risus nec in in malesuada magna. Tempus in ut sed a integer at. Nunc ornare mi vulputate semper.",
    // },
    {
      id: "3",
      icon_link: "/assets/icons/social.svg",
      title: "Social Platform",
      description:
        "Our company is dedicated to building vibrant and inclusive social platforms that foster meaningful connections and community engagement.",
    },
    // {
    //   id: "5",
    //   icon_link: "/assets/icons/electronic.svg",
    //   title: "Electronic Contract Platform",
    //   description:
    //     "Lorem ipsum dolor sit amet consectetur. Risus nec in in malesuada magna. Tempus in ut sed a integer at. Nunc ornare mi vulputate semper.",
    // },
    {
      id: "4",
      icon_link: "/assets/icons/handshake.svg",
      title: "End to End Business Solution",
      description:
        "Our company provides end-to-end business solutions designed to address the diverse needs and challenges of modern enterprises.",
    },
  ]
  return (
    <MultipleSector id={"our-expirence"}>
      <AosWrapper>
        <MultipleSectorContent>
          <MultipleSectorHeader>
            <p>
              {"Ever-ready team to Challenge Problems in "}
              <span className={"text-gradient"}>{"Multiple Sectors"}</span>
            </p>
          </MultipleSectorHeader>
          <SectorCards id={"sector"}>
            {contents.map((content, index) => (
              <Card
                gradientBorder={true}
                hideShadow={true}
                gradientBorderBottom={"1px"}
                borderRadius={"50px"}
                key={index}
              >
                <SectorCard>
                  <SectorContent>
                    <SectorIcon>
                      <img src={content.icon_link} key={content[index]} />
                    </SectorIcon>
                    <SectorDetails>
                      <SectorTitle>{content.title}</SectorTitle>
                      <SectorDescription>
                        {content.description}
                      </SectorDescription>
                    </SectorDetails>
                  </SectorContent>
                </SectorCard>
              </Card>
            ))}
          </SectorCards>
        </MultipleSectorContent>
      </AosWrapper>
    </MultipleSector>
  )
}

export { MultipleSectorComponent }
