import { DatePicker } from "antd"
import React from "react"
import styled from "styled-components"
import { ErrorCounterWrapper, ErrorStyled } from "../TextField"
import { FormikErrors } from "formik"

interface IDatePickerProps {
  label?: string
  onChange?: any
  value: any
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
}

const Container = styled.div`
  .label {
    font-weight: 500;
    font-size: 1rem;
    color: ${(props) => props.theme.baseTextColor};
    margin: 10px 0;
  }

  .ant-picker {
    background-color: ${(props) => props.theme.cardColor} !important;
    border-color: ${(props) => `${props.theme.borderColor} !important`};
    height: 40px;
    width: 13vw;

    .ant-picker-clear {
      margin-right: 1.5em;
      color: ${(props) => props.theme.baseTextColor};
      background-color: ${(props) => props.theme.cardColor};
    }
  }
`

const DatePickerComponent: React.FC<IDatePickerProps> = ({
  label,
  onChange,
  value,
  error,
}) => {
  return (
    <Container>
      <div className="label">{label}</div>
      <DatePicker
        name={"date"}
        placeholder={"Select date"}
        onChange={onChange}
        value={value}
        inputReadOnly={true}
        format={"YYYY-MM-DD"}
        allowClear={true}
        popupClassName={"date-picker"}
      />
      <ErrorCounterWrapper>
        {error ? <ErrorStyled>{error}</ErrorStyled> : <div />}
      </ErrorCounterWrapper>
    </Container>
  )
}

export { DatePickerComponent }
