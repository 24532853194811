import React from "react"
import styled from "styled-components"

export interface TeamImgProps {
  image?: string
  name?: string
  position?: string
}

const TeamImg = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.teamInfoCard};
  border-radius: 25px;
`

const Image = styled.div`
  img {
    height: auto;
    background-color: white;
    object-fit: fill;
    border-radius: 12px;
    width: 100%;

    @media (max-width: 900px) and (min-width: 700px) {
      height: auto;
    }
  }
`
const PersonDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;

  @media (max-width: 425px) {
    padding-top: 10px;
    font-size: 1rem;
  }
`

const PersonName = styled.div`
  ${(props) => props.theme.typography.heroHeadlineMob}
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textColor};

  @media (max-width: 425px) {
    font-size: 1rem;
  }
`

const PersonPosition = styled.div`
  ${(props) => props.theme.typography.PersonName}
  padding-top: 10px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textColor};

  @media (max-width: 425px) {
    font-size: 0.875rem;
  }
`

const TeamImgCard: React.FC<TeamImgProps> = ({ image, position, name }) => {
  return (
    <TeamImg>
      <Image>
        <img src={image} width={310} height={310} />
      </Image>
      <PersonDetail>
        <PersonName>{name}</PersonName>
        <PersonPosition>{position}</PersonPosition>
      </PersonDetail>
    </TeamImg>
  )
}

export { TeamImgCard }
