export const CompanyHistory = [
  {
    date: "2019",
    title: "RTW was founded",
    description:
      "Established in 2019, we are a dedicated team committed to providing comprehensive solutions for professional development and career advancement. This year was crucial for us as we secured our first of many client projects, and focused on building bespoke software solutions tailored to their specific needs",
  },
  {
    date: "2020",
    title: "Group of Management Team/Technical Team was formed",
    description:
      "Group of dedicated Management Team and Technical Team was formed. We take great pride in the caliber and dedication of our Management Team and Technical Team. Their collaborative efforts, passion for excellence, and commitment to work satisfaction form the backbone of ReadyToWork's success.",
  },
  {
    date: "2021",
    title: "Business expansion to multiple new sectors",
    description:
      "RTW established partnerships with local businesses and startups to expand its client base. We also continued investment in employee training and development to enhance technical expertise and project management skills",
  },
  {
    date: "2022",
    title: "Client satisfaction and project successes",
    description:
      "Over the time RTW gained recognition for successful client projects and received referrals, further increasing its reputation and client base. We also focused on building long-term relationships with clients through ongoing support and maintenance services",
  },
  {
    date: "2023",
    title: "Team of 50+ employees",
    description:
      "RTW has grown into a robust organization with a passionate team of 50+ talented professionals. Since our establishment, we have focused on assembling a diverse and skilled workforce to deliver top-notch services and solutions for professional development and career advancement",
  },
]
