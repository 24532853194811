import { QueryCache, QueryClient } from "react-query"

export const queryCache = new QueryCache()

const MINUTE = 60 * 60 * 1000

const queryClient = new QueryClient({
  queryCache: queryCache,
  defaultOptions: {
    queries: {
      cacheTime: 5 * MINUTE,
    },
  },
})

export { queryClient }
