import { DeleteOutlined, InboxOutlined } from "@ant-design/icons"
import { notification, UploadProps, Upload, Image } from "antd"
import React, { useState } from "react"
import styled from "styled-components"
import { Error } from "../TextEditor"

interface IUploadProps {
  labelText: string
  borderColor?: string
  labelColor?: string
  height?: string
}

const Container = styled.div<any>`
  .label {
    font-weight: 500;
    font-size: 1rem;
    color: ${(props: IUploadProps | any) =>
      props.labelColor ? props.labelColor : props.theme.baseTextColor};
  }
  .ant-upload-select {
    border: 1px
      ${(props: IUploadProps) =>
        props.borderColor
          ? `dashed ${props.borderColor}`
          : "dashed"} !important;
    border-radius: 5px;
  }
  .ant-upload {
    height: ${(props: IUploadProps) => (props.height ? props.height : "216px")};
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .ant-upload.ant-upload-drag {
    border: none;
  }

  .ant-upload-drag-icon .anticon {
    svg {
      font-size: 3rem;
      fill: ${(props) => props.theme.uploadIconColor};
    }
  }
  .ant-upload-drag p.ant-upload-text {
    color: ${(props) => props.theme.uploadTextColor};
  }

  .ant-upload-drag p.ant-upload-hint {
    color: ${(props) => props.theme.textDisabled};
  }

  .image-component {
    position: relative;
    height: 62px;
    margin-top: 8px;
    padding: 8px;
    border: 1px solid #424242;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .image {
      width: 44px;
      height: 44px;
      margin-right: 10px;
    }
    .delete-icon {
      position: absolute;
      right: 20px;
    }
  }
`

const UploadComponent: React.FC<any> = ({
  labelText,
  handleChange,
  setLoading,
  setImgUploadError,
  setUploading,
  error,
  defaultImage,
  removeImage,
}) => {
  const [imageFile, setImageFile] = useState<any>(null)

  const imageToBeRendered = JSON.stringify(defaultImage)?.includes("http")
    ? defaultImage
    : ""

  const errorMessage =
    "Please upload an image having size less than or equal 5MB."

  const handleUpload = async ({ file, onSuccess, onError }: any) => {
    setImgUploadError?.(false)
    try {
      onSuccess?.("ok")
      handleChange?.(file)
    } catch (err: any) {
      onError?.(err)
    } finally {
      setUploading?.(false)
      setLoading?.(false)
    }
  }

  const beforeUpload = (file) => {
    const isLessThan5MB = file.size / 1024 / 1024 <= 5
    if (!isLessThan5MB) {
      notification.error({ message: errorMessage, key: "image" })
      setImgUploadError(true)
      return false
    }
    return isLessThan5MB
  }

  const componentProps: UploadProps = {
    name: "image",
    maxCount: 1,
    listType: "picture",
    accept: "image/png, image/jpeg, image/jpg",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    customRequest: handleUpload,
    onChange: (info) => {
      localStorage.setItem(
        "image",
        `${URL?.createObjectURL(info?.fileList?.[0]?.originFileObj)},,${
          info?.fileList?.[0]?.originFileObj?.name
        }`
      )
      setImageFile(info?.fileList)
    },
    beforeUpload: beforeUpload,
    showUploadList: false,
  }

  return (
    <Container>
      <span className="label">{labelText}</span>
      <Upload {...componentProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Support for a single upload. Strictly prohibited from uploading
          company data or other banned files.
        </p>
      </Upload>

      {imageToBeRendered || imageFile?.length ? (
        <div className="image-component">
          <Image
            src={
              imageToBeRendered
                ? imageToBeRendered
                : URL?.createObjectURL(imageFile?.[0]?.originFileObj)
            }
            className="image"
          />
          <p>
            {imageToBeRendered
              ? defaultImage?.match(/cover_image\/(.*?)\?X-Goog/i)?.[1] ||
                localStorage.getItem("image")?.split(",,")?.[1] ||
                "124235345246436.png"
              : imageFile?.[0]?.name}
          </p>
          <div
            className="delete-icon"
            onClick={() => {
              setImageFile(null)
              localStorage.removeItem("image")
              removeImage()
            }}
          >
            <DeleteOutlined />
          </div>
        </div>
      ) : null}
      {error && <Error>{error}</Error>}
    </Container>
  )
}

export { UploadComponent }
