/* eslint-disable no-unused-vars */
import React from "react"
import styled from "styled-components"
import { Input } from "antd"

const { Search } = Input
interface SearchProps {
  label?: string
  value?: string
  loading?: boolean
  width?: number
  placeholder?: string

  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onSearch?: (
    value: string,
    event?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void
  className?: string
  size?: any
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .label {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    color: ${(props) => props.theme.base};
  }
`

const SearchComponent = styled(Search)`
  width: 392px;
  & .ant-input {
    height: 30px;
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.base};
  }

  & .ant-input-affix-wrapper {
    border: 0.5px solid ${(props) => props.theme.searchBarBorderColor} !important;
    background-color: transparent;
    font-size: 12px;
  }

  & .ant-btn {
    height: 39px;
    margin-top: -0.7px;
    background: linear-gradient(
      87.75deg,
      ${(props) => props.theme.buttonGradientFirst},
      ${(props) => props.theme.buttonGradientSecond}
    );
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    width: 50px;
    border: 0.5px solid ${(props) => props.theme.searchBarBorderColor} !important;
    & .anticon {
      svg {
        fill: ${(props) => props.theme.base};
      }
    }
  }
`

export const SearchBar: React.FC<SearchProps> = ({
  label,
  value,
  onChange,
  onPressEnter,
  onSearch,
  loading,
  placeholder,
  className,
  size,
  ...rest
}) => {
  return (
    <Wrapper>
      <span className={"label"}>{label}</span>
      <SearchComponent
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onPressEnter={onPressEnter}
        onSearch={onSearch}
        loading={loading}
        className={className}
        size={size ? size : "middle"}
        allowClear={true}
        {...rest}
      />
    </Wrapper>
  )
}
