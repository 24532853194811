import React from "react"
import styled from "styled-components"
import ScalaLogoLarge from "./scalaLogoLarge"
import ScalaLogoMobile from "./scalaLogoMobile"
import { AosWrapper } from "../../molecules/AosWrapper"

const Wrapper = styled.div`
  background: ${(props) => props.theme.scalaGroupGradientDark};
`

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 1500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 150px 20px;
  margin: auto;

  @media only screen and (max-width: 768px) {
    padding: 60px 20px 0 20px;
  }
`

const ScalaGropInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 941px;

  .title {
    ${(props) => props.theme.typography.sectionHeadline};
    color: ${(props) => props.theme.sameBase};
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 1.375rem;
    }
  }

  .title-gradient {
    background: ${(props) => props.theme.highlightGradient};
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .description {
    ${(props) => props.theme.typography.paragraph1}
    color: ${(props) => props.theme.fontColor};
    text-align: center;
    margin-top: 15px;

    @media only screen and (max-width: 768px) {
      font-size: 0.875rem;
    }
  }
`

const ScalaGropSection = () => {
  return (
    <Wrapper id={"scala-group"}>
      <AosWrapper>
        <MainContainer>
          <ScalaGropInfo>
            <h3 className={"title"}>
              {"We're a proud member of the "}
              <span className={"title title-gradient"}>{"Scala group"}</span>
            </h3>
            <p className={"description"}>
              {
                "Scala Inc is engaged in the development, sales, and maintenance of SaaS and ASP-related business software products. Readytowork Co., Ltd. is a member of the Scala Group. Scala Inc. is listed on the Tokyo Stock Exchange Prime Market (security code 4845).."
              }
            </p>
          </ScalaGropInfo>
          <ScalaLogoLarge />
          <ScalaLogoMobile />
        </MainContainer>
      </AosWrapper>
    </Wrapper>
  )
}

export { ScalaGropSection }
