import styled from "styled-components"

const MessangerIconWrapper = styled.div`
  display: none;
  position: fixed;
  right: 60px;
  bottom: 5px;
  flex-direction: column-reverse;
  .img {
    height: 80px;
    width: 80px;
  }
`

interface IProps {
  imageSrc: string
}

const MessangerIcon: React.FC<IProps> = ({ imageSrc }) => {
  return (
    <MessangerIconWrapper>
      <img src={imageSrc} />
    </MessangerIconWrapper>
  )
}

export { MessangerIcon }
