import React from "react"
import styled from "styled-components"

import {
  ISoftwareSolutionCard,
  SoftWareSolutionCard,
} from "../../molecules/SoftwareSolutionCard"

interface ISoftwareSolutionGrid {
  items: Array<ISoftwareSolutionCard>
}

const Wrapper = styled.div`
  width: 1312px;
  margin: auto;
  @media (max-width: 1350px) {
    width: 100%;
  }
`

const GridStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 60px;
  column-gap: 20px;
  place-items: center;
  @media (max-width: 1350px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 915px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  @media (max-width: 374px) {
    gap: 20px;
  }
`

const SoftwareSolutionGrid: React.FC<ISoftwareSolutionGrid> = ({ items }) => {
  return (
    <Wrapper>
      <GridStyle>
        {items.map((item, index) => (
          <SoftWareSolutionCard
            key={index}
            title={item.title}
            content={item.content}
            icon={item.icon}
            lightIcon={item.lightIcon}
          />
        ))}
      </GridStyle>
    </Wrapper>
  )
}

export { SoftwareSolutionGrid }
