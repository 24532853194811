import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle<any>`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-family:'Lato', sans-serif;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .before-scrolling  {
    background-color: transparent !important;
  }
  .after-scrolling {
    background: ${(props) => props.theme.coverGradientHeader}
  }
  .after-scrolling-home{
    background: ${(props) => props.theme.coverGradientHeaderHome}
  }
    .ant-notification-notice {
    background: ${(props) => props.theme.cardColor} !important;
    border: 1px solid ${(props) => props.theme.borderColor};
    align-items: center;
    .ant-notification-notice-with-icon {
      align-items: center;
      display: flex;
    }
    .ant-notification-notice-message { 
      color: ${(props) => props.theme.baseTextColor};
      margin-top: 8px;
      word-break: break-all;
    }
    span{
      color: ${(props) => props.theme.baseTextColor};
    }
    a {
      margin-right: -10px;
    }
  }
  /* stylelint-disable property-no-vendor-prefix */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
   }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`

export { GlobalStyles }
