import axios from "axios"
import { auth } from "./firebase"

/**
 * axios instance
 */

axios.defaults.responseType = "json"

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
})

API.interceptors.request.use(
  async (config) => {
    const authUser = Object.keys(window?.localStorage).filter((item) =>
      item.startsWith("firebase:authUser")
    )?.[0]
    const user = authUser
      ? JSON.parse(localStorage.getItem(authUser))
      : undefined
    const token =
      user?.stsTokenManager?.accessToken ||
      (await auth.currentUser?.getIdToken(true))

    if (token) {
      config.headers["Authorization"] = "Bearer " + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

API.interceptors.response.use(
  (response) => {
    return response?.data
  },
  (error) => {
    return Promise.reject(error.response)
  }
)

export { API }
