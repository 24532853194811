import React from "react"
import styled from "styled-components"
import {
  Descripition,
  Headline,
  StyledGradientTitle,
  animationProperties,
} from "../../../utils"
import { MapCard } from "../../atoms"
import { AosWrapper } from "../../molecules/AosWrapper"
import useDeviceTheme from "@project/shared/src/useDeviceTheme"

const Wrapper = styled.div`
  padding: 75px 20px;
  background-color: ${(props) => props.theme.bgSectorColor};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  .map-background {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 641px) {
    padding: 60px 20px;
  }
`

const MapCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 49px;
  padding: 0 20px;
  @media (max-width: 1115px) {
    flex-direction: column;
    justify-content: flex-start;
    height: 500px;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    padding: 0 100px;
  }
  @media (max-width: 576px) {
    height: 335px;
    overflow-y: scroll;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`

const LocationSection: React.FC = () => {
  const isDarkTheme = useDeviceTheme()
  return (
    <Wrapper id={"contact"}>
      <AosWrapper
        className={"map-background"}
        animateIn={"animate__fadeInUpBig animate__zoomIn"}
      >
        <img
          src={
            isDarkTheme
              ? "/assets/imgs/contact.svg"
              : "/assets/imgs/contact-light.svg"
          }
          width={"100%"}
          height={"auto"}
          style={{ objectFit: "contain" }}
          className={"map-background"}
        />
      </AosWrapper>
      <AosWrapper
        duration={animationProperties.duration + 0.2}
        delay={animationProperties.delay + 0.2}
      >
        <Headline>
          {"We’re here to"}
          <StyledGradientTitle> {"hear "}</StyledGradientTitle>
          {"from you"}
        </Headline>
        <Descripition>
          {
            "We are Japan based company with developers team from all around Nepal and\n Management team from Japan collaborating at our best to come-up with the\n innovative approach to serve technology."
          }
        </Descripition>
        <MapCardWrapper>
          <AosWrapper
            duration={animationProperties.duration + 1}
            delay={animationProperties.delay + 1}
            animateIn={"animate__fadeInUpBig animate__zoomIn"}
          >
            <MapCard
              country={"Nepal"}
              officeType={"Branch Office"}
              description={
                "We are here in Nepal to hear from you. Feel free to\n contact us in the details below in case of any\n inquiry."
              }
              location={"Satdobato-15, Laitpur"}
              email={"hr@readytowork.work"}
            />
          </AosWrapper>
          <AosWrapper
            duration={animationProperties.duration + 1}
            delay={animationProperties.delay + 1}
            animateIn={"animate__fadeInUpBig animate__zoomIn"}
          >
            <MapCard
              country={"Japan"}
              description={
                "Our head office is located at Japan\nresponsible for suppervising and managing\nthe workflow of the company."
              }
              officeType={"Head Office"}
              location={"〒152ｰ0004"}
              location2={"東京都目黒区鷹番1-1-5 4F C-15"}
              email={"admin@readytowork.work"}
            />
          </AosWrapper>
        </MapCardWrapper>
      </AosWrapper>
    </Wrapper>
  )
}

export { LocationSection }
