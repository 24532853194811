import React from "react"
import styled from "styled-components"
import { Card } from "../Card"

interface NewsCardProps {
  date?: string
  title?: string
  previewText?: string
}
const NewsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 60px 40px;
  gap: 40px;
  isolation: isolate;
  width: 550px;
  background: linear-gradient(148.87deg, #252222 0%, #15151e 101.48%);
  @media screen and (min-width: 768px) and (max-width: 1220px) {
    min-width: 335px;
    max-width: 550px;
    width: 100%;
  }
  @media (max-width: 768px) {
    max-width: 335px;
    padding: 40px 20px;
    gap: 20px;
    width: 100%;
    scroll-snap-align: center;
  }
`
const ImageWrapper = styled.div`
  max-width: 470px;
  max-height: 280px;
  width: 100%;

  background: linear-gradient(
    177.64deg,
    rgba(40, 40, 40, 0.2) 29.53%,
    #0f2b2d 98.02%
  );
  border-radius: 25px;
  @media (max-width: 768px) {
    max-width: 295px;
    max-height: 203px;
    width: 100%;
  }

  img {
    max-width: 470px;
    max-height: 280px;
    width: 100%;
    border-radius: 25px;
    @media (max-width: 768px) {
      max-width: 295px;
      max-height: 203px;
    }
  }
`
const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 100%;
  @media (max-width: 768px) {
    gap: 20px;
    max-width: 295px;
  }
`

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  p {
    ${(props) => props.theme.typography.navMobItemFont}
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${(props) => props.theme.textColor};
  }
`

const Title = styled.div`
  ${(props) => props.theme.typography.cardTitle5}
  text-align: justify;
  text-transform: capitalize;
  color: ${(props) => props.theme.base};
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

const Description = styled.div`
  ${(props) => props.theme.typography.detailsText}
  line-height: 150%;
  text-align: justify;
  text-transform: capitalize;
  color: ${(props) => props.theme.fontColor};
  @media (max-width: 768px) {
    font-size: 1rem;
  }
  @media (max-width: 350px) {
    font-size: 0.875rem !important;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 100%;

  p {
    ${(props) => props.theme.typography.sectionHeadline}
    font-size: 1.25rem;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.baseTextColor};
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`

const NewsCardComponent: React.FC<NewsCardProps> = ({
  title,
  previewText,
  date,
}) => {
  return (
    <NewsCard
      gradientBorder={true}
      gradientBorderLeft={"1px"}
      borderRadius={"40px"}
    >
      <ImageWrapper>
        <img src="assets/imgs/medium.svg" alt="cover image" />
      </ImageWrapper>
      <NewsContent>
        <DateWrapper>
          <img src={"assets/icons/calendar.svg"} alt={"calendar"} />
          <p>{date}</p>
        </DateWrapper>
        <Title>{title}</Title>
        <Description>{previewText}</Description>
      </NewsContent>
      <Footer>
        <p>Explore now</p> <img src={"assets/icons/arrow.svg"} />
      </Footer>
    </NewsCard>
  )
}

export { NewsCardComponent }
